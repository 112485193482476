/* eslint-disable */
import { CalendarIcon, ChevronDown, ChevronLeft, Pen, Square } from 'lucide-react'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlaceOrderBg from '../../assets/images/PlaceOrderBg.png'
import MFCardBg from '../../assets/SVGS/MF-Card-Bg.svg'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { toCurrency } from '../../utils/CurrencyUtil'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../Components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { useModal } from '../../hooks/use-modal-store'
import { NavbarContext } from '../../Provider/ShowNavbar'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { userDetailsState } from '../LoginState'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { MutualFundsSelectorDates, MutualFundsSelectorDetails } from '../../store/MFStore'
import { addNumberSuffix, addMonth, monthNames } from '../../utils/Util'
import MFSipMandate from './MFSipMandate'
import { setDate } from 'date-fns'
import { Card, CardContent, CardHeader, CardTitle } from '../../Components/ui/card'
import { AccountBalance, Info } from '@mui/icons-material'
import { BankDetails } from '../../store/AccountDetailsStore'

const MFRedeem = () => {
  const location = useLocation()
  const [fundDetails] = useState(location?.state?.fundDetails)
  const fundPortfolio = useRecoilValueLoadable(
    MutualFundsSelectorDetails(location.state?.fundDetails.schemeCode),
  )

  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)

  const navigate = useNavigate()
  const { onOpen } = useModal()

  const [userDetails] = useRecoilState(userDetailsState)
  const [redemptionAmount, setRedemptionAmount] = useState(Math.floor(fundDetails?.minInvt) || 0)
  const [isLoading, setIsLoading] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userData?.uid.split('-')[0]

  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  console.log(bankDetails, 'sdfsdf')

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const redeemOrder = async () => {
    try {
      setIsLoading(true)

      const payload = {
        orderType: 'REDEMPTION',
        schemeCode: fundDetails?.schemeCode,
        clientCode: clientCode.toUpperCase(),
        qty: fundDetails?.balance,
      }

      const response = await post('mutualfundservice/api/bseStar/mfOrder/order', payload)

      setIsLoading(false)

      if (!response.isError) {
        onOpen('openPopup', {
          title: 'Redemption request successful.',
          message: 'The amount will be credited to your bank account shortly.',
          severity: 'success',
        })
      }

      if (response.isError) {
        setMessageModalState({
          message: response.response.error,
          severity: 'error',
        })
      }
    } catch (error) {
      console.log('🚀 ~ redeemOrder ~ error:', error)
    }
  }

  return (
    <>
      <div className='bg-white'>
        <div className='max-w-[1128px] mx-auto min-h-screen pb-12'>
          <div
            className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0'
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className='h-5 w-5' />
            Redeem
          </div>

          <div className='max-w-[360px] mx-auto h-full flex flex-col gap-y-12 justify-between'>
            <div className='h-[170px] w-full bg-cover flex justify-center items-center p-2 z-0 relative'>
              <div
                className='absolute inset-0 bg-cover w-full h-full'
                style={{ backgroundImage: `url(${PlaceOrderBg})` }}
              />
              <div
                className='absolute inset-0'
                style={{
                  background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                  // boxShadow: '0px 16px 24px rgba(65, 85, 141, 0.08)',
                }}
              />
              <div className='flex items-center space-x-4 z-10'>
                <img
                  src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                  alt={fundDetails.compname}
                  className='w-12 h-12 border rounded-lg'
                />
                <div>
                  <h3
                    className='font-semibold text-sm leading-tight text-wrap w-48'
                    style={{ textWrap: 'pretty' }}
                  >
                    {fundDetails?.compname}
                  </h3>
                  <p className='text-sm text-muted-foreground'>
                    3Y returns{' '}
                    <span className='font-bold text-green-600'>
                      {fundPortfolio.state === 'hasValue' &&
                        fundPortfolio?.contents?.mainInfo[0].threeYearReturn.toFixed(2)}
                      %
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className='flex justify-between'>
                <span className='text-xs text-black-400'>Invested amount</span>
                <span className='font-semibold text-black-500'>
                  ₹ {toCurrency(fundDetails.holding)}
                </span>
              </div>

              {/* <div className='flex justify-between'>
                <span className='text-xs text-black-400 flex gap-x-1 items-center'>
                  Current folio value <Info fontSize='16px' color='gray' />
                </span>
                <span className='font-semibold text-black-500'>
                  ₹ {toCurrency(fundDetails.holding)}
                </span>
              </div> */}
            </div>

            <div className='flex flex-col justify-center items-center gap-1.5 mt-6'>
              <Label htmlFor='amount' className='text-black-400 text-xs'>
                Redemption amount
              </Label>
              <Input
                startAdornment='₹'
                type='amount'
                id='amount'
                placeholder='Amount'
                className='border-none outline-none max-w-[120px] mx-auto'
                value={redemptionAmount}
                onChange={(e) => setRedemptionAmount(e.target.value)}
              />
              {/* <p className='text-xs text-error-600 text-center'>
                Minimum first investment amount is ₹ {toCurrency(fundDetails?.minInvt)}
              </p> */}
            </div>

            <div className='flex justify-center gap-x-2 mt-2'>
              {[25, 50, 75].map((btn) => (
                <Button
                  size='simple'
                  className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                  onClick={() => setRedemptionAmount(() => (fundDetails.holding / 100) * btn)}
                >
                  + {toCurrency(btn)} %
                </Button>
              ))}

              <Button
                size='simple'
                className='px-3 py-[2px] rounded-[50px] bg-error-50 hover:bg-error-50 text-error-500'
                onClick={() => setRedemptionAmount(fundDetails.holding)}
              >
                All
              </Button>
            </div>

            <div className='flex flex-col gap-y-6'>
              <div>
                <Card className='flex items-center gap-3 p-4 w-full max-w-md hover:bg-accent/50 transition-colors cursor-pointer'>
                  <div className='flex items-center justify-center w-10 h-10 rounded-full border'>
                    {src ? (
                      <img
                        src={src}
                        alt='bank logos'
                        onError={handleError}
                        height={24}
                        width={24}
                      />
                    ) : (
                      <AccountBalance className='text-primary-200 w-5 h-5' />
                    )}
                    {/* <AccountBalance className='w-5 h-5' /> */}
                  </div>
                  <div className='flex flex-col'>
                    <h3 className='font-semibold'>
                      {bankDetails && bankDetails?.accountHolderName}
                    </h3>
                    <p className='text-sm text-muted-foreground'>
                      {bankDetails && bankDetails?.bankName} A/c ending in{' '}
                      {bankDetails && bankDetails?.accountNo && bankDetails?.accountNo.slice(-4)}
                    </p>
                  </div>
                </Card>
              </div>

              <Button
                className='rounded-xl flex-1 bg-error-500 hover:bg-error-400'
                loading={isLoading}
                onClick={() => redeemOrder()}
              >
                Redeem
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MFRedeem
