/* eslint-disable */

import React, { useContext, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import EditIcon from '@mui/icons-material/Edit'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '../ui/menubar'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '../ui/sheet'
import { Separator } from '../ui/separator'
import { NavbarContext } from '../../Provider/ShowNavbar'
import { toCurrency } from '../../utils/CurrencyUtil'
import { addNumberSuffix, monthShortNames } from '../../utils/Util'

const SipTable = ({ sips, setOpen, setModifyOpen, setModifiedInvestment }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [detailsOpen, setDetailsOpen] = useState(false)

  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { displayNavbar } = useContext(NavbarContext)

  const handleSheetOpen = (sips) => {
    setSelectedInvoice(sips)
    setOpen(true)
  }

  const handleModify = () => {
    setModifiedInvestment({ ...selectedInvoice })
    setDetailsOpen(false)
    setModifyOpen(true)
  }

  return (
    <div>
      {isDesktop ? (
        <Table>
          <TableHeader className='bg-[#F4F7FE]'>
            <TableRow className='border-none'>
              <TableHead className='w-[100px] rounded-l-xl text-center'></TableHead>
              <TableHead className='font-DMSans font-medium text-sm text-primary-400 w-[50%]'>
                Investment Name
              </TableHead>
              {/* <TableHead className='text-right'>Annual Step Up</TableHead> */}
              <TableHead className='text-right font-DMSans font-medium text-sm text-primary-400'>
                Investment amount
              </TableHead>
              <TableHead className='text-right rounded-r-xl'></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sips.map((sips, index) => (
              <TableRow
                key={sips.date}
                onMouseEnter={() => {
                  setIsHovered(true)
                  setHoveredRow(index)
                }}
                onMouseLeave={() => {
                  setIsHovered(false)
                  setHoveredRow(null)
                }}
                style={{
                  boxShadow:
                    isHovered && hoveredRow === index && '0px 24px 24px -20px rgba(0, 0, 0, 0.08)',
                }}
              >
                <TableCell className='font-medium'>
                  <div className='h-12 w-12 bg-primary-50 rounded-lg flex justify-center items-center'>
                    <div className='text-center font-DMSans text-primary-500'>
                      <p className='font-semibold text-[18px] leading-[26px]'>
                        {sips?.sipStartDate.split('/')[0]}
                      </p>
                      <p className='font-medium text-[10px] leading-3'>
                        {monthShortNames[sips?.sipStartDate.split('/')[1] - 1]}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <p className='font-DMSans font-semibold text-base text-black-500'>
                    {sips?.sname}
                  </p>
                  <p className='text-sm text-muted-foreground'>
                    Repeats on the {addNumberSuffix(sips?.sipStartDate.split('/')[0])} of every
                    month
                  </p>
                  <p className='text-xs'>MandateID - {sips?.mandateId}</p>
                </TableCell>
                {/* <TableCell className='text-right'>+{sips?.Annual_Step_Up}%</TableCell> */}
                <TableCell className='text-right font-DMSans font-semibold text-base'>
                  ₹ {toCurrency(sips?.installmentAmt)}
                </TableCell>
                {/* <TableCell className='text-right'>
                  <Menubar>
                    <MenubarMenu className='bg-transparent'>
                      <MenubarTrigger className='hover:bg-[#F4F7FE] px-1 py-[10px] rounded-full h-10 w-7 flex justify-center items-center'>
                        <MoreVertIcon sx={{ color: '#41558D' }} />
                      </MenubarTrigger>

                      <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                        <MenubarItem
                          onClick={() => setOpen(true)}
                          className='py-2 pl-3 text-sm text-black-500 cursor-pointer  hover:bg-blue-100'
                        >
                          Modify
                        </MenubarItem>

                        <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer hover:bg-blue-100 '>
                          Pause
                        </MenubarItem>

                        <MenubarItem
                          onClick={() => {
                            setOpen(false)
                          }}
                          className='py-2 pl-3 text-sm cursor-pointer hover:bg-red-100 hover:text-red-500'
                        >
                          Cancel
                        </MenubarItem>
                      </MenubarContent>
                    </MenubarMenu>
                  </Menubar>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table>
          <TableBody>
            {sips.map((sips, index) => (
              <TableRow
                key={sips.date}
                onMouseEnter={() => {
                  setIsHovered(true)
                  setHoveredRow(index)
                }}
                onMouseLeave={() => {
                  setIsHovered(false)
                  setHoveredRow(null)
                }}
                style={{
                  boxShadow:
                    isHovered && hoveredRow === index && '0px 24px 24px -20px rgba(0, 0, 0, 0.08)',
                }}
              >
                <TableCell className='font-medium text-center'>
                  <div className='h-12 w-12 bg-primary-50 rounded-lg flex justify-center items-center'>
                    <div className='text-center font-DMSans text-primary-500'>
                      <p className='font-semibold text-[18px] leading-[26px]'>
                        {sips?.sipStartDate.split('/')[0]}
                      </p>
                      <p className='font-medium text-[10px] leading-3'>
                        {monthShortNames[sips?.sipStartDate.split('/')[1] - 1]}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='flex justify-between'>
                    <div>
                      <p className='font-DMSans font-semibold text-base'>{sips?.sname}</p>
                      <p className='text-sm text-muted-foreground'>
                        Repeats on the {addNumberSuffix(sips?.sipStartDate.split('/')[0])} of every
                        month
                      </p>
                    </div>
                    {/* <Sheet open={detailsOpen} onOpenChange={setDetailsOpen}>
                      <SheetTrigger
                        onClick={() => handleSheetOpen(sips)}
                        className='hover:bg-[#F4F7FE] px-1 py-[10px] rounded-full h-6 w-6 flex justify-center items-center'
                      >
                        <ArrowForwardIosIcon sx={{ color: '#9E9E9E' }} />
                      </SheetTrigger>
                      <SheetContent
                        side='bottom'
                        className={`${displayNavbar ? 'bottom-20' : 'bottom-0'} h-[264px] p-0`}
                        style={{ borderRadius: '24px 24px 0px 0px' }}
                      >
                        <SheetHeader className={'px-6 pt-6'}>
                          <SheetTitle>
                            <div className='flex gap-3'>
                              <div className='h-12 w-12 bg-red-500'></div>
                              <div className='text-start'>
                                <p className='font-DMSans font-semibold text-base text-black-500 mb-1'>
                                  {sips?.sname}
                                </p>
                                <p className='font-DMSans font-medium text-xs text-black-400'>
                                  Mandate ID - {sips?.mandateId}
                                </p>

                                <div className='flex justify-between items-center font-DMSans font-semibold'>
                                  <p className='text-xs text-black-400'></p>
                                  <p className='text-base text-black-500'>
                                    ₹ {sips?.installmentAmt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </SheetTitle>
                        </SheetHeader>
                        <Separator />
                        <div className='mt-4 px-6'>
                          <div
                            onClick={handleModify}
                            className='flex  items-center gap-x-4 hover:bg-[#F4F7FE] p-[10px] rounded-lg'
                          >
                            <EditIcon sx={{ height: '16px', width: '16px' }} />
                            <p className='font-DMSans font-medium text-sm'>Modify</p>
                          </div>
                          <div className='flex items-center gap-x-4  hover:bg-[#F4F7FE] p-[10px] rounded-lg'>
                            <PauseRoundedIcon sx={{ height: '16px', width: '16px' }} />
                            <p className='font-DMSans font-medium text-sm'>Pause</p>
                          </div>
                          <div
                            onClick={() => {
                              setDetailsOpen(false)
                            }}
                            className='flex items-center gap-x-4  hover:bg-red-100 p-[10px] rounded-lg'
                          >
                            <CancelRoundedIcon sx={{ height: '16px', width: '16px' }} />
                            <p className='font-DMSans font-medium text-sm'>Cancel</p>
                          </div>
                        </div>
                      </SheetContent>
                    </Sheet> */}
                  </div>
                  <div className='flex justify-between items-center font-DMSans font-semibold'>
                    <p className='text-xs'>MandateID - {sips?.mandateId}</p>
                    <p className='text-base'>₹ {sips?.installmentAmt}</p>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

export default SipTable
