/* eslint-disable */
import { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../Components/ui/dialog'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../../Components/ui/help-me-start-drawer'
import { Input } from '../../Components/ui/input'
import { Label } from '../../Components/ui/label'
import useMediaQuery from '../../hooks/useMediaQuery'
import './HelpMeStart.css'
import { ChevronLeft, ChevronRight, ChevronsUpDown, MoveLeft, MoveRight, X } from 'lucide-react'
import { toCurrency } from '../../utils/CurrencyUtil'
import { get, post } from '../../http/restservice'
import { useFormik } from 'formik'
import env from '../../env/env'
import { useNavigate } from 'react-router-dom'
import IconsSelector from '../../store/IconsStore'
import { useRecoilValue } from 'recoil'
import { DrawerContext } from '../../Provider/DrawerContext'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '../ui/sheet'

export const HelpMeStartWeb = ({ open, setOpen, phoneNumber }) => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(true)
  const [protoPlans, setProtoPlans] = useState(null)
  const navigate = useNavigate()
  const iconsList = useRecoilValue(IconsSelector)

  const [formData, setFormData] = useState({
    phoneNumber: phoneNumber,
    amount: '',
    duration: '',
    investType: '',
  })

  const [currentOptionIndex, setCurrentOptionIndex] = useState(0)

  const addUserPlan = async () => {
    if (!formData.isInvested) {
    }

    const payload = {
      ...formData,
      amount: Number(formData.amount),
    }

    try {
      const res = await post(`${env.API_URL}customerservice/api/protoFirstPlan/`, payload)
      if (!res.isError) {
        setLoading(false)
        setProtoPlans(res.data)
        setStep(3)
      }
    } catch (error) {
      console.log('[USER_PLAN_ERROR]', error)
    }
  }

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/protoFirstPlan/${phoneNumber}`)

        if (!res.isError) {
          if (res != '') {
            setCurrentOptionIndex(res.investType === 'SIP' ? 1 : 0)
            setFormData({
              phoneNumber: res.phoneNumber,
              amount: res.amount,
              duration: res.duration,
              investType: res.investType,
              isInvested: res.isInvested,
            })
          }
        }
      } catch (error) {
        console.log('[USER_PLAN_ERROR]', error)
      }
    }

    fetchUserPlan()
  }, [])

  const protoFirstPlans =
    protoPlans &&
    Object.entries(protoPlans[0]).map(([key, value]) => ({
      category: key,
      ...value,
    }))

  const handleClick = (basketId) => {
    navigate('/dashboard/theme-details', {
      state: {
        selectedItem: { id: basketId, investType: formData.investType, amount: formData.amount },
      },
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className={`max-w-[736px] p-0 overflow-hidden`}>
        <DialogHeader
          className={`text-start relative rounded-t-3xl p-8 ${step != 3 && 'h-[326px]'}`}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <div
            className={`${step === 3 && 'blur-sm animate-blurTransition'}`}
            style={{
              background: 'url(/Home/car-bg.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              zIndex: -1,
            }}
          ></div>

          <div>
            <DialogTitle className='text-2xl text-[#1D3B7A] font-semibold flex justify-between'>
              {step === 3 ? 'Curating plans for you...' : 'Let Fabits help you out'}
              <span
                className='bg-white rounded-full p-2 cursor-pointer'
                onClick={() => setOpen(false)}
              >
                <X className='h-4 w-4 text-black-300' />
              </span>
            </DialogTitle>
            <DialogDescription className='text-[#2E529E]'>
              {step === 3 ? (
                <p className='flex gap-x-1'>
                  Plans in which you can invest
                  <span className='font-semibold underline'>
                    &#8377; {toCurrency(formData.amount)},
                  </span>
                  {formData.investType !== 'One Time' && (
                    <>
                      <span className='font-semibold underline'>every month </span>for
                      <span className='font-semibold underline'>
                        {parseInt(formData.duration.match(/\d+/)[0], 10)} years.
                      </span>
                    </>
                  )}
                  <span
                    onClick={() => setStep(1)}
                    className='material-symbols-rounded cursor-pointer'
                  >
                    stylus
                  </span>
                </p>
              ) : (
                'Answer a few questions to start building your wealth like the experts'
              )}
            </DialogDescription>
          </div>

          {step === 3 && !loading && (
            <div className='flex gap-x-5 z-10 pt-5'>
              {protoPlans &&
                protoFirstPlans.map((plan, index) => {
                  return (
                    <div
                      className='flex flex-col flex-1 items-center text-center bg-white rounded-2xl p-4 cursor-pointer hover:shadow-lg'
                      onClick={() => handleClick(plan.basketId)}
                    >
                      <div className='pb-2'>
                        <img
                          src={iconsList['light' + plan.basketId]}
                          className='w-16 rounded-full'
                          alt={plan.name}
                        />
                      </div>
                      <div className='flex flex-col justify-between h-full w-full'>
                        <div>
                          <h3 className='text-base text-[#1D3B7A] font-semibold'>{plan.name}</h3>
                          <p className='text-sm text-[#5E78AE]'>{plan.desc}</p>
                        </div>
                        <div className='flex justify-between items-center w-full mt-4 text-start'>
                          <div>
                            <p className='text-[#5E78AE] font-medium text-sm'>
                              {parseInt(formData.duration.match(/\d+/)[0], 10) === 5
                                ? 3
                                : parseInt(formData.duration.match(/\d+/)[0], 10)}
                              Y Returns
                            </p>
                            <span className='text-[#1D3B7A] font-semibold text-sm'>
                              {plan['return%']}
                            </span>
                          </div>
                          <div className='text-[#1D3B7A] font-semibold text-sm'>
                            <img
                              src={`/Volatility/${
                                plan.category === 'Low Risk'
                                  ? 'low' + '.svg'
                                  : plan.category === 'High Risk'
                                  ? 'high' + '.svg'
                                  : 'medium' + '.svg'
                              }`}
                              alt={plan.category}
                            />

                            <p
                              className={`font-semibold ${
                                plan.category === 'High Risk'
                                  ? 'text-error-500'
                                  : plan.category === 'Med Risk'
                                  ? 'text-warning-500'
                                  : 'text-secondary-500'
                              } font-medium text-sm`}
                            >
                              {plan.category}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}

          <img
            src='/Home/car.svg'
            className={`w-25 h-16 ${
              step === 3 ? 'animate-moveSlow blur-sm' : 'animate-carMove'
            } absolute left-0 bottom-14`}
          />
        </DialogHeader>
        {step !== 3 && (
          <InvestmentForm
            step={step}
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
            addUserPlan={addUserPlan}
            currentOptionIndex={currentOptionIndex}
            setCurrentOptionIndex={setCurrentOptionIndex}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export const HelpMeStartMobile = ({ open, setOpen, phoneNumber }) => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(true)
  const [protoPlans, setProtoPlans] = useState(null)
  const navigate = useNavigate()
  const iconsList = useRecoilValue(IconsSelector)

  const [formData, setFormData] = useState({
    phoneNumber: phoneNumber,
    amount: '',
    duration: '',
    investType: '',
  })

  const [currentOptionIndex, setCurrentOptionIndex] = useState(0)

  const addUserPlan = async () => {
    if (!formData.isInvested) {
    }

    const payload = {
      ...formData,
      amount: Number(formData.amount),
    }

    try {
      const res = await post(`${env.API_URL}customerservice/api/protoFirstPlan/`, payload)
      if (!res.isError) {
        setLoading(false)

        setProtoPlans(res.data)
        setStep(3)
      }
    } catch (error) {
      console.log('[USER_PLAN_ERROR]', error)
    }
  }

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/protoFirstPlan/${phoneNumber}`)

        if (!res.isError) {
          if (res != '') {
            setCurrentOptionIndex(res.investType === 'SIP' ? 1 : 0)
            setFormData({
              phoneNumber: res.phoneNumber,
              amount: res.amount,
              duration: res.duration,
              investType: res.investType,
              isInvested: res.isInvested,
            })
          }
        }
      } catch (error) {
        console.log('[USER_PLAN_ERROR]', error)
      }
    }

    fetchUserPlan()
  }, [])

  const protoFirstPlans =
    protoPlans &&
    Object.entries(protoPlans[0]).map(([key, value]) => ({
      category: key,
      ...value,
    }))

  const handleClick = (basketId) => {
    setOpen(false)
    navigate('/dashboard/theme-details', {
      state: {
        selectedItem: { id: basketId, investType: formData.investType, amount: formData.amount },
      },
    })
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent side='bottom' className='p-0 border-none rounded-t-3xl'>
        <SheetHeader
          className={`text-start relative ${step === 3 ? 'h-auto' : 'h-[288px]'} rounded-t-3xl p-8`}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <div
            className={`${step === 3 && 'blur-sm animate-blurTransition'}`}
            style={{
              background: 'url(/Home/car-bg.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
            }}
          ></div>
          <div>
            <SheetTitle className='text-xl text-[#1D3B7A] font-semibold'>
              {step === 3 ? 'Curating plans for you...' : 'Let Fabits help you out'}
            </SheetTitle>
            <SheetDescription className='text-[#2E529E]'>
              {step === 3 ? (
                <p className='text-center'>
                  Plans in which you can invest{' '}
                  {formData.investType === 'One Time' && (
                    <span className='font-semibold underline ml-1'>
                      {' '}
                      &#8377; {toCurrency(formData.amount)}
                    </span>
                  )}
                  {formData.investType !== 'One Time' && (
                    <>
                      <span className='font-semibold underline ml-1'>
                        {' '}
                        &#8377; {toCurrency(formData.amount)}, every month
                      </span>{' '}
                      for
                      <span className='font-semibold underline'>
                        {' '}
                        {parseInt(formData.duration.match(/\d+/)[0], 10)} years.
                      </span>
                    </>
                  )}
                  <span
                    onClick={() => setStep(1)}
                    className='material-symbols-rounded cursor-pointer ml-1'
                  >
                    stylus
                  </span>
                </p>
              ) : (
                'Answer a few questions to start building your wealth like the experts'
              )}
            </SheetDescription>
          </div>

          {step === 3 && (
            <div className='flex flex-col gap-y-2 z-10 overflow-auto'>
              {protoPlans &&
                protoFirstPlans.map((plan, index) => {
                  return (
                    <div
                      onClick={() => handleClick(plan.basketId)}
                      className='flex flex-col flex-1 items-center text-center bg-white rounded-2xl p-2 cursor-pointer'
                    >
                      <div className='pb-2'>
                        <img
                          src={iconsList['light' + plan.basketId]}
                          alt={plan.name}
                          className='w-16 rounded-full'
                        />
                      </div>
                      <div className='flex flex-col justify-between h-full w-full px-2'>
                        <div>
                          <h3 className='text-base text-[#1D3B7A] font-semibold'>{plan.name}</h3>
                          <p className='text-sm text-[#5E78AE]'>{plan.desc}</p>
                        </div>
                        <div className='flex justify-between items-center w-full mt-4 text-start'>
                          <div>
                            <p className='text-[#5E78AE] font-medium text-sm'>
                              {parseInt(formData.duration.match(/\d+/)[0], 10) === 5
                                ? 3
                                : parseInt(formData.duration.match(/\d+/)[0], 10)}
                              Y Returns
                            </p>
                            <span className='text-[#1D3B7A] font-semibold text-sm'>
                              {plan['return%']}
                            </span>
                          </div>
                          <div>
                            <span className='text-[#1D3B7A] font-semibold text-sm'>
                              <img
                                src={`/Volatility/${
                                  plan.category === 'Low Risk'
                                    ? 'low' + '.svg'
                                    : plan.category === 'High Risk'
                                    ? 'high' + '.svg'
                                    : 'medium' + '.svg'
                                }`}
                                alt={plan.category}
                              />

                              <p
                                className={`font-semibold ${
                                  plan.category === 'High Risk'
                                    ? 'text-error-500'
                                    : plan.category === 'Med Risk'
                                    ? 'text-warning-500'
                                    : 'text-secondary-500'
                                } font-medium text-sm`}
                              >
                                {plan.category}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}
          <img
            src='/Home/car.svg'
            className={`w-25 h-16 ${
              step === 3 ? 'animate-moveSlow blur-sm' : 'animate-carMove'
            } absolute left-0 bottom-14`}
          />
        </SheetHeader>
        {step !== 3 && (
          <InvestmentForm
            step={step}
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
            addUserPlan={addUserPlan}
            currentOptionIndex={currentOptionIndex}
            setCurrentOptionIndex={setCurrentOptionIndex}
          />
        )}
      </SheetContent>
    </Sheet>
  )
}

function InvestmentForm({
  step,
  setStep,
  setFormData,
  formData,
  addUserPlan,
  currentOptionIndex,
  setCurrentOptionIndex,
}) {
  const options = ['One Time', 'SIP']
  const TimePeriods = Object.freeze({
    LESS_THAN_ONE_YEAR: 'Less than 1 year',
    LESS_THAN_THREE_YEARS: 'Less than 3 years',
    LESS_THAN_FIVE_YEARS: 'Less than 5 years',
    LESS_THAN_TEN_YEARS: 'Less than 10 years',
  })

  const handleClick = (value) => {
    if (value >= 0) {
      setCurrentOptionIndex(value)
      setFormData({ ...formData, investType: options[value] })
    } else {
      setCurrentOptionIndex((prevIndex) => (prevIndex + 1) % options.length)
      setFormData({ ...formData, investType: options[currentOptionIndex] })
    }
  }

  return (
    <div
      className='lg:rounded-b-3xl py-6 md:pt-6 -mt-5 md:pb-8 flex flex-col gap-y-6'
      style={{
        background: 'linear-gradient(180deg, #E6E66A 55.89%, #FFFFDB 70.74%)',
        boxShadow: '0px 4px 24px 0px rgba(44, 60, 0, 0.12)',
      }}
    >
      {step === 1 && (
        <>
          <div
            className={`${
              step === 1 ? 'block' : 'hidden'
            } px-4 md:px-8 flex justify-between items-center`}
          >
            <h2 className='text-xl md:text-2xl font-semibold'>
              How much time do you wish to invest for?
            </h2>
            <span className='bg-black-500 py-[3px] px-[10px] rounded-[20px] text-[#F6F6F6]'>
              {step}/3
            </span>
          </div>

          <div
            className={` ${
              step === 1 ? 'block' : 'hidden'
            } flex justify-center gap-x-2 md:gap-x-6 px-4 md:px-8 box-border text-center h-auto`}
          >
            <span
              onClick={() => setFormData({ ...formData, duration: TimePeriods.LESS_THAN_ONE_YEAR })}
              className={`${
                formData.duration === TimePeriods.LESS_THAN_ONE_YEAR && 'border-2 border-[#83A720]'
              } hover:border-2 md:max-w-[136px] h-full hover:border-[#83A720] cursor-pointer py-[10px] px-5 rounded-xl`}
            >
              <p className='text-[#394C03] text-sm font-semibold'>Brief</p>
              <p className='text-[#677C2B] text-sm flex items-center'>
                <ChevronLeft className='h-4 w-4' />
                1Y
              </p>
            </span>
            <span
              onClick={() =>
                setFormData({ ...formData, duration: TimePeriods.LESS_THAN_THREE_YEARS })
              }
              className={`${
                formData.duration === TimePeriods.LESS_THAN_THREE_YEARS &&
                'border-2 border-[#83A720]'
              } hover:border-2 md:max-w-[136px] h-full hover:border-[#83A720] cursor-pointer py-[10px] px-5 rounded-xl`}
            >
              <p className='text-[#394C03] text-sm font-semibold'>Short</p>
              <p className='text-[#677C2B] text-sm'>1Y-3Y</p>
            </span>
            <span
              onClick={() =>
                setFormData({ ...formData, duration: TimePeriods.LESS_THAN_FIVE_YEARS })
              }
              className={`${
                formData.duration === TimePeriods.LESS_THAN_FIVE_YEARS &&
                'border-2 border-[#83A720]'
              } hover:border-2 md:max-w-[136px] h-full hover:border-[#83A720] cursor-pointer py-[10px] px-5 rounded-xl`}
            >
              <p className='text-[#394C03] text-sm font-semibold'>Mid</p>
              <p className='text-[#677C2B] text-sm'>3Y-5Y</p>
            </span>
            <span
              onClick={() =>
                setFormData({ ...formData, duration: TimePeriods.LESS_THAN_TEN_YEARS })
              }
              className={`${
                formData.duration === TimePeriods.LESS_THAN_TEN_YEARS && 'border-2 border-[#83A720]'
              } hover:border-2 md:max-w-[136px] h-full hover:border-[#83A720] cursor-pointer py-[10px] px-5 rounded-xl`}
            >
              <p className='text-[#394C03] text-sm font-semibold'>Long</p>
              <p className='text-[#677C2B] text-sm flex items-center'>
                <ChevronRight className='h-4 w-4' />
                5Y
              </p>
            </span>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div
            className={`${
              step === 2 ? 'block' : 'hidden'
            } px-4 md:px-8 flex justify-between items-center`}
          >
            <h2 className='text-2xl font-semibold'>What are your investment preferences?</h2>
            <span className='bg-black-500 py-[3px] px-2 md:px-[10px] rounded-[20px] text-[#F6F6F6]'>
              {step}/3
            </span>
          </div>

          <div
            className={`${
              step === 2 ? 'block' : 'hidden'
            } flex flex-col lg:flex-row gap-y-6 lg:items-center lg:justify-center gap-x-6 px-8 box-border text-start lg:text-center lg:h-[68px] text-black-400`}
          >
            I would like to make a
            <div className='lg:hidden flex justify-between gap-x-2'>
              <button
                onClick={() => handleClick(0)}
                className={`${
                  currentOptionIndex === 0 && 'border-[2px]'
                }  text-[#394C03] font-semibold flex-1 border-[#83A720] py-[10px] pl-4 pr-5 rounded-[12px]`}
              >
                One Time
              </button>
              <button
                onClick={() => handleClick(1)}
                className={`${
                  currentOptionIndex === 1 && 'border-[2px]'
                } text-[#394C03] font-semibold flex-1 border-[#83A720] py-[10px] pl-4 pr-5 rounded-[12px]`}
              >
                Monthly
              </button>
            </div>
            <button
              onClick={() => handleClick(currentOptionIndex === 0 ? 1 : 0)}
              className='border-[2px] border-[#83A720] py-[10px] pl-4 pr-5 rounded-[12px] hidden lg:flex items-center gap-x-2'
            >
              <ChevronsUpDown className='text-[#394C03] h-4 w-4' />

              {options[currentOptionIndex] === 'SIP' ? 'Monthly' : 'One Time'}
            </button>
            <div className='flex justify-between items-center gap-x-2'>
              <p>investment of</p>
              <Input
                value={formData.amount}
                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                type='number'
                className='max-w-40 bg-[#F5F8CC] focus-visible:ring-0 focus-visible:ring-offset-0'
              />
            </div>
          </div>
        </>
      )}

      <div
        className={`flex ${step === 1 ? 'justify-end' : 'justify-between'} ${
          step === 3 ? 'hidden' : 'block'
        } gap-x-4 px-4 md:px-8`}
      >
        {step !== 1 && (
          <button
            onClick={() => setStep((prev) => prev - 1)}
            className='bg-[#F5F8CC] flex gap-x-12 py-[10px] pl-4 pr-3 rounded-lg text-white'
          >
            <MoveLeft className='text-[#83A720]' />
          </button>
        )}

        {step !== 3 && (
          <button
            disabled={
              (step === 1 && formData.duration === '') ||
              (step === 2 && formData.investType === '') ||
              (step === 2 && formData.amount === '')
            }
            onClick={() => (step === 2 ? addUserPlan() : setStep((prev) => prev + 1))}
            className='w-full md:w-auto bg-[#83A720] flex justify-between md:gap-x-12 py-3 md:py-[10px] px-4 md:pl-4 md:pr-3 rounded-lg text-white cursor-pointer disabled:bg-secondary-200'
          >
            {step === 2 ? 'Show plans' : 'Next'}
            <MoveRight />
          </button>
        )}
      </div>
    </div>
  )
}
