import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'

const PostLoginContainer = () => {
  const location = useLocation()
  const [openNav, setOpenNav] = useState(false)
  const isMFPage =
    location.pathname === '/dashboard/mutual-funds' ||
    location.pathname === '/dashboard/mutual-funds/' ||
    location.pathname === '/dashboard/mutual-funds/investments' ||
    location.pathname === '/dashboard/mutual-funds/details' ||
    location.pathname === '/dashboard/mutual-funds/place-order' ||
    location.pathname === '/dashboard/mutual-funds/orders' ||
    location.pathname === '/dashboard/mutual-funds/order-payment' ||
    location.pathname === '/dashboard/mutual-funds/payment-request' ||
    location.pathname === '/dashboard/mutual-funds/sips' ||
    location.pathname === '/dashboard/mutual-funds/redeem/place-order'

  const handleOpenNav = () => {
    setOpenNav(!openNav)
  }

  return (
    <>
      {!location.pathname.includes('/tax-filing') && (
        <div className='w-full hidden lg:block'>
          <Navbar />
        </div>
      )}

      <div className='flex justify-center min-h-screen'>
        <div
          className={`w-full lg:mt-[52px] ${
            isMFPage ? 'max-w-auto mb-0' : 'max-w-[1128px] mb-16 lg:pb-12 lg:pt-4'
          }`}
        >
          {!location.pathname.includes('/tax-filing') && <Header handleOpenNav={handleOpenNav} />}
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default PostLoginContainer
