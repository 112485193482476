/* eslint-disable */
import { CalendarIcon, ChevronLeft, Pen } from 'lucide-react'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlaceOrderBg from '../../assets/images/PlaceOrderBg.png'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { useModal } from '../../hooks/use-modal-store'
import { NavbarContext } from '../../Provider/ShowNavbar'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { userDetailsState } from '../LoginState'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { addNumberSuffix, addMonth, monthNames } from '../../utils/Util'
import MFSipMandate from './MFSipMandate'
import { Card, CardContent } from '../../Components/ui/card'

const MFPlaceOrder = () => {
  const location = useLocation()

  const [fundDetails] = useState(location?.state?.fundDetails)
  const sipDates = [3, 5, 10, 15, 22, 25]
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const { displayNavbar } = useContext(NavbarContext)
  const [tabValue, setTabValue] = useState(location.state.destination || 'One-Time')

  const [selectedDay, setSelectedDay] = useState(5)
  const [userDetails] = useRecoilState(userDetailsState)
  const [oneTimeAmount, setOneTimeAmount] = useState(Math.floor(fundDetails?.minInvt) || 0)
  const [sipAmount, setSipAmount] = useState(Math.floor(fundDetails?.minSipAmount) || 0)
  const [isLoading, setIsLoading] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userData?.uid.split('-')[0]
  const [showMfSipMandatePopup, setShowMfSipMandatePopup] = useState(false)
  const [sipDate, setSipDate] = useState('')

  const [nextSipDay, nextSipMonth, nextSipYear] = sipDate.split('/')
  const [redirectURL, setRedirectURL] = useState('')
  const [callMandateAuthURL, setCallMandateAuthURL] = useState(false)

  const currentDate = new Date()
  const today = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  const nextDay1 = new Date()
  nextDay1.setDate(today + 1)
  const nextDay2 = new Date()
  nextDay2.setDate(today + 2)

  const [mandateId, setMandateId] = useState('')
  const [timeLeft, setTimeLeft] = useState(120)
  const [timeMandateDetailsLeft, setTimeMandateDetailsLeft] = useState(null)
  const [mandateStatus, setMandateStatus] = useState(null)
  useEffect(() => {
    const handlePolling = async () => {
      if (callMandateAuthURL && mandateId) {
        // Check if it's time to call the eMandateAuthURL
        if (
          timeLeft === 110 ||
          timeLeft === 100 ||
          timeLeft === 80 ||
          timeLeft === 60 ||
          timeLeft === 40 ||
          timeLeft === 20 ||
          timeLeft === 0
        ) {
          try {
            const eMandateResponse = await getEMandateAuthURL(mandateId)

            if (eMandateResponse.Status == 100) {
              setTimeLeft(-10)
              handleSuccessfulEMandate(eMandateResponse)
              return
            }

            if (timeLeft <= 0 && eMandateResponse.Status != 100) {
              handleFailedEMandate()
            }
          } catch (error) {
            console.error('Error in eMandateAuthURL:', error)
            handleFailedEMandate()
          }
        }

        // Countdown logic
        if (timeLeft > 0) {
          const timerId = setTimeout(() => {
            setTimeLeft((prevTime) => prevTime - 1)
          }, 1000)

          // Cleanup timeout
          return () => clearTimeout(timerId)
        }
      }
    }

    handlePolling() // Call the polling function
  }, [callMandateAuthURL, timeLeft])

  useEffect(() => {
    const handlePolling = async () => {
      if (redirectURL) {
        // Check if it's time to call the eMandateAuthURL
        if (
          timeMandateDetailsLeft === 210 ||
          timeMandateDetailsLeft === 190 ||
          timeMandateDetailsLeft === 170 ||
          timeMandateDetailsLeft === 150 ||
          timeMandateDetailsLeft === 130 ||
          timeMandateDetailsLeft === 100 ||
          timeMandateDetailsLeft === 80 ||
          timeMandateDetailsLeft === 60 ||
          timeMandateDetailsLeft === 40 ||
          timeMandateDetailsLeft === 20 ||
          timeMandateDetailsLeft === 0
        ) {
          try {
            const response = await getMandateDeatils(mandateId)

            if (response.isError) {
              setShowMfSipMandatePopup(false)
              setMessageModalState({
                message: 'Error occurred while fetching mandate details or processing e-Mandate',
                severity: 'error',
              })
            }

            if (!response.isError && response.MandateDetails[0].status == 'UNDER PROCESSING') {
              setTimeMandateDetailsLeft(-10)
              setMandateStatus(response.MandateDetails[0].status)
              await handleXSIP(mandateId, response.MandateDetails[0].status)
              return
            }

            // if (!response.isError) {
            //   setMandateStatus()
            // }
          } catch (error) {
            console.error('Error in eMandateAuthURL:', error)
            handleFailedEMandate()
          }
        }

        // Countdown logic
        if (timeMandateDetailsLeft > 0) {
          const timerId = setTimeout(() => {
            setTimeMandateDetailsLeft((prevTime) => prevTime - 1)
          }, 1000)

          // Cleanup timeout
          return () => clearTimeout(timerId)
        }
      }
    }

    handlePolling() // Call the polling function
  }, [redirectURL, timeMandateDetailsLeft])

  // useEffect(() => {
  //   let timerId

  //   const handlePolling = async () => {
  //     if (!redirectURL || timeMandateDetailsLeft === null) return

  //     if (timeMandateDetailsLeft <= 0) {
  //       try {
  //         const response = await getMandateDeatils(mandateId)

  //         if (response.isError) {
  //           setShowMfSipMandatePopup(false)
  //           setMessageModalState({
  //             message: 'Error occurred while fetching mandate details or processing e-Mandate',
  //             severity: 'error',
  //           })
  //           return
  //         }

  //         const status = response.MandateDetails[0].status
  //         setMandateStatus(status)

  //         if (status === 'UNDER PROCESSING') {
  //           // Retry immediately
  //           setTimeMandateDetailsLeft(-10)
  //           await handleXSIP(mandateId, status)
  //           return
  //         }
  //       } catch (error) {
  //         console.error('Error in eMandateAuthURL:', error)
  //         handleFailedEMandate()
  //         return
  //       }

  //       // Start next polling interval

  //       setNextInterval()
  //     }
  //   }

  //   const setNextInterval = () => {
  //     if (timeMandateDetailsLeft === 60) {
  //       setTimeMandateDetailsLeft(30) // After 1 minute, set next interval to 30s
  //     } else if (timeMandateDetailsLeft === 30) {
  //       setTimeMandateDetailsLeft(30) // 2nd 30s interval
  //     } else if (timeMandateDetailsLeft === 0) {
  //       setTimeMandateDetailsLeft(10) // Final 10s interval
  //     } else {
  //       setTimeMandateDetailsLeft(60) // Default to 1 minute
  //     }
  //   }

  //   if (timeMandateDetailsLeft > 0) {
  //     timerId = setTimeout(() => {
  //       setTimeMandateDetailsLeft((prev) => prev - 1)
  //     }, 1000)
  //   } else {
  //     handlePolling()
  //   }

  //   return () => clearTimeout(timerId)
  // }, [redirectURL, timeMandateDetailsLeft])

  useEffect(() => {
    // Initialize countdown when redirectURL is available
    if (redirectURL) {
      setTimeMandateDetailsLeft(60) // Start with 1-minute interval
    }
  }, [redirectURL])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const res = addMonth(today, selectedDay)
    setSipDate(res)
  }, [selectedDay, today])

  const placeOneTimeOrder = async () => {
    try {
      setIsLoading(true)

      const payload = {
        orderType: 'PURCHASE',
        schemeCode: fundDetails?.bseSchemeCode,
        clientCode: clientCode.toUpperCase(),
        orderVal: oneTimeAmount,
      }

      const response = await post('mutualfundservice/api/bseStar/mfOrder/order', payload)

      setIsLoading(false)

      if (response.isError) {
        setMessageModalState({
          message: response.response.error,
          severity: 'error',
        })
      }

      const orderNumberMatch = response.data.match(/ORDER NO: (\d+)/)

      const orderNumber = orderNumberMatch ? orderNumberMatch[1] : null

      if (response.status === 'SUCCESS' && orderNumber) {
        const paymentPayload = {
          clientCode: clientCode.toUpperCase(),
          orderNumber: orderNumber,
          totalAmount: oneTimeAmount,
          upiId: userData?.upiId,
        }

        navigate('/dashboard/mutual-funds/order-payment', {
          state: {
            paymentPayload,
            orderNumber,
          },
        })
      } else {
        setMessageModalState({
          message: response.data.responsestring,
          linkMessage: response.data.responsestring,
          severity: 'error',
        })
      }
    } catch (error) {
      console.log('🚀 ~ placeOneTimeOrder ~ error:', error)
    }
  }

  const placeSIPOrder = async () => {
    try {
      setIsLoading(true)

      const payload = {
        clientCode: clientCode.toUpperCase(),
        amount: sipAmount.toString(),
        sipStartDate: sipDate,
        mandateType: 'UNIVERSAL',
      }

      const response = await post(
        'mutualfundservice/api/bseStar/mfUpload/mandateRegistration',
        payload,
      )

      if (response.isError) {
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
        return
      }

      if (
        response.mandateStatus === 'NEW' &&
        response.status === 'SUCCESS' &&
        response.alreadyExists === false
      ) {
        setIsLoading(true)
        setShowMfSipMandatePopup(true)
        setMandateId(response.mandateId)
        setCallMandateAuthURL(true)
      }

      if (
        response.mandateStatus != 'NEW' &&
        response.status === 'SUCCESS' &&
        response.alreadyExists === true
      ) {
        const mandateDetails = await getMandateDeatils(response.mandateId)
        await handleXSIP(response.mandateId, mandateDetails.MandateDetails[0].mandateStatus)
        return
      }

      // if (
      //   mandateDetails.status == 200 &&
      //   mandateDetails.MandateDetails[0].status == 'WAITING FOR CLIENT AUTHENTICATION'
      // ) {
      //   setMandateId(response.mandateId)
      //   setCallMandateAuthURL(true)
      // }
    } catch (error) {
      setMessageModalState({
        message: 'Something went wrong. Please try again later.',
        severity: 'error',
      })
    }
  }

  // Helper function to handle existing mandate registration
  const handleXSIP = async (mandateId, status) => {
    console.log('🚀 ~ handleXSIP ~ mandateId, status:', mandateId, status)
    const payload = {
      schemeCode: fundDetails.bseSchemeCode,
      clientCode: clientCode.toUpperCase(),
      installmentAmount: sipAmount,
      startDate: sipDate,
      mandateId: mandateId,
      mandateStatus: status,
    }

    try {
      const xSIPRegistration = await post(
        'mutualfundservice/api/bseStar/api/XSIPRegistration',
        payload,
      )

      setShowMfSipMandatePopup(false)

      if (xSIPRegistration.status === 'SUCCESS') {
        onOpen('openPopup', {
          title: 'SIP Registration Successful! ',
          message:
            ' SIP Registration Successful! 🎉 Your SIP registration has been completed successfully.',
          severity: 'success',
        })
      }
    } catch (error) {
      console.log('Error registering XSIP:', error)
    }
  }

  // Helper function to get eMandate Auth URL
  const getEMandateAuthURL = async (mandateId) => {
    const eMandatePayload = {
      clientCode: clientCode.toUpperCase(),
      mandateId,
      loopBackUrl: 'http://localhost:3000/dashboard/mutual-funds/place-order',
    }

    return await post('mutualfundservice/api/bseStar/mfWebService/eMandateAuthURL', eMandatePayload)
  }

  const getMandateDeatils = async (mandateId) => {
    const payload = {
      fromDate: '01/10/2024',
      toDate: '07/12/2024',
      clientCode: clientCode.toUpperCase(),
      mandateId: mandateId,
    }

    return await post('mutualfundservice/api/bseStar/mfWebService/mandateDetails', payload)
  }

  // Helper function to handle successful eMandate response
  const handleSuccessfulEMandate = (eMandateResponse) => {
    setRedirectURL(eMandateResponse.ResponseString)
    setTimeout(() => {
      window.open(eMandateResponse.ResponseString)
    }, 2000)
  }

  // Helper function to handle failed eMandate response
  const handleFailedEMandate = () => {
    setShowMfSipMandatePopup(false)
    setMessageModalState({
      message:
        'The request could not be processed due to an unexpected issue, Please try again later.',
      severity: 'error',
    })
  }

  return (
    <>
      {showMfSipMandatePopup ? (
        <MFSipMandate
          isLoading={isLoading}
          setShowMfSipMandatePopup={setShowMfSipMandatePopup}
          redirectURL={redirectURL}
        />
      ) : (
        <div className='bg-white'>
          <div className='max-w-[1128px] mx-auto min-h-screen pb-12'>
            <div
              className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0'
              onClick={() => navigate(-1)}
            >
              <ChevronLeft className='h-5 w-5' />
              Place Order
            </div>

            <div className='max-w-[360px] mx-auto h-full flex flex-col gap-y-12 justify-between'>
              <div className='h-[170px] w-full bg-cover flex justify-center items-center p-2 z-0 relative'>
                <div
                  className='absolute inset-0 bg-cover w-full h-full'
                  style={{ backgroundImage: `url(${PlaceOrderBg})` }}
                />
                <div
                  className='absolute inset-0'
                  style={{
                    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                    // boxShadow: '0px 16px 24px rgba(65, 85, 141, 0.08)',
                  }}
                />
                <div className='flex items-center space-x-4 z-10'>
                  <img
                    src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                    alt='DSP logo'
                    className='w-12 h-12 border rounded-lg'
                  />
                  <div>
                    <h3
                      className='font-semibold text-sm leading-tight text-wrap w-48'
                      style={{ textWrap: 'pretty' }}
                    >
                      {fundDetails.legalName
                        ? fundDetails.legalName.replace(/Fund.*$/, 'Fund')
                        : fundDetails.sName.replace(/Fund.*$/, 'Fund')}
                    </h3>
                    <p className='text-sm text-muted-foreground'>
                      3Y returns{' '}
                      <span className='font-bold text-green-600'>
                        {fundDetails?.threeYrRet.toFixed(2)}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <Tabs defaultValue='One-Time' value={tabValue} onValueChange={setTabValue}>
                <TabsList className='mx-auto w-full'>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'One-Time' && 'text-primary-700 font-semibold'
                    }`}
                    value='One-Time'
                  >
                    One-Time
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'One-Time' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'SIP' && 'text-primary-700 font-semibold'
                    }`}
                    value='SIP'
                  >
                    SIP
                    <span
                      className={`h-2 w-2 rounded-full  ${
                        tabValue === 'SIP' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                </TabsList>
                <TabsContent value='One-Time' className='h-full'>
                  <div className='flex flex-col justify-center items-center gap-1.5 mt-12'>
                    <Label htmlFor='amount' className='text-black-400 text-xs'>
                      Investment amount
                    </Label>
                    <Input
                      startAdornment='₹'
                      type='amount'
                      id='amount'
                      placeholder='Amount'
                      className='border-none outline-none text-center max-w-[150px] font-DMSans font-semibold text-3xl'
                      value={oneTimeAmount}
                      onChange={(e) => setOneTimeAmount(e.target.value)}
                    />
                    {oneTimeAmount < Number(fundDetails?.minInvt) && (
                      <p className='text-xs text-error-600 text-center'>
                        Minimum first investment amount is ₹ {toCurrency(fundDetails?.minInvt)}
                      </p>
                    )}
                  </div>

                  <div className='flex justify-center gap-x-2 mt-6'>
                    {[1000, 2000, 3000].map((btn) => (
                      <Button
                        size='simple'
                        className='px-3 py-[2px] rounded-[50px] bg-primary-50 hover:bg-primary-50 text-primary-500'
                        onClick={() => setOneTimeAmount((prev) => Number(prev) + btn)}
                      >
                        + {toCurrency(btn)}
                      </Button>
                    ))}
                  </div>

                  <div className='flex gap-x-2 mt-12'>
                    <Button
                      className='rounded-xl border flex-1'
                      variant='simple'
                      onClick={() => setTabValue('SIP')}
                    >
                      Add SIP
                    </Button>
                    <Button
                      className='rounded-xl flex-1'
                      loading={isLoading && tabValue === 'One-Time'}
                      onClick={() => placeOneTimeOrder()}
                    >
                      Invest One Time
                    </Button>
                  </div>
                </TabsContent>
                <TabsContent value='SIP'>
                  {' '}
                  <div className='flex flex-col justify-center items-center gap-1.5 mt-12'>
                    <Label htmlFor='amount' className='text-black-400 text-xs'>
                      Investment amount
                    </Label>
                    <Input
                      startAdornment='₹'
                      type='amount'
                      id='amount'
                      placeholder='Amount'
                      className='border-none outline-none text-center max-w-[150px] font-DMSans font-semibold text-3xl mx-auto'
                      value={sipAmount}
                      onChange={(e) => setSipAmount(e.target.value)}
                    />
                    {sipAmount < Number(fundDetails?.minSipAmount) && (
                      <p className='text-xs text-error-600 text-center'>
                        Minimum SIP amount is ₹ {sipAmount === 0 ? 100 : fundDetails?.minSipAmount}
                      </p>
                    )}
                  </div>
                  <div className='flex justify-center gap-x-2 mt-6'>
                    {[1000, 2000, 3000].map((btn) => (
                      <Button
                        size='simple'
                        className='px-3 py-[2px] rounded-[50px] bg-primary-50 hover:bg-primary-50 text-primary-500'
                        onClick={() => setSipAmount((prev) => prev + btn)}
                      >
                        + {toCurrency(btn)}
                      </Button>
                    ))}
                  </div>
                  <div className='mt-12'>
                    <Label className='text-sm text-black-300'>Select SIP Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={'outline'}
                          className='pl-3 font-normal border-none bg-primary-50 rounded-lg w-full flex justify-between max-w-[360px] mx-auto'
                        >
                          <span className='text-primary-500 flex items-center gap-x-2'>
                            <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            {addNumberSuffix(+selectedDay)} of every month
                          </span>

                          <Pen className='h-4 w-4 text-primary-500' />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className='p-0 w-auto rounded-full' align='center' side='top'>
                        <div className='flex gap-x-2 p-2'>
                          {sipDates.map((day) => (
                            <Button
                              className={`h-8 w-8 rounded-full inline-block ${
                                selectedDay !== +day
                                  ? 'bg-white border border-primary-50 text-black-400 hover:bg-primary-50'
                                  : 'text-white'
                              }`}
                              size='simple'
                              // disabled={
                              //   +day === today ||
                              //   +day === nextDay1.getDate() ||
                              //   +day === nextDay2.getDate()
                              // }
                              onClick={() => setSelectedDay(+day)}
                            >
                              {day}
                            </Button>
                          ))}
                        </div>
                      </PopoverContent>
                    </Popover>

                    {/* <div className='flex justify-between items-center w-full mt-6'>
                  <Label className='text-nowrap text-sm'>Add annual step-up</Label>
                  <Input className='max-w-[72px]' endAdornment='%' />
                </div> */}

                    <Card className='w-full max-w-md mx-auto mt-6'>
                      {/* <CardHeader>
                        <CardTitle className='text-base font-semibold'>SIP Information</CardTitle>
                      </CardHeader> */}
                      <CardContent className='space-y-4 p-3'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col'>
                            <p className='text-sm font-medium text-black-300'>First Instalment</p>
                            <p className='text-sm font-semibold text-black-400'>Initiated Now</p>
                          </div>
                          <div className='flex flex-col'>
                            <p className='text-sm font-medium text-black-300'>Next Instalment</p>
                            <p className='text-sm font-semibold text-black-400'>
                              {addNumberSuffix(+nextSipDay)} {monthNames[nextSipMonth - 1]}{' '}
                              {nextSipYear}
                            </p>
                          </div>
                        </div>
                        <ul className='list-disc pl-5 space-y-2 text-black-300 text-xs'>
                          <li>Your 1st SIP instalment will be initiated now.</li>
                          <li>
                            All future SIP instalments will be paid on{' '}
                            {addNumberSuffix(selectedDay)} of every month. Dates may vary in case of
                            holidays.
                          </li>
                          {/* <li>
                            Future installments must start at least 30 days after the SIP creation
                            date, as per BSE guidelines.
                          </li> */}
                        </ul>
                      </CardContent>
                    </Card>
                  </div>
                  {/* <div className='my-6 flex justify-center'>
                <Button
                  variant='link'
                  size='simple'
                  className='text-primary-500 p-0 border-b border-dashed border-primary-500 rounded-none hover:no-underline'
                  onClick={() => onOpen('openMFBreakdown', { displayNavbar })}
                >
                  View detailed breakdown
                  <ChevronDown className='h-4 w-4 ml-1' />
                </Button>
              </div> */}
                  <div className='flex gap-x-2 mt-6'>
                    <Button
                      disabled={oneTimeAmount === 0 || !oneTimeAmount}
                      className='rounded-xl border flex-1'
                      variant='simple'
                      onClick={() => setTabValue('One-Time')}
                    >
                      Add One Time amount
                    </Button>
                    <Button
                      disabled={sipAmount < 100 || !sipAmount}
                      className='rounded-xl flex-1'
                      loading={isLoading && tabValue === 'SIP'}
                      onClick={() => placeSIPOrder()}
                    >
                      Start SIP
                    </Button>
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MFPlaceOrder
