import { AlertTriangle, ArrowLeft, RefreshCcw } from 'lucide-react'
import { Button } from '../Components/ui/button'
import { Alert, AlertDescription, AlertTitle } from '../Components/ui/alert'
import { useNavigate } from 'react-router-dom'

export function ErrorBoundaryPage({ error }) {
  const navigate = useNavigate()

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleGoBack = () => {
    navigate('/dashboard')
    window.location.reload()
  }

  return (
    <div className='min-h-screen bg-background flex items-center justify-center p-4'>
      <div className='max-w-md w-full space-y-8'>
        <div className='text-center'>
          <AlertTriangle className='mx-auto h-12 w-12 text-destructive' />
          <h2 className='mt-6 text-3xl font-extrabold text-foreground'>
            Oops! Something went wrong
          </h2>
          <p className='mt-2 text-sm text-muted-foreground'>
            We apologize for the inconvenience. An unexpected error has occurred.
          </p>
        </div>
        <Alert variant='destructive'>
          <AlertTriangle className='h-4 w-4' />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error?.message || 'An unknown error occurred'}</AlertDescription>
        </Alert>
        <div className='mt-8 space-y-4'>
          <Button onClick={handleGoBack} variant='outline' className='w-full'>
            <ArrowLeft className='mr-2 h-4 w-4' /> Go Back
          </Button>
          <Button onClick={handleRefresh} className='w-full'>
            <RefreshCcw className='mr-2 h-4 w-4' /> Refresh Page
          </Button>
        </div>
        <p className='mt-4 text-center text-sm text-muted-foreground'>
          If the problem persists, please contact our support team.
        </p>
      </div>
    </div>
  )
}
