import { useModal } from '../../hooks/use-modal-store'
import { post } from '../../http/restservice'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'

const BankPaymentResponse = () => {
  const payData = JSON.parse(localStorage.getItem('paymentStatusData')) || null
  console.log('🚀 ~ BankPaymentResponse ~ payData:', payData)
  const location = useLocation()
  const responseString = location?.state?.response ? location?.state?.response : payData.response
  const { onOpen } = useModal()
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [timeLeft, setTimeLeft] = useState(300) // 5 minutes = 300 seconds

  const paymentStatus = async () => {
    try {
      const response = await post('/mutualfundservice/api/bseStar/mfUpload/paymentStatus', {
        clientCode: payData?.clientCode,
        orderNo: payData?.orderNo,
      })

      if (response.isError) {
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
      }

      if (!response.isError && response.status === 'SUCCESS') {
        onOpen('openPopup', {
          title: 'UPI Payment Successful',
          message:
            'Payment Successful! 🎉 Your UPI transaction has been completed securely. Thank you for your payment!',
          severity: 'success',
        })
      }
    } catch (error) {
      console.log('🚀 ~ paymentStatus ~ error:', error)
    }
  }

  // useEffect(() => {
  //   paymentStatus()
  // }, [])

  useEffect(() => {
    if (!location?.state) {
      if (
        timeLeft === 290 ||
        timeLeft === 280 ||
        timeLeft === 270 ||
        timeLeft === 260 ||
        timeLeft === 260 ||
        timeLeft === 0
      ) {
        paymentStatus()
      }

      if (timeLeft > 0) {
        const timerId = setTimeout(() => {
          setTimeLeft(timeLeft - 1)
        }, 1000)

        // Clear the timeout when component unmounts or when timeLeft changes
        return () => clearTimeout(timerId)
      }
    }
  }, [timeLeft, JSON.stringify(payData)])

  useEffect(() => {
    if (location?.state) {
      // Create a container for the form and inject the HTML
      const container = document.getElementById('bankPage')

      setTimeout(() => {
        // Automatically submit the form
        const form = container.querySelector('form[name="Bankfrm"]')
        if (form) {
          // form.target = '_blank'
          form.submit()
        }
      }, 3000)

      // Clean up the container after submission
      return () => {
        document.body.removeChild(container)
      }
    }
  }, [responseString])

  return (
    <div
      id='bankPage'
      className='fixed top-0 left-0 right-0 bottom-0 bg-white'
      dangerouslySetInnerHTML={{ __html: responseString }}
    />
  )
}

export default BankPaymentResponse
