/* eslint-disable */

import { useEffect, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import { AutoComplete } from '../../Components/ui/mf-search-input'

import DebtFunds from '../../Components/MutualFunds/DebtFunds'
import EquityFunds from '../../Components/MutualFunds/EquityFunds'
import HybridFunds from '../../Components/MutualFunds/HybridFunds'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { get, getMFData } from '../../http/restservice'
import useDebounce from '../../hooks/useDebounce'
import { messageModalState } from '../../store/Feedback'
import { useSetRecoilState } from 'recoil'

const MutualFunds = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabValue, setTabValue] = useState(searchParams.get('assetClass') || 'Equity')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setDisbled] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const location = useLocation()
  const [invested, setInvested] = useState(false)

  const paramsObject = Object.fromEntries(searchParams.entries())

  const [mutualFundsData, setMutualFundsData] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [options, setOptions] = useState([])
  const setMessageModalState = useSetRecoilState(messageModalState)
  const naigate = useNavigate()

  useEffect(() => {
    const fetchMFData = async () => {
      setIsLoading(true)

      if (paramsObject?.search) {
        delete paramsObject.assetClass
        // delete paramsObject.limit
        // delete paramsObject.page
      }

      const response = await get(
        'https://apimywealth.fabits.com/mutualfundservice/api/mfData/allFunds',
        {},
        paramsObject,
      )
      setIsLoading(false)
      if (response.isError) {
        setMutualFundsData([])
        setTotalPages(0)
        setOptions([])
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
      }

      if (!response.isError) {
        const ops =
          response &&
          response?.funds &&
          response.funds.map((item, i) => {
            return { id: i, label: item.sName, value: item.sName, item }
          })

        setMutualFundsData(response.funds)
        setTotalPages(response.totalPages)
        setOptions(ops)
      }
    }

    fetchMFData()
  }, [JSON.stringify(paramsObject)])

  const clearAllFilter = () => {
    setSearchParams({
      assetClass: searchParams.get('assetClass'),
      page: 0,
      size: 10,
      sortBy: 'threeYearRet',
      sortDir: 'desc',
    })
  }

  const hideContentPaths = [
    '/dashboard/mutual-funds/investments',
    '/dashboard/mutual-funds/orders',
    '/dashboard/mutual-funds/sips',
  ] // Update based on your child routes
  const shouldHideParentContent = hideContentPaths.includes(location.pathname)

  return (
    <>
      {isDesktop ? (
        <>
          <div
            className='h-[404px] pt-12'
            style={{
              background:
                'radial-gradient(83.35% 187.05% at 21.03% 89.97%, #1B243B 0%, #242F4E 40.06%, #3B4D80 100%)',
              isolation: 'isolate',
            }}
          >
            <div className='max-w-[1128px] h-10 mx-auto'>
              <div className='flex justify-between items-center'>
                <div>
                  <span
                    className={`text-primary-100 px-5 py-2 ${
                      location.pathname === '/dashboard/mutual-funds' &&
                      'bg-white rounded-[40px] text-primary-700 px-5 py-2 text-sm'
                    }`}
                    value='dashboard'
                  >
                    <Link className='no-underline' to='/dashboard/mutual-funds'>
                      Explore
                    </Link>
                  </span>
                  <span
                    className={`text-primary-100 px-5 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/investments' &&
                      'bg-white rounded-[40px] text-primary-700 px-5 py-2 text-sm'
                    }`}
                  >
                    <Link className='no-underline' to='/dashboard/mutual-funds/investments'>
                      {' '}
                      My Investments
                    </Link>
                  </span>
                  <span
                    className={`text-primary-100 px-5 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/sips' &&
                      'bg-white rounded-[40px] text-primary-700 px-5 py-2 text-sm'
                    }`}
                  >
                    <Link className='no-underline' to='/dashboard/mutual-funds/sips'>
                      SIPs
                    </Link>
                  </span>
                  {/* <span
                    className={`text-primary-100 px-5 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/orders' &&
                      'bg-white rounded-[40px] text-primary-700 px-5 py-2 text-sm'
                    }`}
                  >
                    <Link className='no-underline' to='/dashboard/mutual-funds/orders'>
                      {' '}
                      Orders
                    </Link>
                  </span> */}
                </div>

                {invested && (
                  <div className='w-[400px]'>
                    <AutoComplete
                      options={options}
                      emptyMessage='No results.'
                      placeholder='Search for funds'
                      isLoading={isLoading}
                      onValueChange={setSearchValue}
                      value={searchValue}
                      disabled={isDisabled}
                    />
                  </div>
                )}
              </div>

              {!invested ? (
                <>
                  <h1 className='font-semibold text-[32px] text-white text-center my-12'>
                    Mutual Funds
                  </h1>
                  <div className='max-w-[552px] mx-auto'>
                    <AutoComplete
                      options={options}
                      emptyMessage='No results.'
                      placeholder='Search for funds'
                      isLoading={isLoading}
                      onValueChange={setSearchValue}
                      value={searchValue}
                      disabled={isDisabled}
                    />
                  </div>
                </>
              ) : (
                <div className='flex gap-x-12 justify-center items-end my-12'>
                  <div>
                    <p className='text-center text-white font-semibold'>₹ 1,000</p>
                    <p className='text-primary-100 text-sm text-center'>Invested Amount</p>
                  </div>
                  <div>
                    <h3 className='text-center text-white text-[32px] font-AlfaSlabOne tracking-[4%] leading-[42px]'>
                      ₹ 997
                    </h3>
                    <p className='text-primary-100 text-sm text-center'>Current MF Portfolio</p>
                  </div>
                  <div>
                    <hp className='text-center text-white font-semibold'>-0.3%</hp>
                    <p className='text-primary-100 text-sm text-center'>Returns</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Outlet />

          {!shouldHideParentContent && (
            <div className='bg-white'>
              <div className='max-w-[1128px] py-12 mx-auto'>
                <div>
                  <Tabs defaultValue='Equity' value={tabValue} onValueChange={setTabValue}>
                    <TabsList className='mx-auto w-full'>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Debt' && 'text-primary-700 font-semibold'
                        }`}
                        value='Debt'
                        onClick={clearAllFilter}
                      >
                        Debt {tabValue === 'Debt' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full ${
                            tabValue === 'Debt' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Equity' && 'text-primary-700 font-semibold'
                        }`}
                        value='Equity'
                        onClick={clearAllFilter}
                      >
                        Equity {tabValue === 'Equity' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Equity' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Hybrid' && 'text-primary-700 font-semibold'
                        }`}
                        value='Hybrid'
                        onClick={clearAllFilter}
                      >
                        Hybrid {tabValue === 'Hybrid' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Hybrid' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value='Debt'>
                      <DebtFunds
                        searchValue={debouncedSearchTerm}
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                    <TabsContent value='Equity'>
                      <EquityFunds
                        searchValue={debouncedSearchTerm}
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                    <TabsContent value='Hybrid'>
                      <HybridFunds
                        searchValue={debouncedSearchTerm}
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className='h-[286px] p-4'
            style={{
              background:
                'radial-gradient(83.35% 187.05% at 21.03% 89.97%, #1B243B 0%, #242F4E 40.06%, #3B4D80 100%)',
              isolation: 'isolate',
            }}
          >
            <div className='h-10 mx-auto'>
              <div>
                <div className='flex justify-between items-center overflow-x-scroll hide-scrollbar'>
                  <span
                    className={`text-primary-100 px-4 py-2 ${
                      location.pathname === '/dashboard/mutual-funds' &&
                      'bg-white rounded-[40px] text-primary-700 px-4 py-2 text-sm'
                    }`}
                    value='dashboard'
                  >
                    <Link className='no-underline' to='/dashboard/mutual-funds'>
                      Explore
                    </Link>
                  </span>
                  <span
                    className={`text-primary-100 px-4 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/investments' &&
                      'bg-white rounded-[40px] text-primary-700 px-4 py-2 text-sm'
                    }`}
                  >
                    <Link
                      className='no-underline text-nowrap'
                      to='/dashboard/mutual-funds/investments'
                    >
                      My Investments
                    </Link>
                  </span>
                  <span
                    className={`text-primary-100 px-4 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/sips' &&
                      'bg-white rounded-[40px] text-primary-700 px-4 py-2 text-sm'
                    }`}
                  >
                    <Link className='no-underline text-nowrap' to='/dashboard/mutual-funds/sips'>
                      SIPs
                    </Link>
                  </span>
                  {/* <span
                    className={`text-primary-100 px-4 py-2 ${
                      location.pathname === '/dashboard/mutual-funds/orders' &&
                      'bg-white rounded-[40px] text-primary-700 px-4 py-2 text-sm'
                    }`}
                  >
                    <Link className='no-underline text-nowrap' to='/dashboard/mutual-funds/orders'>
                      Orders
                    </Link>
                  </span> */}
                </div>
              </div>

              {!invested ? (
                <>
                  <h1 className='font-semibold text-[32px] text-white text-center my-12'>
                    Mutual Funds
                  </h1>
                  <AutoComplete
                    options={options}
                    emptyMessage='No results.'
                    placeholder='Search for funds'
                    isLoading={isLoading}
                    onValueChange={setSearchValue}
                    value={searchValue}
                    disabled={isDisabled}
                  />
                </>
              ) : (
                <div className='flex flex-col gap-y-6 justify-center my-12'>
                  <div>
                    <h3 className='text-center text-white text-[32px] font-AlfaSlabOne tracking-[4%] leading-[42px]'>
                      ₹ 997
                    </h3>
                    <p className='text-primary-100 text-sm text-center'>Current MF Portfolio</p>
                  </div>
                  <div className='flex justify-between'>
                    <div>
                      <p className='text-center text-white font-semibold'>₹ 1,000</p>
                      <p className='text-primary-100 text-sm text-center'>Invested Amount</p>
                    </div>

                    <div>
                      <hp className='text-center text-white font-semibold'>-0.3%</hp>
                      <p className='text-primary-100 text-sm text-center'>Returns</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Outlet />

          {!shouldHideParentContent && (
            <div className='bg-white'>
              <div className='max-w-[1128px] py-12 mx-auto'>
                <div>
                  <Tabs defaultValue='Debt' value={tabValue} onValueChange={setTabValue}>
                    <TabsList className='mx-auto w-full'>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Debt' && 'text-primary-700 font-semibold'
                        }`}
                        value='Debt'
                      >
                        Debt {tabValue === 'Debt' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full ${
                            tabValue === 'Debt' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Equity' && 'text-primary-700 font-semibold'
                        }`}
                        value='Equity'
                      >
                        Equity {tabValue === 'Equity' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Equity' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                      <TabsTrigger
                        className={`text-primary-200 text-2xl font-light flex flex-col ${
                          tabValue === 'Hybrid' && 'text-primary-700 font-semibold'
                        }`}
                        value='Hybrid'
                      >
                        Hybrid {tabValue === 'Hybrid' && 'Funds'}
                        <span
                          className={`h-2 w-2 rounded-full  ${
                            tabValue === 'Hybrid' ? 'bg-primary-700' : 'bg-transparent'
                          }`}
                        ></span>
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value='Debt'>
                      <DebtFunds
                        searchValue={searchValue}
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                    <TabsContent value='Equity'>
                      <EquityFunds
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                    <TabsContent value='Hybrid'>
                      <HybridFunds
                        mutualFundsData={mutualFundsData}
                        totalPages={totalPages}
                        isLoading={isLoading}
                      />
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MutualFunds
