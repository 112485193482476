/* eslint-disable */
import { ArrowUp } from 'lucide-react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../Components/ui/tabs'
import React, { useEffect, useState } from 'react'
import { Separator } from '../../Components/ui/separator'
import MFIndividualInvestmentsTable from '../../Components/MutualFunds/MFIndividualInvestmentsTable'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { MutualFundsHolding } from '../../store/MFStore'
import Loader from '../../Components/Loader/Loader'

const MFInvestments = () => {
  const [tabValue, setTabValue] = useState('Individual Investments')

  const mfHolding = useRecoilValueLoadable(MutualFundsHolding)
  console.log('🚀 ~ MFInvestments ~ mfHolding:', mfHolding)
  const refreshMfHolding = useRecoilRefresher_UNSTABLE(MutualFundsHolding)

  useEffect(() => refreshMfHolding(), [])

  switch (mfHolding.state) {
    case 'loading':
      return <Loader />

    case 'hasValue':
      return (
        <div className='bg-white min-h-screen py-6 sm:py-12'>
          <Tabs
            defaultValue='Individual Investments'
            value={tabValue}
            onValueChange={setTabValue}
            className='max-w-[1128px] mx-auto'
          >
            <TabsList className='mx-auto w-full'>
              <TabsTrigger
                className={`text-primary-200 text-xl font-light flex flex-col ${
                  tabValue === 'Individual Investments' && 'text-primary-700 font-semibold'
                }`}
                value='Individual Investments'
              >
                Individual {tabValue === 'Individual Investments' && 'Investments'}
                <span
                  className={`h-2 w-2 rounded-full ${
                    tabValue === 'Individual Investments' ? 'bg-primary-700' : 'bg-transparent'
                  }`}
                ></span>
              </TabsTrigger>
              {/* <TabsTrigger
            className={`text-primary-200 text-xl font-light flex flex-col ${
              tabValue === 'Basket' && 'text-primary-700 font-semibold'
            }`}
            value='Basket'
          >
            Basket {tabValue === 'Basket' && 'Investments'}
            <span
              className={`h-2 w-2 rounded-full  ${
                tabValue === 'Basket' ? 'bg-primary-700' : 'bg-transparent'
              }`}
            ></span>
          </TabsTrigger> */}
            </TabsList>
            <TabsContent value='Individual Investments' className='h-full py-12 px-4'>
              {/* Investment Details  */}
              {/* <div className='w-full max-w-[424px] mx-auto px-6 py-4 rounded-xl border '>
            <div className='flex justify-between items-center space-x-6'>
              <div className='space-y-1 flex-1'>
                <p className='font-semibold'>₹ 1,48,101</p>
                <p className='text-xs text-black-400 flex gap-x-1 items-center'>
                  Current{' '}
                  <span
                    className={`bg-secondary-600 font-semibold text-secondary-600 rounded-[50%] h-4 w-4 flex justify-center items-center mr-1 sm:hidden`}
                  >
                    <ArrowUp className='h-3 w-2 text-white' />
                  </span>
                  <span className='text-secondary-600 font-semibold sm:hidden'> +10.55 %</span>
                </p>
              </div>
              <Separator orientation='vertical' className='h-11' />
              <div className='space-y-1 flex-1'>
                <p className='font-semibold'>₹ 1,32,201</p>
                <p className='text-xs text-black-400'>Invested</p>
              </div>
              <Separator orientation='vertical' className='h-11 hidden sm:block' />
              <div className='space-y-1 hidden sm:block flex-1'>
                <p className='font-semibold text-secondary-600 flex items-center'>
                  <span
                    className={`bg-secondary-600 rounded-[50%] h-5 w-5 flex justify-center items-center text-white mr-1`}
                  >
                    <ArrowUp className='h-4 w-3' />
                  </span>
                  +10.55 %
                </p>
                <p className='text-xs text-black-400'>Returns</p>
              </div>
            </div>
          </div> */}

              {mfHolding.state === 'hasValue' && (
                <MFIndividualInvestmentsTable holdings={mfHolding?.contents} />
              )}
            </TabsContent>
            {/* <TabsContent value='Basket'>b</TabsContent> */}
          </Tabs>
        </div>
      )

    case 'hasError':
      return <div>error</div>

    default:
      return null
  }
}

export default MFInvestments
