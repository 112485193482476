/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Typography, Grid, FormControl, Link, useTheme } from '@mui/material'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { UserDetails } from '../store/AccountDetailsStore'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import { post } from '../http/restservice'
import { useLocation, useNavigate } from 'react-router-dom'
import { toCurrency } from '../utils/CurrencyUtil'
import { formatDateToStr } from '../utils/Util'
import env from '../env/env'
import clevertap from 'clevertap-web-sdk'

const EMandateForm = ({ emandateDate }) => {
  useEffect(() => {
    document.getElementById('e-mandate-form').submit()
  }, [])
  return (
    <form id='e-mandate-form' method='post' action={env.E_MANDATE_URL}>
      <input type='hidden' value='' name='Filler1' />
      <input type='hidden' value='' name='Filler2' />
      <input type='hidden' value='' name='Filler3' />
      <input type='hidden' value='' name='Filler4' />
      <input type='hidden' value={emandateDate.Filler5} name='Filler5' />
      <input type='hidden' value='' name='Filler6' />
      <input type='hidden' value='' name='Filler7' />
      <input type='hidden' value='' name='Filler8' />
      <input type='hidden' value='' name='Filler9' />
      <input type='hidden' value='' name='Filler10' />
      <input type='hidden' value='' name='Merchant_PartyName' />
      <input type='hidden' value='' name='Merchant_Category_Desc' />
      <input type='hidden' value='' name='Merchant_CreditorName' />
      <input type='hidden' value='' name='Merchant_CreditorAccountNo' />
      <input type='hidden' value={emandateDate.Short_Code} name='Short_Code' />
      <input type='hidden' value={emandateDate.Customer_EmailId} name='Customer_EmailId' />
      <input type='hidden' value={emandateDate.Customer_Name} name='Customer_Name' />
      <input type='hidden' value={emandateDate.Customer_MaxAmount} name='Customer_MaxAmount' />
      <input type='hidden' value={emandateDate.Channel} name='Channel' />
      <input type='hidden' value={emandateDate.Customer_StartDate} name='Customer_StartDate' />
      <input type='hidden' value={emandateDate.Customer_ExpiryDate} name='Customer_ExpiryDate' />
      <input type='hidden' value='' name='Customer_TelphoneNo' />
      <input type='hidden' value='' name='Customer_DebitAmount' />
      <input type='hidden' value='' name='Customer_Reference1' />
      <input type='hidden' value='' name='Customer_Reference2' />
      <input type='hidden' value='y' name='ispostback' />
      <input
        type='hidden'
        value={emandateDate.Customer_InstructedMemberId}
        name='Customer_InstructedMemberId'
      />
      <input type='hidden' value={emandateDate.MsgId} name='MsgId' />
      <input
        type='hidden'
        value={emandateDate.Customer_DebitFrequency}
        name='Customer_DebitFrequency'
      />
      <input
        type='hidden'
        value={emandateDate.Customer_SequencyType}
        name='Customer_SequenceType'
      />
      <input type='hidden' value={emandateDate.CheckSum} name='CheckSum' />
      <input type='hidden' value={emandateDate.UtilCode} name='UtilCode' />
      <input type='hidden' value='' name='Customer_Mobile' />
      <input
        type='hidden'
        value={emandateDate.Merchant_Category_Code}
        name='Merchant_Category_Code'
      />
      <input type='hidden' value={emandateDate.Customer_AccountNo} name='Customer_AccountNo' />
      <input type='hidden' value='Sponsor' name='ButtonS' />
    </form>
  )
}

const EMandate = () => {
  const { state, contents: userBankDetails } = useRecoilValueLoadable(UserDetails)
  const refreshData = useRecoilRefresher_UNSTABLE(UserDetails)
  const [loading, setLoading] = useState(false)
  const [messageResponse, setMessageResponse] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const sipRecord = location.state?.sip

  useEffect(() => {
    if (sipRecord) {
      refreshData()
    } else {
      navigate('/dashboard/sips')
    }
  }, [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'EMandate Page',
    })
  }, [])

  const createEMandate = async () => {
    const response = await post(`/planservice/api/portfolio/emandate`, sipRecord, null, null)
    if (response.isError) {
      setLoading(false)
      // console.log(response)
    } else {
      // console.log(response)
      setMessageResponse(response.data)
    }
  }

  const getTriggerDate = () => {
    const d = new Date(sipRecord.next_trigger_date)
    d.setDate(d.getDate() - 1)
    return formatDateToStr(d)
  }

  return (
    <>
      <Typography component='h5' fontWeight={500} sx={{ margin: '0 8px' }}>
        Create E-Mandate
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            fontWeight: '500',
            background: theme.palette.secondary.main,
            padding: '10px 25px',
            paddingTop: '20px',
            paddingBottom: '20px',
            my: 2,
            borderRadius: '10px',
          }}
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Grid>
              {/* <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              {' '}
              {message && <Alert severity='error'>{message}</Alert>}
              {successMessage && <Alert severity='success'>{successMessage}</Alert>}
            </Grid> */}
              {state === 'hasValue' && userBankDetails && (
                <Grid container>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Account Name
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      {userBankDetails.accountHolderName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Account Number
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      {userBankDetails.accountNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Bank
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      {userBankDetails.bankName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Account Type
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      Savings
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Amount
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      <Typography component='span' className='custom-font'>
                        &#8377;
                      </Typography>{' '}
                      {toCurrency(sipRecord.sip_amount * 2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <Typography
                      component='label'
                      sx={{ color: theme.palette.primary.textColor, fontSize: '12px' }}
                    >
                      Debit Date
                    </Typography>
                    <Typography component='div' fontWeight={500}>
                      {getTriggerDate()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                    <Typography component='h3' sx={{ fontSize: '18px', fontWeight: 'bold', mb: 1 }}>
                      What is e-Mandate?
                    </Typography>
                    <Typography component='p' sx={{ fontSize: '12px', mb: 1 }}>
                      An eMandate allows you to schedule automatic fund transfers from your bank
                      account to your trading account for your investments in Smart Investment
                      Boxes. You have to register an eMandate with your bank before you can debit
                      funds. This is a one-time process for each SIP. It may take up to 5 days for
                      your bank to activate / delete the mandate.
                    </Typography>
                    <Typography component='p' sx={{ fontSize: '12px', mb: 1, fontWeight: 'bold' }}>
                      Why is the eMandate amount is more than 2x the SIP amount?
                    </Typography>
                    <Typography component='p' sx={{ fontSize: '12px', mb: 1 }}>
                      This is done to account for the following. Annual SIP increase : The increase
                      will happen automatically every year without the need for you to update the
                      eMandate amount. Fees and Taxes: The approx. amount indicated previously will
                      be added to your SIP amount.
                    </Typography>
                    {/* <Typography component='p' sx={{ fontSize: '12px' }}>
                      Learn More at{' '}
                      <Link
                        onClick={() => window.open('https://www.fabits.com/SIPs', '_blank')}
                        sx={{ cursor: 'pointer', fontSize: '14px' }}
                      >
                        www.fabits.com/SIPs
                      </Link>{' '}
                    </Typography> */}
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                    <FormControl sx={{ display: 'block' }}>
                      <LoadingButton
                        loading={loading}
                        variant='contained'
                        sx={{
                          borderRadius: '20px',
                          px: 3,
                          my: 2,
                          float: 'right',
                        }}
                        onClick={() => {
                          clevertap.event.push('Clicked Button', {
                            Action: 'Create eMandate',
                            Page_name: 'eMandate',
                          })
                          setLoading(true)
                          createEMandate()
                        }}
                      >
                        Create E-Mandate
                      </LoadingButton>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {messageResponse && <EMandateForm emandateDate={messageResponse} />}
    </>
  )
}

export { EMandate, EMandateForm }
