/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Box, Typography, Menu, MenuItem, IconButton, useTheme, Switch } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../stylesheet.scss'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import MenuIcon from '@mui/icons-material/Menu'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import { userDetailsState } from '../Pages/LoginState'
import { getUserDetails, isDematUser, removeToken } from '../utils/AuthUtil'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import reallogo from '../assets/Icons/reallogo.png'
import darkLogo from '../assets/DarkThemeFabLogo.png'
import env from '../env/env'
import { NotificationsSelector } from '../store/AccountDetailsStore'
import { Notifications } from './Notifications'
import { patch, post } from '../http/restservice'
import { navbarData, navbarDataNonDemat, navbarTitles, navTitles } from '../constants/navbar'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet'
import { Button } from './ui/button'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from './ui/menubar'
import KYC from '../assets/Navbar/kyc.svg'
import avatar from '../assets/Navbar/Group 146.png'
import goals from '../assets/Navbar/goals.svg'
import useMediaQuery from '../hooks/useMediaQuery'
import { DrawerContext } from '../Provider/DrawerContext'
import { messageModalState } from '../store/Feedback'
import useLocalStorage from '../hooks/useLocalStorage'
import { NavbarContext } from '../Provider/ShowNavbar'
import { ChevronLeft, Instagram, LinkedIn, Logout, Twitter, YouTube } from '@mui/icons-material'
import ShoppingBag from '../assets/Navbar/ShoppingBag.svg'
import Timeline from '../assets/Navbar/Timeline.svg'
import ArrowRight from '../assets/Navbar/ArrowRight.svg'

const socials = [
  { id: 1, icon: <YouTube sx={{ color: 'white', width: '14px', height: '14px' }} /> },
  { id: 1, icon: <LinkedIn sx={{ color: 'white', width: '14px', height: '14px' }} /> },
  { id: 1, icon: <Twitter sx={{ color: 'white', width: '14px', height: '14px' }} /> },
  { id: 1, icon: <Instagram sx={{ color: 'white', width: '14px', height: '14px' }} /> },
]

const Header = ({ handleOpenNav }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const location = useLocation()
  const { darkMode, toggleDarkMode } = useThemeMode()
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const theme = useTheme()
  const { state, contents: notifications } = useRecoilValueLoadable(NotificationsSelector)
  const refreshNotifications = useRecoilRefresher_UNSTABLE(NotificationsSelector)
  const [notificationsVar, setNotificationsVar] = useState([])
  const [openMore, setOpenMore] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isDrawerOpen } = useContext(DrawerContext)
  const { setDisplayNavbar } = useContext(NavbarContext)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [showNavbar, setShowNavbar] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [openDiscover, setOpenDiscover] = useState(false)

  useEffect(() => {
    if (openDiscover) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [openDiscover])

  useEffect(() => {
    setDisplayNavbar(showNavbar)
  }, [showNavbar])

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }

    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    removeToken()
    navigate('/login')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (state === 'hasValue' && notifications?.length) setNotificationsVar(notifications)
  }, [state, notifications])

  // const openHelpWidget = () => {
  //   console.log('open')
  //   const contact_form_token = localStorage.getItem('contact_form_token')
  //   if (!contact_form_token || (contact_form_token && isTokenExpired(contact_form_token))) {
  //     console.log('get contact form token')
  //     getContactFormJwtToken()
  //   } else {
  //     window.authenticate(contact_form_token)
  //   }
  //   window.openContactForm()
  // }

  // const getContactFormJwtToken = async () => {
  //   const payload = {
  //     name: userDetails?.firstName + ' ' + userDetails?.lastName,
  //     email: userDetails?.email,
  //     exp: Math.round(Date.now() / 1000) + 300,
  //   }
  //   try {
  //     const response = await post('customerservice/api/customer/getContactFormToken', payload)
  //     if (response.isError) {
  //       return
  //     }
  //     if (response) {
  //       localStorage.setItem('contact_form_token', response.token)
  //       window.authenticate(response.token)
  //     }
  //   } catch (e) {
  //     console.error('error', e)
  //   }
  // }

  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    if (!access_token) {
      navigate('/login')
      return
    }
    setUserDetails(getUserDetails())
    window.initContactForm()
    refreshNotifications()
  }, [])

  const logout = () => {
    removeToken()
    navigate('/login')
  }

  const refer = () => {
    navigate('/dashboard/refer')
  }

  const profileHandler = () => {
    handleClose()
    navigate('/dashboard/profile')
  }

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await patch(`customerservice/api/notification/${notificationId}/markAsRead`)
      if (!response.isError) {
        setNotificationsVar((prevState) => {
          return prevState.filter((notification) => notification.notificationId !== notificationId)
        })
      }
      console.log(response)
      // refreshNotifications()
    } catch (e) {
      console.log(e)
    }
  }

  const findNavTitle = navTitles.filter(
    (el) => el.linkPath === location.pathname || el.highlightUrls.includes(location.pathname),
  )

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
      setIsTransitioning(position > 300 && position < 404)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isMFPage =
    location.pathname === '/dashboard/mutual-funds' ||
    location.pathname === '/dashboard/mutual-funds/' ||
    location.pathname === '/dashboard/mutual-funds/investments' ||
    location.pathname === '/dashboard/mutual-funds/details' ||
    location.pathname === '/dashboard/mutual-funds/place-order' ||
    location.pathname === '/dashboard/mutual-funds/orders' ||
    location.pathname === '/dashboard/mutual-funds/payment-request' ||
    location.pathname === '/dashboard/mutual-funds/sips' ||
    location.pathname === '/dashboard/mutual-funds/redeem/place-order'

  const getNavbarStyle = () => {
    if (!isMFPage) return 'bg-white border-b'
    if (isMFPage && scrollPosition < 400) return 'bg-[#242F4E]'
    if (isMFPage && scrollPosition >= 504) return 'bg-white border-b'
    return 'bg-gradient-to-b from-[#eceef4] to-white'
  }

  const getTextStyle = () => {
    if (!isMFPage) return 'text-black-400'
    if (scrollPosition < 300) return 'text-primary-100'
    if (scrollPosition >= 404) return 'text-black-400'
    return `text-primary-100 transition-colors duration-300 ${
      isTransitioning ? 'text-opacity-50' : ''
    }`
  }

  return (
    <>
      <div
        className={`sticky top-0 lg:hidden z-10 py-5 px-4 flex justify-between items-center ${getNavbarStyle()} mb-0`}
      >
        <div className='block lg:hidden'>
          <img
            src={isMFPage && scrollPosition < 404 ? '/logo2.svg' : '/logo.svg'}
            alt=''
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Logo',
                Page_name: location.pathname,
              })
              navigate('/dashboard')
            }}
            className='cursor-pointer'
          />
        </div>

        <div className='flex lg:hidden justify-center items-center gap-x-4'>
          {/* {!isDesktop && (
            <span
              id='web-inbox-placeholder'
              className={`material-symbols-rounded ${getTextStyle()} w-6 h-6`}
            >
              notifications
            </span>
          )} */}

          <div>
            <Menubar>
              <MenubarMenu className='bg-transparent'>
                <MenubarTrigger onClick={() => setShowMenu(true)}>
                  <Avatar className='cursor-pointer'>
                    {/* <AvatarImage src={avatar} alt='User Image' /> */}

                    <AvatarFallback className='bg-primary-200'>
                      <div className='rounded-full text-white flex justify-center items-center text-xl'>
                        {userDetails?.firstName?.slice(0, 1)}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </MenubarTrigger>
                {/* <a className='no-underline' href='https://fabits.com/pricing' target='_blank'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Pricing
                    </MenubarItem>
                  </a> */}
                <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                  {/* {isDematUser() && (
                    <Link to='/dashboard/profile' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                        Profile
                      </MenubarItem>
                    </Link>
                  )} */}

                  {/* {isDematUser() && (
                    <Link to='/dashboard/myplan' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                        My Plan
                      </MenubarItem>
                    </Link>
                  )} */}

                  <a
                    className='no-underline'
                    href='https://backoffice.fabits.app/shrdbms/userlogin.ss'
                    target='_blank'
                  >
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Back Office
                    </MenubarItem>
                  </a>

                  <Link className='no-underline' to='/dashboard/support'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Support Portal
                    </MenubarItem>
                  </Link>

                  <Link className='no-underline' to='/dashboard/refer'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer bg-[#E7EDFC]'>
                      Refer & Rewards
                    </MenubarItem>
                  </Link>

                  <MenubarItem
                    className='py-2 pl-3 text-sm cursor-pointer bg-error-50 text-error-500'
                    onClick={handleLogout}
                  >
                    Logout
                  </MenubarItem>
                </MenubarContent>

                {showMenu && (
                  <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                    <MenubarItem className='flex justify-between py-4 px-5'>
                      <p onClick={() => setShowMenu(false)}>
                        <ChevronLeft /> Profile
                      </p>
                      <div className='flex lg:hidden justify-center items-center gap-x-4'>
                        {!isDesktop && (
                          <span
                            id='web-inbox-placeholder'
                            className={`material-symbols-rounded text-black-400 w-6 h-6`}
                            onClick={() => setShowMenu(false)}
                          >
                            notifications
                          </span>
                        )}
                      </div>
                    </MenubarItem>
                    <MenubarItem className='flex justify-center py-4 px-5'>
                      <div className='grid justify-items-center'>
                        <div className='h-[72px] w-[72px] bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                          {userDetails?.firstName?.slice(0, 1)}
                        </div>
                        <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                          {userDetails.firstName} {userDetails.lastName}
                        </div>
                      </div>
                    </MenubarItem>
                    {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    <div className='px-5 grid gap-4 my-4'>
                      <div>
                        <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                          ACCOUNT
                        </p>

                        <Link to='/dashboard/profile'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span className='material-symbols-outlined text-xl text-black-500'>
                              person
                            </span>
                            <p>Account Details</p>
                          </MenubarItem>
                        </Link>

                        {/* <Link to='/dashboard/myplan'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span className='material-symbols-outlined text-xl text-black-500'>
                              license
                            </span>
                            <p>Fabits Subscription</p>
                          </MenubarItem>
                        </Link> */}

                        <Link to='/dashboard/funds'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span class='material-symbols-outlined text-xl text-black-500'>
                              account_balance_wallet
                            </span>
                            <p>Wallet</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div>
                        <p className='text-primary-200 font-medium text-[10px] leading-3'>
                          INVESTMENTS
                        </p>

                        <Link to='/dashboard/portfolio'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span className='material-symbols-outlined text-xl text-black-500'>
                              local_mall
                            </span>
                            <p>Individual Holdings</p>
                          </MenubarItem>
                        </Link>

                        <Link to='/dashboard/sips'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span className='material-symbols-outlined text-xl text-black-500'>
                              currency_exchange
                            </span>
                            <p>SIPs</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div>
                        <p className='text-primary-200 font-medium text-[10px] leading-3'>
                          CONNECT
                        </p>

                        <Link to='/dashboard/support'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span class='material-symbols-outlined text-xl text-black-500'>
                              support_agent
                            </span>
                            <p>Support</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div className='flex justify-center'>
                        <MenubarItem className='justify-between w-44 p-0'>
                          {socials.map((social) => {
                            return (
                              <div
                                key={social.id}
                                className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                onClick={() => setShowMenu(false)}
                              >
                                {social.icon}
                              </div>
                            )
                          })}
                        </MenubarItem>
                      </div>
                    </div>

                    <div
                      className='cursor-pointer px-5 my-4  flex justify-center'
                      onClick={(() => setShowMenu(false), handleLogout)}
                    >
                      <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                        Logout
                      </div>
                    </div>
                  </MenubarContent>
                )}
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </div>

      {isDematUser() &&
        !isDrawerOpen &&
        location.pathname !== '/dashboard/theme-details' &&
        location.pathname !== '/dashboard/holdings' && (
          <div>
            <div
              className={`transition-transform duration-300 ease-in-out ${
                showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
              } w-full fixed bottom-0 left-0 right-0 bg-white h-20 flex lg:hidden justify-between gap-x-2 px-2 border-t border-[#D3DBEC] z-[1000]`}
              style={{
                boxShadow: '0px -4px 24px 0px rgba(65, 85, 141, 0.16)',
              }}
            >
              {navbarData.slice(0, 4).map((item, index) => (
                <div
                  key={item.id}
                  className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
                  onClick={() => {
                    clevertap.event.push('Clicked Button', {
                      Action: item.link,
                      Page_name: location.pathname,
                    })
                    if (item.title === 'Discover') {
                      setOpenDiscover(!openDiscover)
                    } else {
                      navigate(item.link)
                      setOpenDiscover(false)
                    }
                  }}
                >
                  {/* $
                  {location.pathname === item.link ||
                    (!openMore &&
                      !openDiscover &&
                      'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center')} */}
                  {index !== 2 && (
                    <span
                      className={`material-symbols-rounded 
                            
               ${
                 openMore &&
                 item.title === 'More' &&
                 !openDiscover &&
                 'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               }

                ${
                  item.title === 'Home' &&
                  !openDiscover &&
                  !openMore &&
                  'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                }

               ${
                 item.title === 'Discover' &&
                 openDiscover &&
                 !openMore &&
                 'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               }

              text-primary-500 py-1 px-5`}
                    >
                      {item.icon}
                    </span>
                  )}
                  {index === 2 && (
                    <div
                      className={`text-primary-500 flex flex-col items-center justify-end h-full`}
                    >
                      <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                        <img
                          src={goals}
                          className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]'
                        />
                      </div>
                      <p
                        className={`font-semibold text-xs -mt-4 ${
                          location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                        }`}
                      >
                        {item.title}
                      </p>
                    </div>
                  )}
                  {index !== 2 && (
                    <p
                      className={`font-semibold text-xs ${
                        location.pathname === item.link ||
                        (item.title === 'Discover' && openDiscover)
                          ? 'text-primary-500'
                          : 'text-primary-400'
                      }`}
                    >
                      {item.title}
                    </p>
                  )}
                </div>
              ))}

              <div
                className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
                onClick={() => {
                  setOpenMore(!openMore), setOpenDiscover(false)
                }}
              >
                <span
                  className={`material-symbols-rounded ${
                    location.pathname === 'shanti' &&
                    !openMore &&
                    'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                  } 

               ${
                 openMore &&
                 navbarData[4].title === 'More' &&
                 'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               } text-primary-500 py-1 px-5`}
                >
                  {navbarData[4].icon}
                </span>

                <Sheet open={openMore}>
                  <SheetContent side='bottom' className='rounded-t-[24px] h-auto bottom-[80px]'>
                    <SheetHeader className='mb-6'>
                      <SheetTitle className='font-DMSans text-[20px] kerning-[28px] -tracking-[0.8px] text-black-500 font-semibold'>
                        More Investment Options
                      </SheetTitle>
                    </SheetHeader>
                    <div className='grid grid-cols-3'>
                      {navbarData.slice(5, 11).map((item) => (
                        <div
                          key={item.id}
                          className='flex flex-col justify-center items-center relative min-h-28 min-w-27 gap-y-2'
                          onClick={() => navigate(item.link)}
                        >
                          <img src={item.icon} alt={item.title} />
                          <p
                            className={`font-DMSans font-semibold text-l text-black-500 -tracking-[0.64px]`}
                          >
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </SheetContent>
                </Sheet>

                <p
                  className={`font-semibold text-xs ${
                    location.pathname === navbarData[5].link
                      ? 'text-primary-500'
                      : 'text-primary-400'
                  }`}
                >
                  {navbarData[4].title}
                </p>
              </div>
            </div>

            {openDiscover && (
              <div
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.8) 40%, #FFFFFF 65%)',
                }}
                className={`fixed top-0 left-0 min-h-screen w-screen bg-white z-[100] flex flex-col justify-end`}
              >
                <div className={`flex items-center justify-center w-full`}>
                  {/* Left Border */}
                  <div
                    className='h-[2px] flex-grow'
                    style={{
                      background: 'linear-gradient(90deg, rgba(211, 219, 236, 0) 0%, #D3DBEC 100%)',
                    }}
                  ></div>

                  {/* Heading */}
                  <h1 className='mx-4 text-xl font-DMSans font-semibold text-primary-800'>
                    Discover
                  </h1>

                  {/* Right Border */}
                  <div
                    className='h-[2px] flex-grow'
                    style={{
                      background: 'linear-gradient(90deg, #D3DBEC 0%, rgba(211, 219, 236, 0) 100%)',
                    }}
                  ></div>
                </div>

                <div className={`w-full px-5 mt-6 ${showNavbar ? 'mb-24' : 'mb-5'}`}>
                  <div className='py-4 border-b border-[#D3DBEC] flex justify-between items-center'>
                    <div
                      onClick={() => {
                        navigate('/dashboard/mutual-funds'), setOpenDiscover(false)
                      }}
                      className='flex gap-3 items-center'
                    >
                      <img src={ShoppingBag} alt='ShoppingBagIcon' height={28} width={24} />
                      <p className='font-semibold font-DMSans text-base text-primary-500'>
                        Mutual Funds{' '}
                        <button className='py-[2px] px-1 text-xs text-white bg-secondary-500 rounded-sm box-border'>
                          NEW!
                        </button>
                      </p>
                    </div>
                    <div>
                      <img src={ArrowRight} alt='Right Arrow' />
                    </div>
                  </div>
                  <div className='py-4 flex justify-between items-center'>
                    <div
                      onClick={() => {
                        navigate('/dashboard/theme'), setOpenDiscover(false)
                      }}
                      className='flex gap-3 items-center'
                    >
                      <img src={Timeline} alt='TimelineIcon' height={28} width={24} />
                      <p className='font-semibold font-DMSans text-base text-primary-500'>
                        Exchange Traded Funds (ETFs)
                      </p>
                    </div>
                    <div>
                      <img src={ArrowRight} alt='Right Arrow' />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

      {!isDematUser() && location.pathname !== '/dashboard/theme-details' && (
        <div>
          <div
            className={`transition-transform duration-300 ease-in-out ${
              showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
            } w-screen fixed bottom-0 left-0 bg-white h-20 flex lg:hidden justify-between gap-x-2 px-2 border-t border-[#D3DBEC] z-[1000] shadow-[0px_-4px_24px_0px_rgba(65,85,141,0.12)]`}
          >
            {navbarDataNonDemat.slice(0, 5).map((item, index) => (
              <div
                key={item.id}
                className='flex flex-col justify-center items-center mt-3 mb-4 relative gap-y-1'
                onClick={() => {
                  if (index === 2) {
                    setMessageModalState({
                      message: 'Your KYC is incomplete.',
                      linkMessage:
                        'Click here to complete your KYC and to enable hassle free, one-click investing.',
                      severity: 'info',
                    })
                  } else if (index === 1) {
                    setOpenDiscover(!openDiscover)
                  } else {
                    navigate(item.link)
                    setOpenDiscover(false)
                  }
                }}
              >
                {index != 2 && (
                  <span
                    className={`material-symbols-rounded ${
                      location.pathname === item.link &&
                      'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                    } text-primary-500 py-1 px-5`}
                  >
                    {item.icon}
                  </span>
                )}

                {index === 2 && (
                  <div className={`text-primary-500 flex flex-col items-center justify-end h-full`}>
                    <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                      <img src={KYC} className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]' />
                    </div>
                    <p
                      className={`font-semibold text-xs -mt-4 ${
                        location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                      }`}
                    >
                      {item.title}
                    </p>
                  </div>
                )}

                {index != 2 && (
                  <p
                    className={`font-semibold text-xs ${
                      location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                    }`}
                  >
                    {item.title}
                  </p>
                )}
              </div>
            ))}
          </div>

          {openDiscover && (
            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.8) 40%, #FFFFFF 65%)',
              }}
              className={`fixed top-0 left-0 min-h-screen w-screen bg-white z-[100] flex flex-col justify-end`}
            >
              <div className={`flex items-center justify-center w-full`}>
                {/* Left Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, rgba(211, 219, 236, 0) 0%, #D3DBEC 100%)',
                  }}
                ></div>

                {/* Heading */}
                <h1 className='mx-4 text-xl font-DMSans font-semibold text-primary-800'>
                  Discover
                </h1>

                {/* Right Border */}
                <div
                  className='h-[2px] flex-grow'
                  style={{
                    background: 'linear-gradient(90deg, #D3DBEC 0%, rgba(211, 219, 236, 0) 100%)',
                  }}
                ></div>
              </div>

              <div className={`w-full px-5 mt-6 ${showNavbar ? 'mb-24' : 'mb-5'}`}>
                <div className='py-4 border-b border-[#D3DBEC] flex justify-between items-center'>
                  <div
                    onClick={() => {
                      navigate('/dashboard/mutual-funds'), setOpenDiscover(false)
                    }}
                    className='flex gap-3 items-center'
                  >
                    <img src={ShoppingBag} alt='ShoppingBagIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Mutual Funds{' '}
                      <button className='py-[2px] px-1 text-xs text-white bg-secondary-500 rounded-sm box-border'>
                        NEW!
                      </button>
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
                <div className='py-4 flex justify-between items-center'>
                  <div
                    onClick={() => {
                      navigate('/dashboard/theme'), setOpenDiscover(false)
                    }}
                    className='flex gap-3 items-center'
                  >
                    <img src={Timeline} alt='TimelineIcon' height={28} width={24} />
                    <p className='font-semibold font-DMSans text-base text-primary-500'>
                      Exchange Traded Funds (ETFs)
                    </p>
                  </div>
                  <div>
                    <img src={ArrowRight} alt='Right Arrow' />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Header
