import React from 'react'
import { Box, Grid, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const TermsAndConditions = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div
            className='flex flex-row justify-start items-center gap-x-1 mx-4 my-4 lg:mx-0 lg:mt-0 lg:mb-6 cursor-pointer'
            onClick={() => navigate('/dashboard')}
          >
            <span className='material-symbols-rounded icon-filled-400 text-[24px]'>
              chevron_left
            </span>
            <Typography component='h5' fontWeight={500} sx={{ margin: '0 8px' }}>
              Terms and conditions
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Box
        sx={{
          fontWeight: '500',
          background: theme.palette.secondary.main,
          padding: '10px 25px',
          margin: '1rem 8px',
          borderRadius: '8px',
        }}
      >
        <List sx={{ listStyle: 'decimal', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`Shareway Securities Pvt. Ltd. (hereinafter referred to as ‘Company’) through its platform 'Fabits’ facilitates applying for Initial Public Offer (IPO) and Follow on Public offer (FPO) to the Users. Funds are blocked as per the Funds/Payments are collected as per the ASBA system. ASBA means "Applications Supported by Blocked Amount". ASBA is a facility provided by banks to investors in initial public offerings (IPOs) and follow-on public offers (FPOs) to block funds in their bank account without having to make a payment.`}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`Applications will be processed without validating the funds availability in User’s bank account. The User should ensure that sufficient funds are available in the Bank Account for which the UPI ID is provided in the online IPO application. The Company may also become a sub-broker/sub-syndicate member/sub-distributor for facilitating IPO/FPO applications for the Users. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The IPO bidding is done on the Exchange platform through APIs provided by the Exchange. As the bids are placed through a digital platform, there could arise technical fault or error, data loss during transmission. The Company will not be held responsible and will not be under any compulsion to compensate for any “lost opportunity” viz. notional profits for non-allotment which could not be executed due to any reason whatsoever, including but not limited to system issues, technical glitches, malfunction etc. The Company will process the application on a best effort basis. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`By applying for an IPO the user accepts that they have read the offer related documents carefully before investing any amount and that Shareway Securities is not liable for any losses incurred on part of the user. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The Company shall not be responsible for non-allotment or delayed allotment of shares in any manner whatsoever. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The User is required to enter the UPI ID for his bank account only. User is required to enter the respective UPI ID of the bank. In case of any mismatch, the application is liable to be rejected. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`Post successful bidding, User will receive block fund request which needs to be accepted. Allotment will not happen if the User does not accept the block request immediately. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The minimum application size, lot size, cut-off price etc are decided by the respective issuers through its Lead Managers. The Company only provides online platform for users to submit their bids and is independent of any of the above. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The User hereby agree and acknowledges that the role of the Company is only to facilitate and process IPO/FPO applications, and the Company shall not be responsible / liable for any losses/damages/costs of any nature whatsoever incurred to the User due to the processing or non-processing of the applications. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The Company reserves the right to reject an application in case: `}
            />
            <List sx={{ listStyle: 'disc', pl: 3 }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary={`User provides insufficient details`} />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText
                  primary={`If it is of the view that processing the same would violate the guidelines laid done by any Regulators `}
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText
                  primary={`If applications are not submitted within stipulated time `}
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText
                  primary={`If User applies in different category for which he/she is eligible `}
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary={`If User do not accept the ASBA fund blocking request`} />
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The User hereby give his/her consent to the Company to share his/her personal information including KYC information with other Group entities of the Company for products and/or services offered by them through the Platform to the User. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The User agrees to indemnify and keep the Company and/or their directors, associates, group, employees and representatives indemnified from and against all actions, claims, (third party claims) demands, proceedings, losses, damages, costs, charges and expenses, directly or indirectly, whatsoever (“Losses”) which the Company and/or their employees, associates, directors, representative may at any time incur, sustain, suffer or be put to as a consequence of or by reason of or arising out of: (i) the Company acting in good faith and taking or refusing to take or omitting to take action on the User’s instructions, and in particular arising directly or indirectly out of the negligence, mistake or misconduct of the User; (ii) The User’s breach or non-compliance of the T&C and/or (iii) fraud or dishonesty relating to any transaction by the User or misrepresentation with respect to data and information provided by the User. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`The Company reserves the right to change/modify this T&C without any prior notice to the User. The User can determine when the T&Cs was last modified by referring to the “Last Updated” legend above. It shall be User’s responsibility to check these T&Cs periodically for changes. User’s acceptance of the amended T&Cs shall signify User’s consent to the changes and agree to be legally bound by the same. `}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={`All disputes and differences arising out of, under or in connection with these terms and conditions or anything done here under shall be within the exclusive jurisdiction only to the courts of Bangalore. `}
            />
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default TermsAndConditions
