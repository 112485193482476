import React from 'react'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../ui/pagination'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'

export default function MFPagination({
  page,
  setPage,
  totalPages,
  pageSize,
  setPageSize,
  totalItems,
}) {
  const handlePageChange = (newPage) => {
    const validPage = Math.max(1, Math.min(newPage, totalPages))
    setPage(validPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    const size = parseInt(newPageSize, 10)
    setPageSize(size)

    // Calculate the new total pages
    const newTotalPages = Math.ceil(totalItems / size)

    if (page > newTotalPages) {
      setPage(newTotalPages > 0 ? newTotalPages : 1) // Ensure page is at least 1
    }
  }

  const renderPageNumbers = () => {
    let pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
        pages.push(
          <PaginationItem key={i}>
            <PaginationLink
              href='#'
              onClick={(e) => {
                e.preventDefault()
                handlePageChange(i)
              }}
              isActive={page === i}
            >
              {i}
            </PaginationLink>
          </PaginationItem>,
        )
      } else if (i === page - 2 || i === page + 2) {
        pages.push(
          <PaginationItem key={i}>
            <PaginationEllipsis />
          </PaginationItem>,
        )
      }
    }
    return pages
  }

  return (
    <div className='flex flex-row items-center md:gap-4'>
      <div className='md:flex items-center gap-2 hidden'>
        <span className='whitespace-nowrap text-sm hidden md:block'>Funds per page</span>
        <Select value={pageSize} onValueChange={handlePageSizeChange}>
          <SelectTrigger className='px-2 py-0 border rounded-lg'>
            <SelectValue placeholder='10' />
          </SelectTrigger>
          <SelectContent>
            {[10, 20, 30, 40, 50].map((size) => (
              <SelectItem key={size} value={size}>
                {size}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <Pagination className='justify-start md:justify-end'>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              href='#'
              onClick={(e) => {
                e.preventDefault()
                if (page > 1) handlePageChange(page - 1)
              }}
            />
          </PaginationItem>
          <div className='hidden md:flex gap-x-1'>{renderPageNumbers()}</div>
          <div className='md:hidden mx-1'>{page}</div>
          <PaginationItem>
            <PaginationNext
              href='#'
              onClick={(e) => {
                e.preventDefault()
                if (page < totalPages) handlePageChange(page + 1)
              }}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
      <div className='md:hidden items-center gap-2 flex border py-0 px-2 rounded-lg'>
        <span className='whitespace-nowrap text-sm hidden md:block'>Funds per page</span>
        <Select value={pageSize} onValueChange={handlePageSizeChange}>
          <SelectTrigger className='px-0 py-0'>
            <SelectValue placeholder='10' />
          </SelectTrigger>
          <SelectContent>
            {[10, 20, 30, 40, 50].map((size) => (
              <SelectItem key={size} value={size}>
                {size}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
