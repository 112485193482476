/* eslint-disable */
import UPI from '../../assets/SVGS/upi.svg'
import blub from '../../assets/SVGS/blub.svg'
import CircleFabits from '../../assets/SVGS/circle-fabits.svg'
import CircleRight from '../../assets/SVGS/circle-right.svg'
import { Separator } from '../../Components/ui/separator'
import { Button } from '../../Components/ui/button'
import { useEffect, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'
import bse from '../../assets/SVGS/bse.svg'
import { useLocation } from 'react-router-dom'
import { AccountBalance } from '@mui/icons-material'
import env from '../../env/env'

const MFSipMandate = ({ bankDetails, setShowMfSipMandatePopup, redirectURL, isLoading }) => {
  const [timeLeft, setTimeLeft] = useState(300) // 5 minutes = 300 seconds
  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  useEffect(() => {
    // if (
    //   timeLeft === 250 ||
    //   timeLeft === 200 ||
    //   timeLeft === 150 ||
    //   timeLeft === 100 ||
    //   timeLeft === 0
    // ) {
    //   paymentStatus()
    // }

    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      // Clear the timeout when component unmounts or when timeLeft changes
      return () => clearTimeout(timerId)
    } else {
      setShowMfSipMandatePopup(false)
    }
  }, [timeLeft])

  // Convert the remaining time to minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  console.log(bankDetails, 'sdfsdf')

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  return (
    <div className='bg-white absolute top-0 left-0 right-0 bottom-0 z-[10000]'>
      <div className='ease-in-out h-[95vh] md:max-w-[360px] m-auto flex flex-col justify-center gap-y-12 pt-12 pb-16 px-4 lg:pb-0 lg:pt-0 lg:px-0'>
        {/* <div className='flex justify-center items-center mt-12'>
        <span className='animate-bounce text-xl border-[2px] border-secondary-600 rounded-full h-16 w-16 flex justify-center items-center'>
          {formatTime(timeLeft)}
        </span>
      </div> */}
        <div className='flex flex-col gap-y-12'>
          <div>
            <h1 className='text-center text-2xl text-black-500 font-semibold'>
              Taking you to create mandate on your bank’s website
            </h1>
            <p className='text-center text-sm text-black-400'>
              The amount is transferred directly to <strong>BSE</strong>
            </p>
          </div>
          <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-[64px]'>
            <img src={'/logo.svg'} alt='UPI Icon' />
            <img
              src={PaymentGif}
              alt='Moving GIF'
              className='absolute left-[25.51%] move-left-right rotate-90 z-2'
            />
            {/* Add the bank icon here */}
            {src ? (
              <img src={src} alt='bank logos' onError={handleError} height={24} width={24} />
            ) : (
              <AccountBalance className='text-primary-600' />
            )}
          </div>

          <div className='bg-[#fdf7e8] rounded-lg p-4'>
            <div className='space-y-4 text-warning-600'>
              <div className='flex gap-x-2 items-center'>
                <img src={blub} />
                <h2 className='font-semibold'>What is a mandate?</h2>
              </div>

              <ol className='space-y-4 list-disc text-sm px-5'>
                <li>
                  <strong>Maximum amount </strong>
                  that BSE is permitted to deduct from your account
                </li>
                <li>
                  The mandate amount may be more than your desired SIP amount, to account for future
                  changes in your investment plans.
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* <p className='text-black-400 text-center text-sm'>
          Request expires in <b>{formatTime(timeLeft)}</b>
        </p> */}
        <Button
          loading={isLoading}
          disabled={!redirectURL || isLoading}
          onClick={() => window.open(redirectURL)}
        >
          Continue
        </Button>
        {/* <Button className='bg-error-50 text-error-500 hover:bg-error-100'>Continue</Button> */}
      </div>
    </div>
  )
}

export default MFSipMandate
