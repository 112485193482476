/* eslint-disable */
import {
  ChevronDown,
  CircleAlert,
  CopyIcon,
  MoveLeft,
  MoveRight,
  PiggyBank,
  Plus,
} from 'lucide-react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../Components/ui/tabs'
import React, { useEffect, useState } from 'react'
import { Button } from '../../Components/ui/button'
import { ContentCopy, Error, KeyboardArrowDown, NorthWest, SouthEast } from '@mui/icons-material'
import { MutualFundsOrders } from '../../store/MFStore'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import Loader from '../../Components/Loader/Loader'
import { Card, CardContent, CardHeader, CardTitle } from '../..//Components/ui/card'
import env from '../../env/env'
import { areDatesDifferent } from '../../utils/Util'

const MFOrders = () => {
  const orders = useRecoilValueLoadable(MutualFundsOrders)
  const [groupedOrders, setGroupedOrders] = useState({})
  const [tabValue, setTabValue] = useState('Individual Investments')
  const [expandedIndex, setExpandedIndex] = useState(null)
  const refreshOrder = useRecoilRefresher_UNSTABLE(MutualFundsOrders)

  // const groupByDate = (orders) => {
  //   return orders.reduce((acc, order) => {
  //     const date = order.createdTimeStamp.split(' ')[0] // Extract date portion
  //     if (!acc[date]) acc[date] = []
  //     acc[date].push(order)
  //     return acc
  //   }, {})
  // }

  // useEffect(() => {
  //   if (orders.state === 'hasValue' && orders.contents?.totalItems > 0) {
  //     setGroupedOrders(groupByDate(orders.contents.orders))
  //   } else if (orders.state === 'hasError') {
  //     console.error('Error fetching orders:', orders.contents)
  //   }
  // }, [orders])

  useEffect(() => {
    refreshOrder()
  }, [])

  // Use regular expressions to extract the required information
  const extractInfo = (str) => {
    console.log('🚀 ~ extractInfo ~ str:', str)
    const regex = /SCHEME:\s*([^\s]+)\s*.*ORDER NO:\s*(\d+)/

    const match = str.match(regex)

    if (match) {
      return `Scheme: ${match[1]}, Order No: ${match[2]}`
    } else {
      return 'No match found'
    }
  }

  switch (orders.state) {
    case 'loading':
      return <Loader />
    case 'hasValue':
      return (
        <>
          <div className='bg-white min-h-screen py-6 sm:py-12'>
            {orders?.contents.totalItems > 0 ? (
              <Tabs
                defaultValue='Individual Investments'
                value={tabValue}
                onValueChange={setTabValue}
                className='max-w-[1128px] mx-auto'
              >
                <TabsList className='mx-auto w-full'>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'Individual Investments' && 'text-primary-700 font-semibold'
                    }`}
                    value='Individual Investments'
                  >
                    Individual {tabValue === 'Individual Investments' && 'Orders'}
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'Individual Investments' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                  {/* <TabsTrigger
            className={`text-primary-200 text-xl font-light flex flex-col ${
              tabValue === 'Basket' && 'text-primary-700 font-semibold'
            }`}
            value='Basket'
          >
            Basket {tabValue === 'Basket' && 'Orders'}
            <span
              className={`h-2 w-2 rounded-full  ${
                tabValue === 'Basket' ? 'bg-primary-700' : 'bg-transparent'
              }`}
            ></span>
          </TabsTrigger> */}
                </TabsList>
                <TabsContent value='Individual Investments' className='h-full py-12 lg:px-4'>
                  {/* MF Orders list */}

                  <div className='w-full mx-auto overflow-hidden'>
                    {orders?.contents?.orders?.map((transaction, index) => (
                      <>
                        <div
                          key={index}
                          className='border-b border-gray-200 last:border-b-0 cursor-pointer px-4 lg:px-0'
                        >
                          {/* <div className='py-2 text-sm font-medium text-black-400 mb-4 mt-12 leading-5'>
                            {index}
                          </div> */}

                          {/* {orders.map((transaction, index) => ( */}
                          <div className={`p-4 flex items-center lg:items-start`}>
                            <div
                              className={`flex-shrink-0 mr-6 text-[10px] flex flex-col justify-center items-center border p-2.5 rounded-[60px] relative leading-5 max-w-[58px] ${
                                transaction.buySell === 'PURCHASE'
                                  ? 'text-black-400'
                                  : 'text-black-400'
                              }`}
                            >
                              {/* {index === 1 && (
                                <Error
                                  className='absolute -bottom-1 -right-1 h-4 w-4 text-error-500 z-2'
                                  sx={{ fontSize: '20px' }}
                                />
                              )} */}

                              {transaction.buySell === 'PURCHASE' ? (
                                <SouthEast sx={{ fontSize: '20px' }} />
                              ) : (
                                <NorthWest sx={{ fontSize: '20px' }} />
                              )}
                              {transaction.buySell === 'PURCHASE' ? 'Buy' : 'Sell'}
                            </div>
                            {/* <div className='hidden md:block mr-4'>
                                <img
                                  src={`${env.MF_LOGOS_URL}/${400034}.png`}
                                  alt=''
                                  className='h-12 w-12'
                                />
                              </div> */}

                            <div className='w-full'>
                              <div
                                className='flex justify-between items-center w-full'
                                onClick={() => {
                                  setExpandedIndex((prevIndex) =>
                                    prevIndex === index ? null : index,
                                  )
                                }}
                              >
                                <div className='flex-grow'>
                                  <div className='flex justify-between items-center'>
                                    <p className='font-semibold text-gray-900  md:max-w-full text-wrap leading-6'>
                                      {transaction?.legalName}
                                    </p>

                                    {/* <span className='flex-shrink-0 text-black-200 lg:hidden'>
                                        <KeyboardArrowDown sx={{ fontSize: '24px' }} />
                                      </span> */}
                                  </div>

                                  <p
                                  // className={`text-sm font-medium text-black-400 leading-5 ${
                                  //   transaction.status.includes('failed')
                                  //     ? 'text-red-500'
                                  //     : 'text-gray-500'
                                  // }`}
                                  >
                                    {transaction.buySell}
                                  </p>

                                  {/* <div className='flex justify-between items-center text-right mt-2 lg:hidden'>
                                    <p className='text-sm font-medium text-black-400'>
                                      {transaction.qty ? transaction.qty : '#'}
                                      Units
                                    </p>
                                    <p className='font-semibold'>
                                      ₹ {transaction.amount.toLocaleString()}
                                    </p>
                                  </div> */}

                                  {/* <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                      <ChevronDown className='flex-shrink-0 w-5 h-5 text-black-200' />
                    </div> */}
                                </div>

                                <div className='hidden lg:flex leading-5 flex-shrink-0 text-right w-[100px]'>
                                  <p className='text-sm font-medium text-black-400'>
                                    {transaction.qty ? transaction.qty : '#'} Units
                                  </p>
                                </div>

                                <div className='hidden lg:flex justify-end items-center gap-x-1 min-w-[150px]'>
                                  {/* <p className='font-semibold leading-6'>
                                    ₹ {transaction.amount.toLocaleString()}
                                  </p> */}
                                  {/* <KeyboardArrowDown
                                      className='flex-shrink-0 text-black-200'
                                      sx={{ fontSize: '24px' }}
                                    /> */}
                                </div>
                              </div>
                              {/* <div
                                className={`text-sm mr-10 hidden lg:flex justify-between items-center border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                  expandedIndex === index
                                    ? 'max-h-48 opacity-100 mt-4 pt-4'
                                    : 'max-h-0 opacity-0'
                                }`}
                              >
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    Order ID
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy order ID</span>
                                    </Button>
                                  </div>
                                </div>

                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Reference No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      123456788765432
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP reference number</span>
                                    </Button>
                                  </div>
                                </div>
                                <div className='flex items-center justify-between gap-x-2 leading-5'>
                                  <span className='font-medium text-sm text-black-400'>
                                    SIP Registration No.
                                  </span>
                                  <div className='flex items-center space-x-2'>
                                    <span className='font-semibold text-black-500 text-sm'>
                                      FAB-01559872
                                    </span>
                                    <Button size='simple' variant='ghost'>
                                      <ContentCopy
                                        className='text-primary-500'
                                        sx={{ fontSize: '16px' }}
                                      />
                                      <span className='sr-only'>Copy SIP registration number</span>
                                    </Button>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          {/* ))} */}
                          {/* <div
                            className={`mt-4 space-y-2 text-xs flex flex-col gap-y-4 lg:hidden border-t border-dashed text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                              expandedIndex === index
                                ? 'max-h-48 opacity-100 py-3'
                                : 'max-h-0 opacity-0'
                            }`}
                          >
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>Order ID</span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500 text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy order ID</span>
                                </Button>
                              </div>
                            </div>

                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Reference No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  123456788765432
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP reference number</span>
                                </Button>
                              </div>
                            </div>
                            <div className='flex items-center justify-between gap-x-2'>
                              <span className='font-medium text-xs text-black-400'>
                                SIP Registration No.
                              </span>
                              <div className='flex items-center space-x-2'>
                                <span className='font-semibold text-black-500  text-xs'>
                                  FAB-01559872
                                </span>
                                <Button size='simple' variant='ghost'>
                                  <CopyIcon className='h-4 w-4 rotate-90 text-primary-500' />
                                  <span className='sr-only'>Copy SIP registration number</span>
                                </Button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </>
                    ))}
                  </div>
                </TabsContent>
                {/* <TabsContent value='Basket'>b</TabsContent> */}
              </Tabs>
            ) : (
              <div className='bg-white flex items-center justify-center'>
                <Card className='w-full max-w-sm text-center border-none shadow-none'>
                  <CardHeader>
                    <PiggyBank className='w-12 h-12 text-primary mx-auto mb-4' />
                    <CardTitle className='text-xl'>You have not made an order yet</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className='text-muted-foreground mb-6'>
                      Start your investment journey by creating your first Systematic Investment
                      Plan.
                    </p>
                    <Button className='w-full'>
                      <Plus className='mr-2 h-4 w-4' /> Create Order
                    </Button>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </>
      )

    case 'hasError':
      return <div>Error</div>

    default:
      return null
  }
}

export default MFOrders
