import useMediaQuery from '../../hooks/useMediaQuery'
import { Button } from '../ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog'
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '../ui/sheet'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { useContext } from 'react'
import { NavbarContext } from '../../Provider/ShowNavbar'

const SipDialog = ({ open, setOpen, modifyOpen, setModifyOpen, modifiedInvestment }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { displayNavbar } = useContext(NavbarContext)

  const handleSaveModification = () => {
    console.log('Saving modified investment:', modifiedInvestment)
    setModifyOpen(false)
  }
  return (
    <div>
      {isDesktop ? (
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent className='sm:max-w-[425px]'>
            <DialogHeader>
              <DialogTitle className='text-start font-DMSans font-semibold text-2xl'>
                Modify SIP
              </DialogTitle>
            </DialogHeader>
            <div className='grid justify-between gap-4'>
              <div className='grid grid-cols-2 items-center gap-4'>
                <Label
                  htmlFor='amount'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  Amount
                </Label>
                <Input
                  startAdornment={'₹'}
                  id='amount'
                  defaultValue='1000'
                  className='col-span-3'
                />
              </div>
              <div className='grid grid-cols-2 items-center gap-4'>
                <Label
                  htmlFor='step'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  Annual Step Up
                </Label>
                <Input endAdornment={'%'} id='step' defaultValue='10' className='col-span-3' />
              </div>
              <div className='grid grid-cols-2 items-center gap-4'>
                <Label
                  htmlFor='date'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  SIP Date
                </Label>
                <p className='text-primary-500 underline decoration-dashed underline-offset-4'>
                  20th of every month
                </p>
              </div>
            </div>
            <DialogFooter>
              <div className='flex justify-between w-full gap-4'>
                <Button
                  onClick={() => setOpen(false)}
                  className='bg-white border hover:bg-primary-50 text-[#41558D] flex-1'
                  type='submit'
                >
                  Cancel
                </Button>
                <Button className='flex-1' type='submit'>
                  Save changes
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={modifyOpen} onOpenChange={setModifyOpen}>
          <SheetContent
            side='bottom'
            className={`${displayNavbar ? 'bottom-20' : 'bottom-0'} h-72`}
            style={{ borderRadius: '24px 24px 0px 0px' }}
          >
            <SheetHeader>
              <SheetTitle className='text-start font-DMSans font-semibold text-base'>
                Modify SIP
              </SheetTitle>
            </SheetHeader>
            <div className='grid gap-4 py-4'>
              <div className='grid grid-cols-2 gap-4'>
                <Label
                  htmlFor='amount'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  Amount
                </Label>
                <Input startAdornment='₹' id='amount' defaultValue='1000' className='w-full' />
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <Label
                  htmlFor='step'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  Annual Step Up
                </Label>
                <Input endAdornment='%' id='step' defaultValue='10' className='w-full' />
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <Label
                  htmlFor='date'
                  className='text-start font-DMSans font-medium text-sm text-black-500'
                >
                  SIP Date
                </Label>
                <p className='text-primary-500 underline decoration-dashed underline-offset-4'>
                  20th of every month
                </p>
              </div>
            </div>
            <SheetFooter>
              <div className='flex justify-between w-full gap-4'>
                {/* <SheetClose asChild> */}
                <Button
                  onClick={() => {
                    setModifyOpen(false)
                    setOpen(false)
                  }}
                  className='bg-white border text-[#41558D] flex-1'
                  type='button'
                >
                  Discard
                </Button>
                {/* </SheetClose> */}
                <Button onClick={handleSaveModification} className='flex-1' type='submit'>
                  Save
                </Button>
              </div>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      )}
    </div>
  )
}

export default SipDialog
