/* eslint-disable */

import { CommandGroup, CommandItem, CommandList, CommandInput } from './command'
import { Command as CommandPrimitive } from 'cmdk'
import { useState, useRef, useCallback, useEffect } from 'react'

import { Skeleton } from './skeleton'

import { Check } from 'lucide-react'
import { cn } from '../../lib/utils'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  disabled,
  isLoading = false,
}) => {
  const inputRef = useRef(null)
  const navigate = useNavigate()
  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState(value)
  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = useCallback(
    (event) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true)
      }

      // This is not a default behaviour of the <input /> field
      if (event.key === 'Enter' && input.value !== '') {
        const optionToSelect = options.find((option) => option.label === input.value)
        if (optionToSelect) {
          setSelected(optionToSelect)
          onValueChange?.(optionToSelect)
        }
      }

      if (event.key === 'Escape') {
        input.blur()
      }
    },
    [isOpen, options, onValueChange],
  )

  const handleBlur = useCallback(() => {
    setOpen(false)
    setInputValue(selected?.label)
  }, [selected])

  const handleSelectOption = useCallback(
    (selectedOption) => {
      setInputValue(selectedOption.label)

      setSelected(selectedOption)
      onValueChange?.(selectedOption)

      // This is a hack to prevent the input from being focused after the user selects an option
      // We can call this hack: "The next tick"
      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [onValueChange],
  )

  return (
    <CommandPrimitive onKeyDown={handleKeyDown}>
      <div>
        <CommandInput
          ref={inputRef}
          value={value}
          onValueChange={isLoading ? undefined : onValueChange}
          onBlur={handleBlur}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          disabled={disabled}
          className='text-base text-white'
        />
      </div>
      <div className='relative mt-1'>
        <div
          className={cn(
            'animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none',
            isOpen ? 'block' : 'hidden',
          )}
        >
          <CommandList className='rounded-lg'>
            {isLoading ? (
              <CommandPrimitive.Loading>
                <div className='p-1'>
                  <Skeleton className='h-8 w-full' />
                </div>
              </CommandPrimitive.Loading>
            ) : null}
            {options.length > 0 && !isLoading ? (
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selected?.value === option.value
                  return (
                    <CommandItem
                      key={option.value}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      // onSelect={() => handleSelectOption(option.value)}
                      className={cn(
                        'flex w-full items-center gap-2 cursor-pointer',
                        !isSelected ? 'pl-8' : null,
                      )}
                      onSelect={() =>
                        navigate(`/dashboard/mutual-funds/details`, {
                          state: {
                            fundDetails: option.item,
                            schemeCode: option.item.schemeCode,
                          },
                        })
                      }
                    >
                      {isSelected ? <Check className='w-4' /> : null}
                      {option.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            ) : null}
            {!isLoading ? (
              <CommandPrimitive.Empty className='select-none rounded-sm px-2 py-3 text-center text-sm'>
                {emptyMessage}
              </CommandPrimitive.Empty>
            ) : (
              <Loader />
            )}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
