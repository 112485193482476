/* eslint-disable */
import useLocalStorage from '../../hooks/useLocalStorage'
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'

import { post } from '../../http/restservice'
import env from '../../env/env'
import { ArrowDownUp } from 'lucide-react'
import sim_card_download from '../../assets/MyPlan/sim_card_download.svg'
import copy_all from '../../assets/MyPlan/copy_all.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '../ui/sheet'
import { useNavigate } from 'react-router-dom'

const SellRequestForm = () => {
  const { isOpen, onClose, type, data } = useModal()
  const isModalOpen = isOpen && type === 'openSellRequestForm'
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const navigate = useNavigate()

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[746px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
                Sell Fund
              </DialogTitle>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans px-4 pt-5 pb-6 rounded-t-[24px] bottom-0 min-h-60 flex flex-col justify-between`}
            showClose={true}
            side='bottom'
          >
            {data && data?.fund && (
              <div className='flex flex-col justify-between gap-y-4'>
                <div className='flex justify-between gap-x-2'>
                  <img
                    src={`${env.MF_LOGOS_URL}/${data.fund.amcCode}.png`}
                    alt={`logo.svg`}
                    className='h-10 w-10 mt-2'
                  />
                  <div>
                    <h3 className='font-semibold'>
                      {data?.fund.compname.split('#')[1].replace(/FUND.*$/, 'FUND')}
                    </h3>
                    <p className='text-xs text-black-400 font-semibold'>
                      {data?.fund.compname.split('#')[0]}
                    </p>
                  </div>
                </div>

                <div className='flex justify-between items-center lg:block mt-[2px]'>
                  <div className='w-full flex gap-x-2 justify-between items-center md:hidden'>
                    <span className='text-xs text-black-400'> QTY {data?.fund.balance}</span>
                    <span className='font-semibold'>
                      ₹{data?.fund.holding.toLocaleString('en-IN')}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <SheetFooter>
              <Button
                onClick={() => {
                  navigate('/dashboard/mutual-funds/redeem/place-order', {
                    state: {
                      fundDetails: data?.fund,
                    },
                  }),
                    onClose()
                }}
                className='bg-white hover:bg-white border border-error-100 text-error-500 rounded-xl'
              >
                Sell
              </Button>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default SellRequestForm
