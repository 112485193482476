/* eslint-disable */

import { useEffect, useState } from 'react'
import SubscriptionModal from '../Components/modals/SubscriptionModal'
import MandateDetails from '../Components/modals/MandateDetails'
import MFBreakdown from '../Components/modals/MFBreakdown'
import SellRequestForm from '../Components/modals/SellRequestForm'
import MessagePopup from '../Components/Popups/MessagePopup'

const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  return (
    <>
      <MessagePopup />
      <SubscriptionModal />
      <MandateDetails />
      <MFBreakdown />
      <SellRequestForm />
    </>
  )
}

export default ModalProvider
