/* eslint-disable */

import React from 'react'
import {
  AreaChart,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { Card, CardContent } from '../ui/card'

const data = [
  { name: 'Oct', value1: 4000, value2: 2400 },
  { name: 'Nov', value1: 3000, value2: 1398 },
  { name: 'Dec', value1: 5000, value2: 3800 },
  { name: 'Jan 24', value1: 2780, value2: 3908 },
  { name: 'Fab', value1: 1890, value2: 4800 },
  { name: 'Mar', value1: 2390, value2: 3800 },
  { name: 'Apr', value1: 3490, value2: 4300 },
]

// const formatYAxis = (value) => {
//   return (value / 1000).toFixed(2) + 'k'
// }

// Custom Tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { date, value1 } = payload[0].payload
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    return (
      <div className='custom-tooltip bg-white p-2 shadow-lg rounded border'>
        <p className='label'>{`Date: ${formattedDate}`}</p>
        <p className='intro'>{`Value: ${value1}`}</p>
      </div>
    )
  }

  return null
}

const CustomizedDot = (props) => {
  const { cx, cy, payload, dataKey } = props
  const isLastDot = payload.name === data[data.length - 1].name

  if (isLastDot) {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={chartConfig.lines.find((line) => line.dataKey === dataKey)?.color}
      />
    )
  }
  return null
}

// const getDynamicTicks = (data, tickCount = 6) => {
//   const max = Math.max(...data.map((d) => d.value1)) // Use the max value in your data
//   const step = Math.ceil(max / tickCount)

//   return Array.from({ length: tickCount + 1 }, (_, i) => i * step)
// }

function filterByMonthYearRange(data, startMonthYear, endMonthYear) {
  // Convert start and end month/year to Date objects
  const startDate = new Date(startMonthYear + '-01')
  const endDate = new Date(endMonthYear + '-01')

  // Filter the array based on the date range
  return data.filter((item) => {
    const itemDate = new Date(item.name + '-01')
    return itemDate >= startDate && itemDate <= endDate
  })
}

const FundChart = ({ startFilterDate, fundchartData = [], setGainPer }) => {
  const pData = fundchartData?.map((item) => {
    const { navRs, navDate } = item
    return {
      name: new Date(navDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
      value1: navRs,
      date: navDate,
    }
  })

  function getTodayMonthYear() {
    const today = new Date()
    const month = today.toLocaleString('en-US', { month: 'short' }) // e.g., "Nov"
    const year = today.getFullYear() // e.g., 2024
    return `${month} ${year}`
  }

  const startMonthYear = startFilterDate
  const endMonthYear = getTodayMonthYear()
  const filteredData = filterByMonthYearRange(pData, startMonthYear, endMonthYear)

  const filterUniqueByDate = (data) => {
    // Use a Map to store each unique date
    const uniqueDataMap = new Map()

    data.forEach((item) => {
      const dateKey = item.date // Use the exact date as the key

      // Only add the first occurrence of each date to the Map
      if (!uniqueDataMap.has(dateKey)) {
        uniqueDataMap.set(dateKey, item)
      }
    })

    // Convert the Map values back to an array format for recharts
    return Array.from(uniqueDataMap.values())
  }

  const uniqueArr = filterUniqueByDate(filteredData)

  // const dynamicTicks = getDynamicTicks(uniqueArr)

  const chartConfig = {
    lines: [
      // { dataKey: 'value2', name: 'This Fund', color: '#D48D22' },
      { dataKey: 'value1', name: 'This Fund', color: '#41558D' },
    ],
  }

  const values = uniqueArr.map((item) => item.value1)
  const highestValue = Math.ceil(Math.max(...values))
  const lowestValue = Math.floor(Math.min(...values))

  const lastValue = values[values.length - 1]
  const firstValue = values[0]

  const absReturn = (lastValue - firstValue) / firstValue
  const absPer = absReturn * 100

  setGainPer(absPer)

  return (
    <Card className='w-full max-w-3xl border-none shadow-none'>
      <CardContent className='p-0'>
        <ResponsiveContainer width='100%' height={300}>
          <AreaChart
            data={uniqueArr}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='6' vertical={false} horizontal={true} />
            {/* <XAxis dataKey='name' tickLine={false} /> */}
            <YAxis
              // tickFormatter={formatYAxis}
              // ticks={dynamicTicks}
              tick={false}
              domain={[lowestValue, highestValue]}
              axisLine={false}
              tickLine={false}
              allowDataOverflow={true}
              type='number'
            />

            <Tooltip content={<CustomTooltip />} />

            <Legend iconType='circle' align='right' />

            <defs>
              <linearGradient id='customGradient' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor='rgba(65, 85, 141, 0.7)' />
                <stop offset='12%' stopColor='rgba(65, 85, 141, 0)' />
              </linearGradient>
            </defs>

            {chartConfig.lines.map((line, index) => (
              <Area
                key={line.dataKey}
                type='linear'
                dataKey={line.dataKey}
                name={line.name}
                stroke={line.color}
                fill='url(#customGradient)' // Use the gradient for the fill
                stackId='1'
                strokeWidth={4}
                dot={<CustomizedDot />}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
      {/* <CardFooter>
        
      </CardFooter> */}
    </Card>
  )
}

export default FundChart
