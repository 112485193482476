/* eslint-disable */

import FundChart from '../../Components/MutualFunds/FundChart'
import { Button } from '../../Components/ui/button'
// import noise from '../../assets/SVGS/noise.svg'
import rocket from '../../assets/SVGS/rocket.svg'
import CurrencyExchange from '../../assets/SVGS/currency-exchange.svg'
import SendMoney from '../../assets/SVGS/send-money.svg'
import { Separator } from '../../Components/ui/separator'
import { Badge } from '../../Components/ui/badge'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeft, MoveRight, Info } from 'lucide-react'
import FundComposeChart from '../../Components/MutualFunds/FundComposeChart'
import useMediaQuery from '../../hooks/useMediaQuery'
import React, { useState, useEffect, useContext } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  MutualFundsSelectorDetails,
  MutualFundChartData,
  MutualFundsSelectorDates,
  FabitsStarFunds,
} from '../../store/MFStore'
import env from '../../env/env'
import { toCurrency } from '../../utils/CurrencyUtil'
import { TrendingUp } from 'lucide-react'
import Loader from '../../Components/Loader/Loader'
import { NavbarContext } from '../../Provider/ShowNavbar'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded'
import { isDematUser } from '../../utils/AuthUtil'
import { messageModalState } from '../../store/Feedback'

const noise = `${env.SVGS}/noise.svg`

const getDateAgo = (monthsAgo) => {
  // Array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // Get the current date
  const currentDate = new Date()

  // Create a new date for the target date
  const targetDate = new Date(currentDate)

  // Subtract the specified number of months
  targetDate.setMonth(targetDate.getMonth() - monthsAgo)

  // Get the year and the month name
  const year = targetDate.getFullYear()
  const monthName = monthNames[targetDate.getMonth()]

  return `${monthName} ${year}`
}

const MFDetails = () => {
  const { state } = useLocation()
  console.log(state, 'sfdfdss')

  const [fundDetails, setFundDetails] = useState(state?.fundDetails)
  const fundPortfolio = useRecoilValueLoadable(MutualFundsSelectorDetails(state?.schemeCode))

  const [filterDuration, setFilterDuration] = useState('6M')
  const [chartData, setChartData] = useState([])
  const fundchartData = useRecoilValueLoadable(MutualFundChartData(state?.schemeCode))

  const fabitsFunds = useRecoilValueLoadable(FabitsStarFunds)
  const [similarFunds, setSimilarFunds] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [expandedClasses, setExpandedClasses] = useState({})

  const { displayNavbar } = useContext(NavbarContext)
  const setMessageModalState = useSetRecoilState(messageModalState)

  //Corpus Gains
  const [gainPer, setGainPer] = useState(0)
  const [absValue, setAbsValue] = useState(0)

  useEffect(() => {
    setFundDetails(state?.fundDetails)
  }, [state])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    if (fundchartData.state === 'hasValue') {
      setChartData(fundchartData.contents)
    } else if (fundchartData.state === 'hasError') {
      setChartData([])
      console.error('Error loading chart data')
    }
  }, [fundchartData])

  useEffect(() => {
    if (fabitsFunds.state === 'hasValue') {
      setSimilarFunds(fabitsFunds.contents)
    } else if (fabitsFunds.state === 'hasError') {
      setSimilarFunds([])
      console.error('Error loading chart data')
    }
  }, [fabitsFunds])

  const navigate = useNavigate()
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const groupedHoldings = fundPortfolio?.contents?.holdingsPortfolio?.reduce((acc, holding) => {
    const { assetClass } = holding

    // Define specific asset classes
    const specificAssetClasses = ['Equity', 'Debt', 'Hybrid']

    // Check if the asset class belongs to specific ones
    if (specificAssetClasses.includes(assetClass)) {
      if (!acc[assetClass]) {
        acc[assetClass] = []
      }
      acc[assetClass].push(holding)
    } else {
      // If it's not one of the specific ones, add to "Others"
      if (!acc.Others) {
        acc.Others = []
      }
      acc.Others.push(holding)
    }

    return acc
  }, {})

  if (fundPortfolio.state === 'loading') {
    return <Loader />
  }

  return (
    <div className='relative'>
      {isDesktop ? (
        <>
          {fundDetails && fundPortfolio?.state === 'hasValue' && (
            <div className='bg-white'>
              <div className='max-w-[1128px] mx-auto py-6'>
                <Button
                  variant='simple'
                  size='simple'
                  className='mb-6 font-semibold flex items-center gap-x-2 cursor-pointer'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeft className='h-5 w-5' /> Fund Details
                </Button>

                <div
                  className='h-[172px] rounded-3xl relative px-12 pb-12 pt-8 flex items-center'
                  style={{
                    background: `linear-gradient(147.55deg, #3B4D80 -29.68%, rgba(103, 119, 164, 0) 68.33%), #242F4E`,
                    isolation: 'isolate',
                  }}
                >
                  <div
                    className='absolute inset-0 rounded-3xl'
                    style={{
                      backgroundImage: `url(${noise})`,
                      // opacity: 0.3,
                      mixBlendMode: 'overlay',
                    }}
                  />

                  <div className='flex justify-between w-full'>
                    <div className='flex gap-x-6'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                        className='h-[60px] w-[60px] rounded-[8px]'
                      />
                      <div>
                        <h1 className='text-white font-semibold text-xl'>
                          {fundDetails.legalName
                            ? fundDetails.legalName.replace(/Fund.*$/, 'Fund')
                            : fundDetails.sName.replace(/Fund.*$/, 'Fund')}
                        </h1>
                        <p className='text-primary-100'>
                          {fundDetails?.sName.replace(/Fund.*$/, 'Fund')}
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-x-6'>
                      <div className='flex flex-col'>
                        <span className='text-primary-100'>3Y Returns</span>
                        <span className='text-primary-50'>
                          {fundDetails?.threeYrRet?.toFixed(2)} %
                        </span>
                      </div>
                      <div className='flex items-center gap-[6px]'>
                        <div
                          className={`h-5 w-5 ${
                            fundDetails?.riskProfile === 'Very High'
                              ? 'bg-red-600'
                              : fundDetails?.riskProfile === 'Moderately High'
                              ? 'bg-red-500'
                              : fundDetails?.riskProfile === 'High'
                              ? 'bg-red-400'
                              : fundDetails?.riskProfile === 'Moderate'
                              ? ''
                              : fundDetails?.riskProfile === 'Very Low'
                              ? 'bg-green-600'
                              : fundDetails?.riskProfile === 'Low to Moderate'
                              ? 'bg-green-500'
                              : 'bg-green-400'
                          }  rounded-full flex justify-center items-center`}
                        >
                          {fundDetails?.riskProfile === 'High' ||
                          fundDetails?.riskProfile === 'Very High' ||
                          fundDetails?.riskProfile === 'Moderately High' ? (
                            <ArrowUpwardRoundedIcon
                              sx={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                          ) : fundDetails?.riskProfile === 'Moderate' ? (
                            <DoNotDisturbOnRoundedIcon
                              sx={{
                                height: '20px',
                                width: '20px',
                                color: 'rgb(238 174 24 / var(--tw-bg-opacity))',
                              }}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              sx={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                          )}
                        </div>
                        <span
                          className={`${
                            fundDetails?.riskProfile === 'Very High'
                              ? 'text-red-600'
                              : fundDetails?.riskProfile === 'Moderately High'
                              ? 'text-red-500'
                              : fundDetails?.riskProfile === 'High'
                              ? 'text-red-400'
                              : fundDetails?.riskProfile === 'Moderate'
                              ? 'text-warning-500'
                              : fundDetails?.riskProfile === 'Very Low'
                              ? 'text-green-600'
                              : fundDetails?.riskProfile === 'Low to Moderate'
                              ? 'text-green-500'
                              : 'text-green-400'
                          }
                          `}
                        >
                          {fundDetails?.riskProfile} Volatility
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex gap-x-6 mt-12'>
                  <div className='flex flex-col gap-y-12 w-8/12'>
                    <div>
                      <h3 className='text-xl font-semibold'>Fund past performance</h3>
                    </div>

                    <div className='flex justify-between'>
                      {/* max-w-[256px] */}
                      <div className='font-normal leading-none text-sm w-[256px]'>
                        <span className='font-semibold'>₹ 10000 </span>
                        invested monthly since{' '}
                        <span className='font-semibold'>
                          {filterDuration === '6M'
                            ? getDateAgo(6)
                            : filterDuration === '1Y'
                            ? getDateAgo(12)
                            : filterDuration === '3Y'
                            ? getDateAgo(36)
                            : getDateAgo(60)}
                        </span>{' '}
                        would become{' '}
                        <span className='font-semibold'>
                          ₹ {Math.round(10000 + (10000 * gainPer) / 100)}
                        </span>
                        {/* <TrendingUp className='h-4 w-4' /> */}
                      </div>
                      <div className='flex justify-between gap-12'>
                        {/* max-w-[376px] */}
                        <div className='font-semibold border-l-2 border-blue-600 text-sm font-DMSans'>
                          <p className='text-blue-600 pl-2'>{fundDetails?.sName}</p>
                          <p className='text-black-500 pl-2'>{gainPer.toFixed(2)} %</p>
                        </div>
                        <div className='font-semibold border-l-2 border-[#D38C23] text-sm font-DMSans'>
                          <p className='text-[#D38C23] pl-2'>
                            {fundPortfolio?.contents?.benchmarkName}
                          </p>
                          <p className='text-black-500 pl-2'>
                            {(() => {
                              const ret =
                                filterDuration === '6M'
                                  ? fundPortfolio?.contents?.benchmarkAbsoluteReturns
                                      ?.sixMonthReturn
                                  : filterDuration === '1Y'
                                  ? fundPortfolio?.contents?.benchmarkAbsoluteReturns?.oneYearReturn
                                  : filterDuration === '3Y'
                                  ? fundPortfolio?.contents?.benchmarkAbsoluteReturns
                                      ?.threeYearReturn
                                  : fundPortfolio?.contents?.benchmarkAbsoluteReturns
                                      ?.fiveYearReturn
                              return ret ? ret : '*'
                            })()}{' '}
                            %
                          </p>
                        </div>
                      </div>
                    </div>

                    <FundChart
                      fundchartData={chartData}
                      setGainPer={setGainPer}
                      startFilterDate={
                        filterDuration === '6M'
                          ? getDateAgo(6)
                          : filterDuration === '1Y'
                          ? getDateAgo(12)
                          : filterDuration === '3Y'
                          ? getDateAgo(36)
                          : getDateAgo(60)
                      }
                    />

                    <div className='flex justify-center'>
                      <div className='flex gap-x-2 mt-6'>
                        <Button
                          variant='simple'
                          className={`text-sm ${
                            filterDuration === '6M'
                              ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                              : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                          }`}
                          size='simple'
                          onClick={() => setFilterDuration('6M')}
                        >
                          6M
                        </Button>
                        <Button
                          variant='simple'
                          className={`text-sm ${
                            filterDuration === '1Y'
                              ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                              : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                          }`}
                          size='simple'
                          onClick={() => setFilterDuration('1Y')}
                        >
                          1Y
                        </Button>

                        <Button
                          variant='simple'
                          className={`text-sm ${
                            filterDuration === '3Y'
                              ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                              : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                          }`}
                          size='simple'
                          onClick={() => setFilterDuration('3Y')}
                        >
                          3Y
                        </Button>
                        <Button
                          variant='simple'
                          className={`text-sm ${
                            filterDuration === '5Y'
                              ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                              : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                          }`}
                          size='simple'
                          onClick={() => setFilterDuration('5Y')}
                        >
                          5Y
                        </Button>
                      </div>
                    </div>

                    <Separator />

                    <div className='flex flex-col gap-y-6'>
                      <h3 className='font-semibold text-lg'>Quick look</h3>

                      <div>
                        <h3 className='font-semibold mb-2'>Fund information</h3>
                        <div className='flex justify-between gap-x-12 text-nowrap'>
                          <dl className='grid grid-cols-2 gap-2 text-sm flex-1'>
                            <dt className='text-black-400'>AUM</dt>
                            <dd className='font-semibold text-right'>
                              ₹ {toCurrency(fundPortfolio?.contents?.mainInfo[0]?.aum)}
                            </dd>
                            <dt className='text-black-400'>AMC</dt>
                            <dd className='font-semibold text-right text-wrap'>
                              {fundDetails?.amcName}
                            </dd>
                          </dl>
                          <dl className='grid grid-cols-2 gap-2 text-sm flex-1'>
                            <dt className='text-black-400 flex gap-1'>
                              Exit load{' '}
                              <div
                                onMouseEnter={() => setIsModalVisible(true)}
                                onMouseLeave={() => setIsModalVisible(false)}
                                className='relative inline-block'
                              >
                                <Info className='h-3 w-3' />
                                {isModalVisible && (
                                  <div className='absolute top-4 left-0 z-999 bg-white border border-gray-300 shadow-md p-2 text-wrap w-96'>
                                    <p>{fundPortfolio?.contents?.mainInfo[0]?.exitLoadCondition}</p>
                                  </div>
                                )}
                              </div>
                            </dt>
                            <dd className='font-semibold text-right text-wrap'>
                              upto {fundPortfolio?.contents?.mainInfo[0]?.exitLoad}% if redeemed
                              before 1 year
                            </dd>

                            <dt className='text-black-400'>Expense ratio</dt>
                            <dd className='font-semibold text-right'>
                              {fundPortfolio?.contents?.mainInfo[0]?.expenseRatio} %
                            </dd>
                          </dl>
                        </div>

                        <div className='mt-5'>
                          <h3 className='font-semibold mb-2'>Minimum investment amounts</h3>
                          <div className='flex justify-between gap-x-12 text-nowrap'>
                            <dl className='grid grid-cols-2 gap-2 text-sm flex-1'>
                              <dt className='text-black-400'>Min. for One Time Investing</dt>
                              <dd className='font-semibold text-right'>₹ {fundDetails?.minInvt}</dd>
                              {/* <dt className='text-black-400'>Min. for 2nd month onwards</dt>
                              <dd className='font-semibold text-right'>₹ 100</dd> */}
                            </dl>
                            <dl className='grid grid-cols-2 gap-2 text-sm flex-1'>
                              <dt className='text-black-400'>Min. for SIP</dt>
                              <dd className='font-semibold text-right'>
                                ₹{' '}
                                {fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Separator />

                    {/* <div className='flex flex-col gap-y-6'>
                      <h3 className='font-semibold text-lg'>About the fund</h3>
                      <div className='space-y-4'>
                        <div className='flex flex-wrap gap-2'>
                          <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                            Debt
                          </Badge>
                          <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                            Sectoral
                          </Badge>
                          <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                            For experienced investors
                          </Badge>
                        </div>
                        <p className='text-sm text-black-400'>
                          Are you looking for a fund that offers high returns in the long run? Are
                          you willing to undertake high risk? If you are, then the Fabita Momentum
                          Stocks Portfolio is just the right pick for you. Momentum investing is a
                          dynamic investment approach which entails buying stocks that are on the
                          rise.
                        </p>
                        <p className='text-sm text-black-400'>
                          The Fabita Momentum Stocks Portfolio has been designed to work in all
                          market scenarios since it selects the top 10 strongest stocks from India's
                          top 50 companies. Here's what makes this portfolio an excellent pick for
                          your basket.
                        </p>
                      </div>
                    </div>

                    <Separator /> */}

                    <div className='flex flex-col gap-y-6'>
                      <h3 className='font-semibold text-lg'>What does this fund compose of?</h3>

                      <div className='flex justify-between gap-x-12 text-nowrap'>
                        <div className='flex-1 py-6'>
                          <div className='flex flex-col gap-y-5'>
                            {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                              <dt className='font-semibold text-primary-500 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-primary-600 -ml-4 before:mr-2'>
                                Equity
                              </dt>
                              <dd className='font-semibold text-right'>60%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 1st month
                              </dt>
                              <dd className='text-right'>35%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 2nd month onwards
                              </dt>
                              <dd className='text-right'>20%</dd>
                            </dl> */}

                            {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                              <dt className='font-semibold text-secondary-600 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-secondary-600 -ml-4 before:mr-2'>
                                Debt
                              </dt>
                              <dd className='font-semibold text-right'>80%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 1st month
                              </dt>
                              <dd className='text-right'>35%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 2nd month onwards
                              </dt>
                              <dd className='text-right'>20%</dd>
                            </dl> */}

                            {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                              <dt className='font-semibold text-warning-600 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-warning-600 -ml-4 before:mr-2'>
                                Commodities
                              </dt>
                              <dd className='font-semibold text-right'>20%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 1st month
                              </dt>
                              <dd className='text-right'>35%</dd>
                              <dt className='text-black-400 border-b border-dashed border-black-400'>
                                Min. for 2nd month onwards
                              </dt>
                              <dd className='text-right'>20%</dd>
                            </dl> */}

                            {Object.keys(groupedHoldings).map((assetClass) => {
                              const sum = groupedHoldings[assetClass].reduce(
                                (acc, holding) => acc + holding.holdingPercentage,
                                0,
                              )
                              const randomColorClass =
                                assetClass === 'Equity'
                                  ? 'text-blue-600 before:bg-blue-600'
                                  : assetClass === 'Debt'
                                  ? 'text-green-600 before:bg-green-600'
                                  : assetClass === 'Hybrid'
                                  ? 'text-purple-600 before:bg-purple-600'
                                  : 'text-red-600 before:bg-red-600'

                              const isExpanded = expandedClasses[assetClass] || false

                              const toggleExpand = () => {
                                setExpandedClasses((prevState) => ({
                                  ...prevState,
                                  [assetClass]: !isExpanded,
                                }))
                              }

                              return (
                                <>
                                  <dl key={assetClass} className='grid grid-cols-2 gap-2 text-sm'>
                                    <dt
                                      className={`font-semibold ${randomColorClass} before:content-["*"] before:w-[6px] before:rounded-[3px] -ml-4 before:mr-2`}
                                    >
                                      {assetClass}
                                    </dt>
                                    <dd className='font-semibold text-right'></dd>
                                    {(isExpanded
                                      ? groupedHoldings[assetClass]
                                      : groupedHoldings[assetClass].slice(0, 5)
                                    ).map((holding, index) => (
                                      <React.Fragment key={index}>
                                        <dt className='text-black-400 '>
                                          {
                                            holding.companyName.length > 30
                                              ? `${holding.companyName
                                                  .replace(
                                                    /^[^a-zA-Z]*|[\(\[].*|[^a-zA-Z\s]+$/g,
                                                    '',
                                                  ) // Remove numbers and '%'
                                                  .slice(0, 35)}...`
                                              : holding.companyName.replace(
                                                  /^[^a-zA-Z]*|[\(\[].*|[^a-zA-Z\s]+$/g,
                                                  '',
                                                ) // Remove numbers and '%'
                                          }
                                        </dt>
                                        <dd className='text-right'>{holding.holdingPercentage}%</dd>
                                      </React.Fragment>
                                    ))}

                                    {groupedHoldings[assetClass].length > 5 && (
                                      <div className='col-span-2 text-right mt-2'>
                                        <button
                                          className='text-blue-600 font-semibold cursor-pointer'
                                          onClick={toggleExpand}
                                        >
                                          {isExpanded ? 'View Less' : 'View All'}
                                        </button>
                                      </div>
                                    )}
                                  </dl>
                                </>
                              )
                            })}
                          </div>
                        </div>

                        <div className='flex-1'>
                          <FundComposeChart holdings={fundPortfolio?.contents?.holdingsPortfolio} />
                        </div>
                      </div>
                    </div>

                    <Separator />

                    <div className='flex flex-col gap-y-6 mb-12'>
                      <div className='flex justify-between items-center'>
                        <h3 className='font-semibold text-lg'>Similar funds for you</h3>
                        <Link
                          to='/'
                          className='text-primary-600 font-semibold text-sm flex items-center gap-x-1'
                        >
                          View All
                          <MoveRight className='h-4 w-4' />
                        </Link>
                      </div>

                      <div className='flex justify-between'>
                        {similarFunds?.data?.map((fund) => (
                          <div
                            className='p-4 rounded-[16px] border flex flex-col gap-y-2 items-start'
                            key={fund?.amcCode}
                            style={{
                              boxShadow: '0px 4px 24px rgba(65, 85, 141, 0.08)',
                            }}
                            onClick={() =>
                              navigate(`/dashboard/mutual-funds/details`, {
                                state: {
                                  fundDetails: fund,
                                  schemeCode: fund.schemeCode,
                                },
                              })
                            }
                          >
                            <img src={rocket} className='h-8 w-8' />

                            <h4 className='font-semibold text-nowrap'>
                              {fund?.legalName?.slice(0, 22)}...
                            </h4>

                            <div className='flex justify-between items-center'>
                              <div className='flex items-center gap-x-2'>
                                <h3 className='text-lg font-semibold'>
                                  {fund?.threeYrRet.toFixed(2)}%
                                </h3>
                                <p className='text-black-400 text-[12px]'>3Y returns</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {isDematUser() ? (
                    <div className='w-4/12'>
                      <div className='flex gap-x-4'>
                        <Link
                          className='flex-1'
                          to='/dashboard/mutual-funds/place-order'
                          state={{ fundDetails: fundDetails, destination: 'SIP' }}
                        >
                          <div
                            className='flex-1 rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-start'
                            style={{
                              background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                              isolation: 'isolate',
                            }}
                          >
                            <div
                              className='absolute inset-0 rounded-[16px]'
                              style={{
                                backgroundImage: `url(${noise})`,
                                // opacity: 0.3,
                                mixBlendMode: 'overlay',
                              }}
                            />

                            <img src={CurrencyExchange} alt='Currency' className='h-6 w-6' />

                            <div className='flex flex-col gap-y-2'>
                              <h3 className='font-medium text-lg text-white'>Monthly SIP</h3>
                              <p className='font-medium text-xs text-primary-100'>
                                Start with as little as
                              </p>
                              <p className='font-medium text-primary-50'>
                                ₹{' '}
                                {toCurrency(
                                  fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount,
                                )}
                              </p>
                            </div>
                          </div>
                        </Link>

                        <Link
                          className='flex-1'
                          to='/dashboard/mutual-funds/place-order'
                          state={{ fundDetails: fundDetails, destination: 'One-Time' }}
                        >
                          <div
                            className='flex-1 rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-startborder border border-black-50'
                            style={{
                              background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                              isolation: 'isolate',
                            }}
                          >
                            <div
                              className='absolute inset-0 rounded-[16px]'
                              style={{
                                backgroundImage: `url(${noise})`,
                                // opacity: 0.3,
                                mixBlendMode: 'overlay',
                              }}
                            />

                            <img src={SendMoney} alt='Send Money' className='h-6 w-6' />

                            <div className='flex flex-col gap-y-2'>
                              <h3 className='font-medium text-lg text-nowrap'>One-Time Invest</h3>
                              <p className='font-medium text-xs text-black-400'>Suggested amount</p>
                              <p className='font-medium'>₹ {toCurrency(fundDetails?.minInvt)}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className='w-4/12'>
                      <div className='flex gap-x-4'>
                        <div
                          className='flex-1 rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-start cursor-pointer'
                          style={{
                            background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                            isolation: 'isolate',
                          }}
                          onClick={() =>
                            setMessageModalState({
                              message: 'Your KYC is incomplete.',
                              linkMessage:
                                'Click here to complete your KYC and to enable hassle free, one-click investing.',
                              severity: 'info',
                            })
                          }
                        >
                          <div
                            className='absolute inset-0 rounded-[16px]'
                            style={{
                              backgroundImage: `url(${noise})`,
                              // opacity: 0.3,
                              mixBlendMode: 'overlay',
                            }}
                          />

                          <img src={CurrencyExchange} alt='Currency' className='h-6 w-6' />

                          <div className='flex flex-col gap-y-2'>
                            <h3 className='font-medium text-lg text-white'>Monthly SIP</h3>
                            <p className='font-medium text-xs text-primary-100'>
                              Start with as little as
                            </p>
                            <p className='font-medium text-primary-50'>
                              ₹{' '}
                              {toCurrency(
                                fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount,
                              )}
                            </p>
                          </div>
                        </div>

                        <div
                          className='cursor-pointer flex-1 rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-startborder border border-black-50'
                          style={{
                            background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                            isolation: 'isolate',
                          }}
                          onClick={() =>
                            setMessageModalState({
                              message: 'Your KYC is incomplete.',
                              linkMessage:
                                'Click here to complete your KYC and to enable hassle free, one-click investing.',
                              severity: 'info',
                            })
                          }
                        >
                          <div
                            className='absolute inset-0 rounded-[16px]'
                            style={{
                              backgroundImage: `url(${noise})`,
                              // opacity: 0.3,
                              mixBlendMode: 'overlay',
                            }}
                          />

                          <img src={SendMoney} alt='Send Money' className='h-6 w-6' />

                          <div className='flex flex-col gap-y-2'>
                            <h3 className='font-medium text-lg text-nowrap'>One-Time Invest</h3>
                            <p className='font-medium text-xs text-black-400'>Suggested amount</p>
                            <p className='font-medium'>₹ {toCurrency(fundDetails?.minInvt)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='bg-white'>
          <div>
            <div
              style={{
                background: `linear-gradient(147.55deg, #3B4D80 -29.68%, rgba(103, 119, 164, 0) 68.33%), #242F4E`,
                isolation: 'isolate',
              }}
            >
              <div className='relative px-4 pb-12 items-center z-10'>
                <div
                  className='absolute inset-0 rounded-3xl'
                  style={{
                    backgroundImage: `url(${noise})`,
                    // opacity: 0.3,
                    mixBlendMode: 'overlay',
                  }}
                />
                <Button
                  variant='simple'
                  size='simple'
                  className='pt-3 font-semibold flex items-center gap-x-2 cursor-pointer text-white'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeft className='h-5 w-5' /> Fund Details
                </Button>
                <div className='flex flex-col pt-8 gap-y-8 justify-between w-full'>
                  <div className='flex flex-col justify-center items-center gap-x-6'>
                    <img
                      src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                      className='h-[60px] w-[60px]'
                    />
                    <div>
                      <h1 className='text-white font-semibold text-xl text-center'>
                        {fundDetails?.legalName}
                      </h1>
                      <p className='text-primary-100 text-center'>{fundDetails?.sName}</p>
                    </div>
                  </div>

                  <div className='flex justify-between items-center gap-x-6'>
                    <div className='flex flex-col'>
                      <span className='text-primary-100'>3Y Returns</span>
                      <span className='text-primary-50'>
                        {' '}
                        {fundDetails?.threeYrRet?.toFixed(2)} %
                      </span>
                    </div>
                    <div className='flex items-center gap-[6px]'>
                      <div
                        className={`h-4 w-4 ${
                          fundDetails?.riskProfile === 'Very High'
                            ? 'bg-red-600'
                            : fundDetails?.riskProfile === 'Moderately High'
                            ? 'bg-red-500'
                            : fundDetails?.riskProfile === 'High'
                            ? 'bg-red-400'
                            : fundDetails?.riskProfile === 'Moderate'
                            ? ''
                            : fundDetails?.riskProfile === 'Very Low'
                            ? 'bg-green-600'
                            : fundDetails?.riskProfile === 'Low to Moderate'
                            ? 'bg-green-500'
                            : 'bg-green-400'
                        }  rounded-full flex justify-center items-center`}
                      >
                        {fundDetails?.riskProfile === 'High' ||
                        fundDetails?.riskProfile === 'Very High' ||
                        fundDetails?.riskProfile === 'Moderately High' ? (
                          <ArrowUpwardRoundedIcon
                            sx={{
                              height: '16px',
                              width: '16px',
                            }}
                          />
                        ) : fundDetails?.riskProfile === 'Moderate' ? (
                          <DoNotDisturbOnRoundedIcon
                            sx={{
                              height: '20px',
                              width: '20px',
                              color: 'rgb(238 174 24 / var(--tw-bg-opacity))',
                            }}
                          />
                        ) : (
                          <ArrowDownwardRoundedIcon
                            sx={{
                              height: '16px',
                              width: '16px',
                            }}
                          />
                        )}
                      </div>
                      <span
                        className={`${
                          fundDetails?.riskProfile === 'Very High'
                            ? 'text-red-600'
                            : fundDetails?.riskProfile === 'Moderately High'
                            ? 'text-red-500'
                            : fundDetails?.riskProfile === 'High'
                            ? 'text-red-400'
                            : fundDetails?.riskProfile === 'Moderate'
                            ? 'text-warning-500'
                            : fundDetails?.riskProfile === 'Very Low'
                            ? 'text-green-600'
                            : fundDetails?.riskProfile === 'Low to Moderate'
                            ? 'text-green-500'
                            : 'text-green-400'
                        }`}
                      >
                        {fundDetails?.riskProfile} Volatility
                      </span>
                    </div>
                  </div>

                  {isDematUser() ? (
                    <div className='flex flex-row-reverse gap-x-4 w-full'>
                      <Link
                        to='/dashboard/mutual-funds/place-order'
                        state={{ fundDetails: fundDetails, destination: 'SIP' }}
                        className='flex-1'
                      >
                        <div
                          className='flex-1 h-full rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-start'
                          style={{
                            background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                            isolation: 'isolate',
                          }}
                        >
                          <div
                            className='absolute inset-0 rounded-[16px]'
                            style={{
                              backgroundImage: `url(${noise})`,
                              // opacity: 0.3,
                              mixBlendMode: 'overlay',
                            }}
                          />

                          <div className='flex flex-col gap-y-2'>
                            <h3 className='font-medium text-lg text-white'>Monthly SIP</h3>
                            <p className='font-medium text-xs text-primary-100'>
                              Start with as little as
                            </p>
                            <p className='font-medium text-primary-50'>
                              ₹{' '}
                              {toCurrency(
                                fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount,
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>

                      <Link
                        to='/dashboard/mutual-funds/place-order'
                        state={{ fundDetails: fundDetails, destination: 'One-Time' }}
                        className='flex-1'
                      >
                        <div
                          className='flex-1 h-full rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-startborder border border-black-50'
                          style={{
                            background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                            isolation: 'isolate',
                          }}
                        >
                          <div
                            className='absolute inset-0 rounded-[16px]'
                            style={{
                              backgroundImage: `url(${noise})`,
                              // opacity: 0.3,
                              mixBlendMode: 'overlay',
                            }}
                          />

                          <div className='flex flex-col gap-y-2'>
                            <h3 className='font-medium text-lg'>One-Time Invest</h3>
                            <p className='font-medium text-xs text-black-400'>Suggested amount</p>
                            <p className='font-medium'>₹ {toCurrency(fundDetails?.minInvt)}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div className='flex flex-row-reverse gap-x-4 w-full'>
                      <div
                        className='flex-1 h-full rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-start'
                        style={{
                          background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                          isolation: 'isolate',
                        }}
                        onClick={() =>
                          setMessageModalState({
                            message: 'Your KYC is incomplete.',
                            linkMessage:
                              'Click here to complete your KYC and to enable hassle free, one-click investing.',
                            severity: 'info',
                          })
                        }
                      >
                        <div
                          className='absolute inset-0 rounded-[16px]'
                          style={{
                            backgroundImage: `url(${noise})`,
                            // opacity: 0.3,
                            mixBlendMode: 'overlay',
                          }}
                        />

                        <div className='flex flex-col gap-y-2'>
                          <h3 className='font-medium text-lg text-white'>Monthly SIP</h3>
                          <p className='font-medium text-xs text-primary-100'>
                            Start with as little as
                          </p>
                          <p className='font-medium text-primary-50'>
                            ₹{' '}
                            {toCurrency(
                              fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount,
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className='flex-1 h-full rounded-[16px] relative px-4 pt-4 pb-3 flex flex-col gap-y-3 items-start justify-startborder border border-black-50'
                        style={{
                          background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                          isolation: 'isolate',
                        }}
                        onClick={() =>
                          setMessageModalState({
                            message: 'Your KYC is incomplete.',
                            linkMessage:
                              'Click here to complete your KYC and to enable hassle free, one-click investing.',
                            severity: 'info',
                          })
                        }
                      >
                        <div
                          className='absolute inset-0 rounded-[16px]'
                          style={{
                            backgroundImage: `url(${noise})`,
                            // opacity: 0.3,
                            mixBlendMode: 'overlay',
                          }}
                        />

                        <div className='flex flex-col gap-y-2'>
                          <h3 className='font-medium text-lg'>One-Time Invest</h3>
                          <p className='font-medium text-xs text-black-400'>Suggested amount</p>
                          <p className='font-medium'>₹ {toCurrency(fundDetails?.minInvt)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='flex gap-x-6 py-6 rounded-t-[32px] justify-center'>
              <div className='flex flex-col gap-y-6'>
                <div className='max-w-[100vw]'>
                  <h3 className='text-xl font-semibold mb-6 px-4'>Fund past performance</h3>

                  <div className='grid gap-4 px-4'>
                    {/* max-w-[256px] */}
                    <div className='font-normal text-center leading-none text-sm'>
                      <span className='font-semibold'>₹ 10000 </span>
                      invested monthly since{' '}
                      <span className='font-semibold'>
                        {filterDuration === '6M'
                          ? getDateAgo(6)
                          : filterDuration === '1Y'
                          ? getDateAgo(12)
                          : filterDuration === '3Y'
                          ? getDateAgo(36)
                          : getDateAgo(60)}
                      </span>{' '}
                      would become{' '}
                      <span className='font-semibold'>
                        ₹ {Math.round(10000 + (10000 * gainPer) / 100)}
                      </span>
                      {/* <TrendingUp className='h-4 w-4' /> */}
                    </div>
                    <div className='flex justify-around gap-4'>
                      {/* max-w-[376px] */}
                      <div className='font-semibold border-l-2 border-blue-600 text-sm font-DMSans'>
                        <p className='text-blue-600 pl-2'>{fundDetails?.sName}</p>
                        <p className='text-black-500 pl-2'>{gainPer.toFixed(2)} %</p>
                      </div>
                      <div className='font-semibold border-l-2 border-[#D38C23] text-sm font-DMSans'>
                        <p className='text-[#D38C23] pl-2'>
                          {fundPortfolio?.contents?.benchmarkName}
                        </p>
                        <p className='text-black-500 pl-2'>
                          {(() => {
                            const ret =
                              filterDuration === '6M'
                                ? fundPortfolio?.contents?.benchmarkAbsoluteReturns?.sixMonthReturn
                                : filterDuration === '1Y'
                                ? fundPortfolio?.contents?.benchmarkAbsoluteReturns?.oneYearReturn
                                : filterDuration === '3Y'
                                ? fundPortfolio?.contents?.benchmarkAbsoluteReturns?.threeYearReturn
                                : fundPortfolio?.contents?.benchmarkAbsoluteReturns?.fiveYearReturn
                            return ret ? ret : '*'
                          })()}{' '}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                  <FundChart
                    fundchartData={chartData}
                    setGainPer={setGainPer}
                    startFilterDate={
                      filterDuration === '6M'
                        ? getDateAgo(6)
                        : filterDuration === '1Y'
                        ? getDateAgo(12)
                        : filterDuration === '3Y'
                        ? getDateAgo(36)
                        : getDateAgo(60)
                    }
                  />
                </div>

                {/* <div className='flex justify-center gap-x-2'>
                  <Button
                    variant='simple'
                    className='px-4 py-1 text-white rounded-3xl bg-primary-600'
                    size='simple'
                  >
                    6M
                  </Button>
                  <Button
                    variant='simple'
                    className='px-4 py-1 text-white rounded-3xl bg-primary-600'
                    size='simple'
                  >
                    1Y
                  </Button>
                  <Button
                    variant='simple'
                    className='px-4 py-1 text-white rounded-3xl bg-primary-600'
                    size='simple'
                  >
                    2Y
                  </Button>
                  <Button
                    variant='simple'
                    className='px-4 py-1 text-white rounded-3xl bg-primary-600'
                    size='simple'
                  >
                    3Y
                  </Button>
                </div> */}
                <div className='flex justify-center'>
                  <div className='flex gap-x-2 mt-6'>
                    <Button
                      variant='simple'
                      className={`text-sm ${
                        filterDuration === '6M'
                          ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                          : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                      }`}
                      size='simple'
                      onClick={() => setFilterDuration('6M')}
                    >
                      6M
                    </Button>
                    <Button
                      variant='simple'
                      className={`text-sm ${
                        filterDuration === '1Y'
                          ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                          : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                      }`}
                      size='simple'
                      onClick={() => setFilterDuration('1Y')}
                    >
                      1Y
                    </Button>
                    <Button
                      variant='simple'
                      className={`text-sm ${
                        filterDuration === '3Y'
                          ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                          : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                      }`}
                      size='simple'
                      onClick={() => setFilterDuration('3Y')}
                    >
                      3Y
                    </Button>
                    <Button
                      variant='simple'
                      className={`text-sm ${
                        filterDuration === '5Y'
                          ? 'px-4 py-1 text-white rounded-3xl bg-primary-600'
                          : 'px-4 py-1 rounded-3xl bg-white border text-primary-500'
                      }`}
                      size='simple'
                      onClick={() => setFilterDuration('5Y')}
                    >
                      5Y
                    </Button>
                  </div>
                </div>

                <Separator className='w-[90%] mx-auto' />

                <div className='flex flex-col gap-y-6 p-4'>
                  <h3 className='font-semibold text-lg'>Quick look</h3>
                  <div>
                    <h3 className='font-semibold mb-2'>Fund information</h3>
                    <div className='text-sm'>
                      <div className='flex justify-between mb-3'>
                        <p className='text-black-400'>AUM</p>
                        <p className='font-semibold text-right'>
                          ₹ {toCurrency(fundPortfolio?.contents?.mainInfo[0]?.aum)}
                        </p>
                      </div>
                      <div className='flex justify-between mb-3'>
                        <dt className='text-black-400'>AMC</dt>
                        <dd className='font-semibold text-right'>{fundDetails?.amcName}</dd>
                      </div>
                      <div className='flex justify-between mb-3'>
                        <dt className='text-black-400'>
                          Exit load{' '}
                          <div
                            // onMouseEnter={() => setIsModalVisible(true)}
                            // onMouseLeave={() => setIsModalVisible(false)}
                            onClick={() => setIsModalVisible(!isModalVisible)}
                            className='relative inline-block'
                          >
                            <Info className='h-3 w-3' />
                            {isModalVisible && (
                              <div className='absolute top-4 left-0 z-999 bg-white border border-gray-300 shadow-md p-2 text-wrap w-60'>
                                <p>{fundPortfolio?.contents?.mainInfo[0]?.exitLoadCondition}</p>
                              </div>
                            )}
                          </div>
                        </dt>
                        <dd className='font-semibold text-right'>
                          upto {fundPortfolio?.contents?.mainInfo[0]?.exitLoad}% if redeemed before
                          1 year
                        </dd>
                      </div>
                      <div className='flex justify-between '>
                        <p className='text-black-400'>Expense ratio</p>
                        <p className='font-semibold text-right'>
                          {fundPortfolio?.contents?.mainInfo[0]?.expenseRatio} %
                        </p>
                      </div>
                    </div>

                    <div className='mt-5'>
                      <h3 className='font-semibold mb-2'>Minimum investment amounts</h3>
                      <dl className='text-sm'>
                        <div className='flex justify-between mb-3'>
                          <p className='text-black-400'>Min. for One Time Investing</p>
                          <p className='font-semibold text-right'>₹ {fundDetails?.minInvt}</p>
                        </div>
                        <div className='flex justify-between '>
                          <p className='text-black-400'>Min. for SIP</p>
                          <p className='font-semibold text-right'>
                            ₹ {fundDetails?.minSipAmount === 0 ? 100 : fundDetails?.minSipAmount}
                          </p>
                        </div>

                        {/* <dt className='text-black-400'>Min. for 2nd month onwards</dt>
                        <dd className='font-semibold text-right'>₹ 100</dd> */}
                      </dl>
                    </div>
                  </div>
                </div>

                <Separator className='w-[90%] mx-auto' />

                {/* <div className='flex flex-col gap-y-6 p-4'>
                  <h3 className='font-semibold text-lg'>About the fund</h3>
                  <div className='space-y-4'>
                    <div className='flex flex-wrap gap-2'>
                      <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                        Debt
                      </Badge>
                      <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                        Sectoral
                      </Badge>
                      <Badge variant='outline' className='text-[#41558D] px-6 py-2'>
                        For experienced investors
                      </Badge>
                    </div>
                    <p className='text-sm text-black-400'>
                      Are you looking for a fund that offers high returns in the long run? Are you
                      willing to undertake high risk? If you are, then the Fabita Momentum Stocks
                      Portfolio is just the right pick for you. Momentum investing is a dynamic
                      investment approach which entails buying stocks that are on the rise.
                    </p>
                    <p className='text-sm text-black-400'>
                      The Fabita Momentum Stocks Portfolio has been designed to work in all market
                      scenarios since it selects the top 10 strongest stocks from India's top 50
                      companies. Here's what makes this portfolio an excellent pick for your basket.
                    </p>
                  </div>
                </div> */}

                {/* <Separator className='w-[90%] mx-auto' /> */}

                <div className='flex flex-col gap-y-6 p-4'>
                  <h3 className='font-semibold text-lg'>What does this fund compose of?</h3>

                  <div className='flex flex-col justify-between gap-x-12 text-nowrap'>
                    <div className='max-w-[100vw]'>
                      <FundComposeChart holdings={fundPortfolio?.contents?.holdingsPortfolio} />
                    </div>
                    <div className='py-6 p-4'>
                      <div className='flex flex-col gap-y-5'>
                        {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                          <dt className='font-semibold text-primary-500 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-primary-600 -ml-4 before:mr-2'>
                            Equity
                          </dt>
                          <dd className='font-semibold text-right'>60%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 1st month
                          </dt>
                          <dd className='text-right'>35%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 2nd month onwards
                          </dt>
                          <dd className='text-right'>20%</dd>
                        </dl> */}

                        {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                          <dt className='font-semibold text-secondary-600 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-secondary-600 -ml-4 before:mr-2'>
                            Debt
                          </dt>
                          <dd className='font-semibold text-right'>80%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 1st month
                          </dt>
                          <dd className='text-right'>35%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 2nd month onwards
                          </dt>
                          <dd className='text-right'>20%</dd>
                        </dl> */}

                        {/* <dl className='grid grid-cols-2 gap-2 text-sm'>
                          <dt className='font-semibold text-warning-600 before:content-["*"] before:w-[6px] before:rounded-[3px] before:bg-warning-600 -ml-4 before:mr-2'>
                            Commodities
                          </dt>
                          <dd className='font-semibold text-right'>20%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 1st month
                          </dt>
                          <dd className='text-right'>35%</dd>
                          <dt className='text-black-400 border-b border-dashed border-black-400'>
                            Min. for 2nd month onwards
                          </dt>
                          <dd className='text-right'>20%</dd>
                        </dl> */}

                        {Object.keys(groupedHoldings).map((assetClass) => {
                          const sum = groupedHoldings[assetClass].reduce(
                            (acc, holding) => acc + holding.holdingPercentage,
                            0,
                          )
                          const randomColorClass =
                            assetClass === 'Equity'
                              ? 'text-blue-600 before:bg-blue-600'
                              : assetClass === 'Debt'
                              ? 'text-green-600 before:bg-green-600'
                              : assetClass === 'Hybrid'
                              ? 'text-purple-600 before:bg-purple-600'
                              : 'text-red-600 before:bg-red-600'

                          const isExpanded = expandedClasses[assetClass] || false

                          const toggleExpand = () => {
                            setExpandedClasses((prevState) => ({
                              ...prevState,
                              [assetClass]: !isExpanded,
                            }))
                          }

                          return (
                            <>
                              <dl key={assetClass} className='grid grid-cols-2 gap-2 text-sm'>
                                <dt
                                  className={`font-semibold ${randomColorClass} before:content-["*"] before:w-[6px] before:rounded-[3px] -ml-4 before:mr-2`}
                                >
                                  {assetClass}
                                </dt>
                                <dd className='font-semibold text-right'></dd>
                                {(isExpanded
                                  ? groupedHoldings[assetClass]
                                  : groupedHoldings[assetClass].slice(0, 5)
                                ).map((holding, index) => (
                                  <React.Fragment key={index}>
                                    <dt className='text-black-400 '>
                                      {
                                        holding.companyName.length > 30
                                          ? `${holding.companyName
                                              .replace(/^[^a-zA-Z]*|[\(\[].*|[^a-zA-Z\s]+$/g, '') // Remove numbers and '%'
                                              .slice(0, 35)}...`
                                          : holding.companyName.replace(
                                              /^[^a-zA-Z]*|[\(\[].*|[^a-zA-Z\s]+$/g,
                                              '',
                                            ) // Remove numbers and '%'
                                      }
                                    </dt>
                                    <dd className='text-right'>{holding.holdingPercentage}%</dd>
                                  </React.Fragment>
                                ))}

                                {groupedHoldings[assetClass].length > 5 && (
                                  <div className='col-span-2 text-right mt-2'>
                                    <button
                                      className='text-blue-600 font-semibold cursor-pointer'
                                      onClick={toggleExpand}
                                    >
                                      {isExpanded ? 'View Less' : 'View All'}
                                    </button>
                                  </div>
                                )}
                              </dl>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <Separator className='w-[90%] mx-auto' />

                <div className='flex flex-col gap-y-6 mb-12'>
                  <div className='flex justify-between items-center px-4'>
                    <h3 className='font-semibold text-lg'>Similar funds for you</h3>
                    <Link
                      to='/'
                      className='text-primary-600 font-semibold text-sm flex items-center gap-x-1'
                    >
                      View All
                      <MoveRight className='h-4 w-4' />
                    </Link>
                  </div>

                  <div className='flex gap-x-4 overflow-x-scroll max-w-[100vw] hide-scrollbar p-4'>
                    {similarFunds?.data?.map((fund) => (
                      <div
                        className='p-4 rounded-[16px] border flex flex-col gap-y-2 items-start max-w-[256px]'
                        key={fund?.amcCode}
                        style={{
                          boxShadow: '0px 4px 24px rgba(65, 85, 141, 0.08)',
                        }}
                        onClick={() =>
                          navigate(`/dashboard/mutual-funds/details`, {
                            state: {
                              fundDetails: fund,
                              schemeCode: fund.schemeCode,
                            },
                          })
                        }
                      >
                        <img src={rocket} className='h-8 w-8' />

                        <h4 className='font-semibold text-nowrap'>
                          {fund?.legalName?.slice(0, 22)}...
                        </h4>

                        <div className='flex justify-between items-center'>
                          <div className='flex items-center gap-x-2'>
                            <h3 className='text-lg font-semibold'>
                              {fund?.threeYrRet?.toFixed(2)}%
                            </h3>
                            <p className='text-black-400 text-[12px]'>3Y returns</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`sticky ${
              displayNavbar ? 'bottom-20' : 'bottom-0'
            } left-0 right-0 z-50 p-4 overflow-hidden`}
          >
            {/* Background Layer with Blur */}
            <div className='absolute inset-0 bg-gradient-to-t from-white to-transparent backdrop-blur-lg'></div>
            {/* Content Layer */}
            {isDematUser() ? (
              <div className='relative flex flex-row-reverse gap-x-4 justify-around z-10'>
                <Link
                  className='flex-1'
                  to='/dashboard/mutual-funds/place-order'
                  state={{ fundDetails: fundDetails, destination: 'SIP' }}
                  // className='flex-1'
                >
                  <div
                    className='flex-1 rounded-[16px] relative p-4 flex flex-col gap-y-3 items-start justify-start'
                    style={{
                      background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                      isolation: 'isolate',
                    }}
                  >
                    <div
                      className='absolute inset-0 rounded-[16px]'
                      style={{
                        backgroundImage: `url(${noise})`,
                        mixBlendMode: 'overlay',
                      }}
                    />
                    <div className='flex flex-col gap-y-2 w-full text-center'>
                      <h3 className='font-medium text text-white'>Monthly SIP</h3>
                    </div>
                  </div>
                </Link>

                <Link
                  className='flex-1'
                  to='/dashboard/mutual-funds/place-order'
                  state={{ fundDetails: fundDetails, destination: 'One-Time' }}
                  // className='flex-1'
                >
                  <div
                    className='flex-1 rounded-[16px] relative p-4 flex flex-col gap-y-3 items-start justify-start border border-black-50'
                    style={{
                      background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                      isolation: 'isolate',
                    }}
                  >
                    <div
                      className='absolute inset-0 rounded-[16px]'
                      style={{
                        backgroundImage: `url(${noise})`,
                        mixBlendMode: 'overlay',
                      }}
                    />
                    <div className='flex flex-col gap-y-2 w-full text-center'>
                      <h3 className='font-medium'>One-Time Invest</h3>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className='relative flex flex-row-reverse gap-x-4 justify-around z-10'>
                <div
                  className='flex-1 rounded-[16px] relative p-4 flex flex-col gap-y-3 items-start justify-start'
                  style={{
                    background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, #808DB3 0%, rgba(128, 141, 179, 0) 100%),radial-gradient(33.93% 43.53% at 98.51% 73.19%, rgba(128, 141, 179, 0.6) 0%, rgba(128, 141, 179, 0) 100%), #41558D`,
                    isolation: 'isolate',
                  }}
                  onClick={() =>
                    setMessageModalState({
                      message: 'Your KYC is incomplete.',
                      linkMessage:
                        'Click here to complete your KYC and to enable hassle free, one-click investing.',
                      severity: 'info',
                    })
                  }
                >
                  <div
                    className='absolute inset-0 rounded-[16px]'
                    style={{
                      backgroundImage: `url(${noise})`,
                      mixBlendMode: 'overlay',
                    }}
                  />
                  <div className='flex flex-col gap-y-2 w-full text-center'>
                    <h3 className='font-medium text text-white'>Monthly SIP</h3>
                  </div>
                </div>

                <div
                  className='flex-1 rounded-[16px] relative p-4 flex flex-col gap-y-3 items-start justify-start border border-black-50'
                  style={{
                    background: `radial-gradient(37.8% 49.16% at 14.29% 13.41%, rgba(45, 45, 45, 0.12) 0%, rgba(45, 45, 45, 0) 100%), #FFFFFF`,
                    isolation: 'isolate',
                  }}
                  onClick={() =>
                    setMessageModalState({
                      message: 'Your KYC is incomplete.',
                      linkMessage:
                        'Click here to complete your KYC and to enable hassle free, one-click investing.',
                      severity: 'info',
                    })
                  }
                >
                  <div
                    className='absolute inset-0 rounded-[16px]'
                    style={{
                      backgroundImage: `url(${noise})`,
                      mixBlendMode: 'overlay',
                    }}
                  />
                  <div className='flex flex-col gap-y-2 w-full text-center'>
                    <h3 className='font-medium'>One-Time Invest</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MFDetails
