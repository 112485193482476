import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { useThemeMode } from './Provider/ThemeContextProvider'
import { lightTheme, darkTheme } from './theme'
import Alerts from './Components/Alerts'
import MessageModal from './Components/MessageModal'
import Loader from './Components/Loader'
import RoutesConfig from './Routes/RoutesConfig'
import { removeToken } from './utils/AuthUtil'
import { HelmetProvider } from 'react-helmet-async'
import ReactGA from 'react-ga4'
import PageViewTracker from './utils/PageViewTracker'
import env from './env/env'
import clevertap from 'clevertap-web-sdk'
import { DrawerProvider } from './Provider/DrawerContext'
import ModalProvider from './Provider/ModalProvider'
import { NavbarProvider } from './Provider/ShowNavbar'
import ErrorBoundary from './Components/ErrorBoundary'

export default function App() {
  const navigator = useNavigate()
  const { darkMode } = useThemeMode()
  const redirectToLogin = () => {
    removeToken()
    navigator('/login')
  }
  const [selectedTheme, setSelectedTheme] = useState(darkMode ? darkTheme : lightTheme)

  useEffect(() => {
    setSelectedTheme(darkMode ? darkTheme : lightTheme)
  }, [darkMode])

  useEffect(() => {
    const handleContextMenu = (event) => {
      if (event.target.tagName === 'IMG') {
        event.preventDefault()
      }
    }

    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  useEffect(() => {
    ReactGA.initialize(env.GA_KEY)
    clevertap.privacy.push({ optOut: false })
    clevertap.privacy.push({ useIP: false })
    clevertap.init(env.CLEVER_TAP_KEY)
    clevertap.spa = true
  }, [])

  window.goToLogin = redirectToLogin

  return (
    <ErrorBoundary>
      <ThemeProvider theme={selectedTheme}>
        <ModalProvider />
        <DrawerProvider>
          <NavbarProvider>
            <PageViewTracker />
            <HelmetProvider>
              <RecoilRoot>
                <CssBaseline />
                <RoutesConfig />
                <Loader />
                <Alerts />
                <MessageModal />
              </RecoilRoot>
            </HelmetProvider>
          </NavbarProvider>
        </DrawerProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
