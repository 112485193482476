/* eslint-disable */

import { ChevronDown, ChevronRight } from 'lucide-react'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from './ui/menubar'
import { isDematUser, removeToken } from '../utils/AuthUtil'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import avatar from '../assets/Navbar/Group 146.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userDetailsState } from '../Pages/LoginState'
import NavigationStore from '../store/NavigationStore'
import { useEffect, useState } from 'react'
import { Button } from './ui/button'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from './ui/navigation-menu'
import { ChevronLeft, Instagram, LinkedIn, Logout, Twitter, YouTube } from '@mui/icons-material'
import useMediaQuery from '../hooks/useMediaQuery'

const socials = [
  {
    id: 1,
    icon: <YouTube sx={{ color: 'white', width: '14px', height: '14px' }} />,
    linkTo: 'www.youtube.com/@fabits.social',
  },
  {
    id: 1,
    icon: <LinkedIn sx={{ color: 'white', width: '14px', height: '14px' }} />,
    linkTo: 'https://www.linkedin.com/company/fabits/',
  },
  {
    id: 1,
    icon: <Twitter sx={{ color: 'white', width: '14px', height: '14px' }} />,
    linkTo: 'https://x.com/fabitssocial',
  },
  {
    id: 1,
    icon: <Instagram sx={{ color: 'white', width: '14px', height: '14px' }} />,
    linkTo: 'https://www.instagram.com/fabits.social',
  },
]

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userDetails] = useRecoilState(userDetailsState)
  const [selectedTab, setSelectedTab] = useState(0)
  const navState = useRecoilValue(NavigationStore)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
      setIsTransitioning(position > 300 && position < 404)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isMFPage =
    location.pathname === '/dashboard/mutual-funds' ||
    location.pathname === '/dashboard/mutual-funds/' ||
    location.pathname === '/dashboard/mutual-funds/investments' ||
    location.pathname === '/dashboard/mutual-funds/details' ||
    location.pathname === '/dashboard/mutual-funds/place-order' ||
    location.pathname === '/dashboard/mutual-funds/orders' ||
    location.pathname === '/dashboard/mutual-funds/payment-request' ||
    location.pathname === '/dashboard/mutual-funds/sips' ||
    location.pathname === '/dashboard/mutual-funds/redeem/place-order'

  const handleLogout = () => {
    removeToken()
    navigate('/login')
  }

  const getNavbarStyle = () => {
    if (!isMFPage) return 'bg-white border-b'
    if (isMFPage && scrollPosition < 400) return 'bg-[#242F4E]'
    if (isMFPage && scrollPosition >= 504) return 'bg-white border-b'
    return 'bg-gradient-to-b from-[#242F4E] to-white'
  }

  const getTextStyle = () => {
    if (!isMFPage) return 'text-black-400'
    if (scrollPosition < 300) return 'text-primary-100 hover:text-primary-100'
    if (scrollPosition >= 404) return 'text-black-400'
    return `text-primary-100 transition-colors duration-300 ${
      isTransitioning ? 'text-opacity-50' : ''
    }`
  }

  return (
    <>
      <div
        className={`w-full h-[52px] fixed top-0 left-0 z-[1000] transition-all duration-200 ${getNavbarStyle()}`}
        style={
          {
            // boxShadow: isMFPage && scrollPosition < 404 ? '0px 8px 48px 24px #242F4E' : 'none',
          }
        }
      >
        <div className='max-w-[1128px] z-10 flex justify-between items-center mx-auto h-full'>
          <div>
            <img src={isMFPage && scrollPosition < 404 ? '/logo2.svg' : '/logo.svg'} alt='Fabits' />
          </div>

          <div className='flex gap-x-4 h-full'>
            {navState.slice(0, 4).map((link) => (
              <Link
                key={link.pathName}
                to={link.linkPath}
                className={`${
                  link.highlightUrls.includes(location.pathname)
                    ? isMFPage && scrollPosition < 404
                      ? 'text-white font-semibold'
                      : 'text-primary-500'
                    : getTextStyle()
                } flex items-center font-medium text-sm no-underline transition-colors duration-300`}
              >
                {link.linkName}
              </Link>
            ))}

            {/* More dropdown remains the same */}
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className={`p-0 bg-transparent ${
                      isMFPage && scrollPosition < 404
                        ? getTextStyle()
                        : 'text-black-400 hover:text-black-300 focus:text-black-300'
                    } `}
                  >
                    More
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className='p-3 flex flex-col gap-y-2 md:w-[150px]'>
                    {navState.slice(4, navState.length).map((link) => (
                      <NavigationMenuLink className='no-underline' key={link.pathName}>
                        <Link
                          to={link.linkPath}
                          className={`${
                            link.highlightUrls.includes(location.pathname)
                              ? ' text-primary-500'
                              : 'text-black-400 hover:text-primary-400'
                          } flex justify-between items-center font-medium text-sm no-underline`}
                        >
                          {link.linkName}

                          {/* <ChevronRight className='h-4 w-4' /> */}
                        </Link>
                      </NavigationMenuLink>
                    ))}
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>

          <div className='flex items-center'>
            {/* Notification icon */}
            <div className='flex items-center mr-6 cursor-pointer'>
              <span
                id='web-inbox-placeholder'
                className={`material-symbols-rounded ${
                  isMFPage && scrollPosition < 404 ? 'text-white' : 'text-black-400'
                } w-5 h-5 transition-colors duration-300`}
              >
                notifications
              </span>
            </div>

            {/* User name */}
            <p
              className={`capitalize text-sm font-semibold mr-4 ${
                isMFPage && scrollPosition < 404 ? 'text-white' : 'text-black-400'
              } transition-colors duration-300`}
            >
              {userDetails.firstName} {userDetails.lastName}
            </p>

            {/* Avatar and menu remain the same */}
            <Menubar>
              <MenubarMenu className='bg-transparent'>
                <MenubarTrigger onClick={() => setShowMenu(true)}>
                  <Avatar className='cursor-pointer h-7 w-7'>
                    {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                    <AvatarFallback className='bg-primary-50 text-black-400'>
                      {userDetails?.firstName?.slice(0, 1)}
                    </AvatarFallback>
                  </Avatar>
                </MenubarTrigger>
                {isDematUser() ? (
                  showMenu && !isDesktop ? (
                    <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                      <MenubarItem className='flex justify-between py-4 px-5'>
                        <p onClick={() => setShowMenu(false)}>
                          <ChevronLeft /> Profile
                        </p>
                        <div className='flex lg:hidden justify-center items-center gap-x-4'>
                          {!isDesktop && (
                            <span
                              id='web-inbox-placeholder'
                              className={`material-symbols-rounded text-black-400 w-6 h-6`}
                              onClick={() => setShowMenu(false)}
                            >
                              notifications
                            </span>
                          )}
                        </div>
                      </MenubarItem>
                      <MenubarItem className='flex justify-center py-4 px-5'>
                        <div className='grid justify-items-center'>
                          <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </div>
                          <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                            {userDetails.firstName} {userDetails.lastName}
                          </div>
                        </div>
                      </MenubarItem>
                      {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                      <div className='px-5 grid gap-4 my-4'>
                        <div>
                          <p className='text-primary-200 font-DMSans font-medium text-[10px] leading-3'>
                            ACCOUNT
                          </p>

                          <Link to='/dashboard/profile'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                person
                              </span>
                              <p>Account Details</p>
                            </MenubarItem>
                          </Link>

                          {/* <Link to='/dashboard/myplan'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                license
                              </span>
                              <p>Fabits Subscription</p>
                            </MenubarItem>
                          </Link> */}

                          <Link to='/dashboard/funds'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span class='material-symbols-outlined text-xl text-black-500'>
                                account_balance_wallet
                              </span>
                              <p>Wallet</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            INVESTMENTS
                          </p>

                          <Link to='/dashboard/portfolio'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                local_mall
                              </span>
                              <p>Individual Holdings</p>
                            </MenubarItem>
                          </Link>

                          <Link to='/dashboard/sips'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                currency_exchange
                              </span>
                              <p>SIPs</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link to='/dashboard/support'>
                            <MenubarItem
                              className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                              onClick={() => setShowMenu(false)}
                            >
                              <span class='material-symbols-outlined text-xl text-black-500'>
                                support_agent
                              </span>
                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-44 p-0'>
                            {socials.map((social) => {
                              return (
                                <div
                                  key={social.id}
                                  // className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                  // onClick={() => setShowMenu(false)}
                                >
                                  <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                    {social.icon}
                                  </a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>

                      <div
                        className='cursor-pointer px-5 my-4  flex justify-center'
                        onClick={(() => setShowMenu(false), handleLogout)}
                      >
                        <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                          Logout
                        </div>
                      </div>
                    </MenubarContent>
                  ) : (
                    <MenubarContent
                      className='rounded-[20px] flex flex-col gap-y-[2px] w-[280px] p-0'
                      align='center'
                    >
                      <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                        <div className='flex gap-x-4 items-center'>
                          <Avatar className='h-7 w-7'>
                            {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                            <AvatarFallback className='bg-primary-100 text-black-400'>
                              {userDetails?.firstName?.slice(0, 1)}
                            </AvatarFallback>
                          </Avatar>

                          <div className='text-black-500 font-DMSans font-semibold text-base'>
                            {userDetails.firstName}
                          </div>
                        </div>
                        <div className='cursor-pointer'>
                          <Logout onClick={handleLogout} sx={{ color: '#ff6666', fontSize: 20 }} />
                        </div>
                      </MenubarItem>
                      {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                      <div className='px-5 grid gap-4 my-4'>
                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            ACCOUNT
                          </p>

                          <Link to='/dashboard/profile'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                person
                              </span>
                              <p>Account Details</p>
                            </MenubarItem>
                          </Link>

                          {/* <Link to='/dashboard/myplan'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                license
                              </span>
                              <p>Fabits Subscription</p>
                            </MenubarItem>
                          </Link> */}

                          <Link to='/dashboard/funds'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span class='material-symbols-outlined text-xl text-black-500'>
                                account_balance_wallet
                              </span>
                              <p>Wallet</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            INVESTMENTS
                          </p>

                          <Link to='/dashboard/portfolio'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                local_mall
                              </span>
                              <p>Individual Holdings</p>
                            </MenubarItem>
                          </Link>

                          <Link to='/dashboard/sips'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span className='material-symbols-outlined text-xl text-black-500'>
                                currency_exchange
                              </span>
                              <p>SIPs</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div>
                          <p className='text-primary-200 font-medium text-[10px] leading-3'>
                            CONNECT
                          </p>

                          <Link to='/dashboard/support'>
                            <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                              <span class='material-symbols-outlined text-xl text-black-500'>
                                support_agent
                              </span>
                              <p>Support</p>
                            </MenubarItem>
                          </Link>
                        </div>
                        <div className='flex justify-center'>
                          <MenubarItem className='justify-between w-36 p-0'>
                            {socials.map((social) => {
                              return (
                                <div
                                  key={social.id}
                                  className='bg-black-500 h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                                >
                                  <a href={social.linkTo}>{social.icon}</a>
                                </div>
                              )
                            })}
                          </MenubarItem>
                        </div>
                      </div>
                    </MenubarContent>
                  )
                ) : showMenu && !isDesktop ? (
                  <MenubarContent className='relative top-[-65px] w-[100vw] h-[100vh] z-[9999] bg-white flex flex-col gap-y-[2px] p-0'>
                    <MenubarItem className='flex justify-between py-4 px-5'>
                      <p onClick={() => setShowMenu(false)}>
                        <ChevronLeft /> Profile
                      </p>
                      <div className='flex lg:hidden justify-center items-center gap-x-4'>
                        {!isDesktop && (
                          <span
                            id='web-inbox-placeholder'
                            className={`material-symbols-rounded text-black-400 w-6 h-6`}
                            onClick={() => setShowMenu(false)}
                          >
                            notifications
                          </span>
                        )}
                      </div>
                    </MenubarItem>
                    <MenubarItem className='flex justify-center py-4 px-5'>
                      <div className='grid justify-items-center'>
                        <div className='h-[72px] w-[72px] aspect-square bg-primary-200 rounded-full text-white flex justify-center items-center text-xl'>
                          {userDetails?.firstName?.slice(0, 1)}
                        </div>
                        <div className='text-black-500 font-DMSans font-semibold mt-2 text-xl'>
                          {userDetails.firstName} {userDetails.lastName}
                        </div>
                      </div>
                    </MenubarItem>
                    {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    <div className='px-5 grid gap-4 my-4 no-underline'>
                      <div>
                        <p className='text-primary-200 font-medium text-[10px] leading-3'>
                          CONNECT
                        </p>

                        <Link to='/dashboard/support'>
                          <MenubarItem
                            className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'
                            onClick={() => setShowMenu(false)}
                          >
                            <span class='material-symbols-outlined text-xl text-black-500'>
                              support_agent
                            </span>
                            <p>Support</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div className='flex justify-center'>
                        <MenubarItem className='justify-between w-44 p-0'>
                          {socials.map((social) => {
                            return (
                              <div
                                key={social.id}
                                // className='bg-black-500 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer'
                                // onClick={() => setShowMenu(false)}
                              >
                                <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                  {social.icon}
                                </a>
                              </div>
                            )
                          })}
                        </MenubarItem>
                      </div>
                    </div>

                    <div
                      className='cursor-pointer px-5 my-4  flex justify-center'
                      onClick={(() => setShowMenu(false), handleLogout)}
                    >
                      <div className='px-5 py-3 font-DMSans rounded-md font-medium text-sm text-error-500 hover:bg-error-200 '>
                        Logout
                      </div>
                    </div>
                  </MenubarContent>
                ) : (
                  <MenubarContent
                    className='rounded-[20px] flex flex-col gap-y-[2px] w-[280px] p-0'
                    align='center'
                  >
                    <MenubarItem className='justify-between bg-[#F4F7FE] py-4 px-5'>
                      <div className='flex gap-x-4 items-center'>
                        <Avatar className='h-7 w-7'>
                          {/* <AvatarImage src={avatar} alt={userDetails.firstName} /> */}
                          <AvatarFallback className='bg-primary-100 text-black-400'>
                            {userDetails?.firstName?.slice(0, 1)}
                          </AvatarFallback>
                        </Avatar>

                        <div className='text-black-500 font-DMSans font-semibold text-base'>
                          {userDetails.firstName}
                        </div>
                      </div>
                      <div className='cursor-pointer'>
                        <Logout onClick={handleLogout} sx={{ color: '#ff6666', fontSize: 20 }} />
                      </div>
                    </MenubarItem>
                    {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    <div className='px-5 grid gap-4 my-4 no-underline'>
                      <div>
                        <p className='text-primary-200 font-medium text-[10px] leading-3'>
                          CONNECT
                        </p>

                        <Link to='/dashboard/support'>
                          <MenubarItem className='flex cursor-pointer gap-2 items-center py-2 hover:bg-black-50'>
                            <span class='material-symbols-outlined text-xl text-black-500'>
                              support_agent
                            </span>
                            <p>Support</p>
                          </MenubarItem>
                        </Link>
                      </div>
                      <div className='flex justify-center'>
                        <MenubarItem className='justify-between w-36 p-0'>
                          {socials.map((social) => {
                            return (
                              <div
                                key={social.id}
                                className='bg-black-500 h-6 w-6 rounded-full flex justify-center items-center cursor-pointer'
                              >
                                <a href={social.linkTo} target='_blank' rel='noopener noreferrer'>
                                  {social.icon}
                                </a>
                              </div>
                            )
                          })}
                        </MenubarItem>
                      </div>
                    </div>
                  </MenubarContent>
                )}
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
