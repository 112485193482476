/* eslint-disable */
import { atom, selector } from 'recoil'
import { isDematUser, isIPOUser } from '../utils/AuthUtil'
import Discover from '../assets/images/Discover Top Funds.svg'
import ChooseAFund from '../assets/images/Help me choose a fund.svg'
import Insurance from '../assets/images/Insurance.svg'
import IPO from '../assets/images/IPO.svg'
import Plan from '../assets/images/Plan a Goal.svg'
import SGB from '../assets/images/SGB.svg'

import home from '../assets/Navbar/home.svg'
import goals from '../assets/Navbar/goals.svg'
import discover from '../assets/Navbar/discover.svg'
import goldbonds from '../assets/Navbar/goldbonds.svg'
import insurance from '../assets/Navbar/insurance.svg'
import wallet from '../assets/Navbar/Wallet.svg'
import sip from '../assets/Navbar/sip.svg'
import talktous from '../assets/Navbar/talktous.svg'
import ipo from '../assets/Navbar/IPO.svg'
import orders from '../assets/Navbar/orders.svg'

const NavigationStateForDematUser = atom({
  key: 'NavigationStateForDematUser',
  default: [
    {
      linkName: 'Home',
      linkPath: '/dashboard',
      pathName: 'home',
      iconName: 'fa-solid fa-house',
      highlightUrls: ['/dashboard'],
    },
    // {
    //   linkName: 'First Ideas',
    //   linkPath: '/dashboard/firstideas',
    //   pathName: 'firstideas',
    //   iconName: 'fa-solid fa-lightbulb',
    //   highlightUrls: ['/dashboard/firstideas'],
    // },
    {
      linkName: 'Plan a Goal',
      linkPath: '/dashboard/plans',
      pathName: 'plan',
      iconName: 'fa-solid fa-chart-simple',
      highlightUrls: [
        '/dashboard/plans',
        '/dashboard/vacationplan',
        '/dashboard/emergencyplan',
        '/dashboard/weddingplan',
        '/dashboard/purchaseplan',
        '/dashboard/retirementplan',
        '/dashboard/educationplan',
        '/dashboard/invest',
      ],
    },
    {
      linkName: 'My Investments',
      linkPath: '/dashboard/portfolio',
      pathName: 'portfolio',
      iconName: 'fa-solid fa-chart-pie',
      highlightUrls: ['/dashboard/portfolio', '/dashboard/holdings'],
    },
    {
      linkName: 'SIPs',
      linkPath: '/dashboard/sips',
      pathName: 'sips',
      iconName: 'fa-solid fa-coins',
      highlightUrls: ['/dashboard/sips', '/dashboard/e-mandate'],
    },
    {
      linkName: 'Orders',
      linkPath: '/dashboard/activities',
      pathName: 'activities',
      iconName: 'fa-regular fa-calendar',
      highlightUrls: ['/dashboard/activities'],
    },
    {
      linkName: 'Account Balance',
      linkPath: '/dashboard/funds',
      pathName: 'funds',
      iconName: 'fa-solid fa-money-bills',
      highlightUrls: ['/dashboard/funds'],
    },
    // {
    //   linkName: 'Know your FAB score',
    //   linkPath: '/dashboard/risk-assessment',
    //   pathName: 'risk-assessment',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/risk-assessment'],
    // },
    {
      linkName: 'Discover top funds',
      linkPath: '/dashboard/theme',
      pathName: 'theme',
      iconName: 'fa-solid fa-sliders',
      highlightUrls: ['/dashboard/theme', '/dashboard/theme-details'],
    },
    // {
    //   linkName: 'IPO',
    //   linkPath: '/dashboard/ipo',
    //   pathName: 'theme',
    //   iconName: 'fa-solid fa-shop',
    //   highlightUrls: ['/dashboard/ipo'],
    // },
    // {
    //   linkName: 'Settings',
    //   linkPath: '/dashboard/settings',
    //   pathName: 'settings',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/settings'],
    // },
    {
      linkName: 'Insurance',
      linkPath: '/dashboard/insurance',
      pathName: 'insurance',
      iconName: 'fa-solid fa-user-shield',
      highlightUrls: ['/dashboard/insurance'],
    },
  ],
})

const NavigationStateForDematIPOUser = atom({
  key: 'NavigationStateForDematIPOUser',
  default: [
    {
      linkName: 'Home',
      linkPath: '/dashboard',
      pathName: 'home',
      iconName: 'home',
      highlightUrls: ['/dashboard'],
    },
    // {
    //   linkName: 'First Ideas',
    //   linkPath: '/dashboard/firstideas',
    //   pathName: 'firstideas',
    //   iconName: 'fa-solid fa-lightbulb',
    //   highlightUrls: ['/dashboard/firstideas'],
    // },

    {
      linkName: 'Plan a Goal',
      linkPath: '/dashboard/plans',
      pathName: 'plan',
      iconName: 'track_changes',
      highlightUrls: [
        '/dashboard/plans',
        '/dashboard/vacationplan',
        '/dashboard/emergencyplan',
        '/dashboard/weddingplan',
        '/dashboard/purchaseplan',
        '/dashboard/retirementplan',
        '/dashboard/educationplan',
        '/dashboard/invest',
      ],
      icon: ChooseAFund,
    },
    {
      linkName: 'Mutual Funds',
      linkPath: '/dashboard/mutual-funds',
      pathName: 'mutual-funds',
      iconName: 'home',
      highlightUrls: ['/dashboard/mutual-funds', '/dashboard/mutual-funds/investments'],
    },
    {
      linkName: 'Discover',
      linkPath: '/dashboard/theme',
      pathName: 'theme',
      iconName: 'bubble_chart',
      highlightUrls: ['/dashboard/theme', '/dashboard/theme-details'],
      icon: Discover,
    },
    {
      linkName: 'Investments',
      linkPath: '/dashboard/portfolio',
      pathName: 'portfolio',
      iconName: 'real_estate_agent',
      highlightUrls: ['/dashboard/portfolio', '/dashboard/holdings'],
      icon: Plan,
    },
    {
      linkName: 'SIPs',
      linkPath: '/dashboard/sips',
      pathName: 'sips',
      iconName: 'currency_exchange',
      highlightUrls: ['/dashboard/sips', '/dashboard/e-mandate'],
    },
    {
      linkName: 'Order History',
      linkPath: '/dashboard/activities',
      pathName: 'activities',
      iconName: 'deployed_code_history',
      highlightUrls: ['/dashboard/activities'],
    },
    {
      linkName: 'Fabits Wallet',
      linkPath: '/dashboard/funds',
      pathName: 'funds',
      iconName: 'account_balance_wallet',
      highlightUrls: ['/dashboard/funds'],
    },
    // {
    //   linkName: 'Know your FAB score',
    //   linkPath: '/dashboard/risk-assessment',
    //   pathName: 'risk-assessment',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/risk-assessment'],
    // },

    {
      linkName: 'IPO',
      linkPath: '/dashboard/ipo',
      pathName: 'ipo',
      iconName: 'add_business',
      highlightUrls: ['/dashboard/ipo'],
      icon: IPO,
    },
    {
      linkName: 'Gold Bonds',
      linkPath: '/dashboard/goldbonds',
      pathName: 'gold',
      iconName: 'stacks',
      highlightUrls: ['/dashboard/goldbonds'],
      icon: SGB,
    },
    // {
    //   linkName: 'Settings',
    //   linkPath: '/dashboard/settings',
    //   pathName: 'settings',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/settings'],
    // },
    {
      linkName: 'Insurance',
      linkPath: '/dashboard/insurance',
      pathName: 'insurance',
      iconName: 'admin_panel_settings',
      highlightUrls: ['/dashboard/insurance'],
      icon: Insurance,
    },
  ],
})

const NavigationStateForNonDematUser = atom({
  key: 'NavigationStateForNonDematUser',
  default: [
    {
      linkName: 'Home',
      linkPath: '/dashboard',
      pathName: 'home',
      iconName: 'fa-solid fa-house',
      highlightUrls: ['/dashboard'],
    },
    // {
    //   linkName: 'First Ideas',
    //   linkPath: '/dashboard/firstideas',
    //   pathName: 'firstideas',
    //   iconName: 'fa-solid fa-lightbulb',
    //   highlightUrls: ['/dashboard/firstideas'],
    // },
    {
      linkName: 'Plan a Goal',
      linkPath: '/dashboard/plans',
      pathName: 'plan',
      iconName: 'fa-solid fa-chart-simple',
      highlightUrls: ['/dashboard/plans'],
    },
    // },
    // {
    //   linkName: 'Risk Assessment',
    //   linkPath: '/dashboard/risk-assessment',
    //   pathName: 'risk-assessment',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/risk-assessment'],
    // },
    {
      linkName: 'Mutual Funds',
      linkPath: '/dashboard/mutual-funds',
      pathName: 'mutual-funds',
      iconName: 'home',
      highlightUrls: ['/dashboard/mutual-funds', '/dashboard/mutual-funds/investments'],
    },
    {
      linkName: 'Discover',
      linkPath: '/dashboard/theme',
      pathName: 'theme',
      iconName: 'fa-solid fa-sliders',
      highlightUrls: ['/dashboard/theme', '/dashboard/theme-details'],
    },
    // {
    //   linkName: 'IPO',
    //   linkPath: '/dashboard/ipo',
    //   pathName: 'theme',
    //   iconName: 'fa-solid fa-shop',
    //   highlightUrls: ['/dashboard/ipo'],
    // },
    {
      linkName: 'Insurance',
      linkPath: '/dashboard/insurance',
      pathName: 'insurance',
      iconName: 'fa-solid fa-user-shield',
      highlightUrls: ['/dashboard/insurance'],
    },
  ],
})

const NavigationStateForNonDematIPOUser = atom({
  key: 'NavigationStateForNonDematIPOUser',
  default: [
    {
      linkName: 'Home',
      linkPath: '/dashboard',
      pathName: 'home',
      iconName: 'home',
      highlightUrls: ['/dashboard'],
    },
    // {
    //   linkName: 'First Ideas',
    //   linkPath: '/dashboard/firstideas',
    //   pathName: 'firstideas',
    //   iconName: 'fa-solid fa-lightbulb',
    //   highlightUrls: ['/dashboard/firstideas'],
    // },
    {
      linkName: 'Plan a Goal',
      linkPath: '/dashboard/plans',
      pathName: 'plan',
      iconName: 'track_changes',
      highlightUrls: ['/dashboard/plans'],
      icon: ChooseAFund,
    },
    // },
    // {
    //   linkName: 'Risk Assessment',
    //   linkPath: '/dashboard/risk-assessment',
    //   pathName: 'risk-assessment',
    //   iconName: 'fa-solid fa-gear',
    //   highlightUrls: ['/dashboard/risk-assessment'],
    // },
    {
      linkName: 'Mutual Funds',
      linkPath: '/dashboard/mutual-funds',
      pathName: 'mutual-funds',
      iconName: 'home',
      highlightUrls: ['/dashboard/mutual-funds', '/dashboard/mutual-funds/investments'],
    },
    {
      linkName: 'Discover',
      linkPath: '/dashboard/theme',
      pathName: 'theme',
      iconName: 'bubble_chart',
      highlightUrls: ['/dashboard/theme', '/dashboard/theme-details'],
      icon: Discover,
    },
    // {
    //   linkName: 'IPO',
    //   linkPath: '/dashboard/ipo',
    //   pathName: 'theme',
    //   iconName: 'add_business',
    //   highlightUrls: ['/dashboard/ipo'],
    //   icon: IPO,
    // },
    {
      linkName: 'Insurance',
      linkPath: '/dashboard/insurance',
      pathName: 'insurance',
      iconName: 'admin_panel_settings',
      highlightUrls: ['/dashboard/insurance'],
      icon: Insurance,
    },
  ],
})

const NavigationSelector = selector({
  key: 'NavigationSelector',
  get: ({ get }) => {
    if (isDematUser()) {
      // if (isIPOUser()) {
      //   return get(NavigationStateForDematIPOUser)
      // } else {
      return get(NavigationStateForDematIPOUser)
      // }
    } else {
      // if (isIPOUser()) {
      //   return get(NavigationStateForNonDematIPOUser)
      // } else {
      return get(NavigationStateForNonDematIPOUser)
      // }
    }
  },
})

export default NavigationSelector
