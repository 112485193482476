import * as React from 'react'

import { cn } from '../../lib/utils'

const Input = React.forwardRef(
  ({ className, type, startAdornment, endAdornment, handleSubmit, ...props }, ref) => {
    return (
      <div className='relative flex items-center'>
        {startAdornment && (
          <span className='absolute left-0 pl-3 text-sm text-primary-500 font-semibold'>
            {startAdornment}
          </span>
        )}
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            startAdornment ? 'pl-8' : '',
            endAdornment ? 'pr-8' : '',
            className,
          )}
          ref={ref}
          {...props}
        />
        {endAdornment && (
          <span
            onClick={handleSubmit}
            className='absolute right-0 pr-3 text-sm text-primary-300 font-semibold cursor-pointer'
          >
            {endAdornment}
          </span>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
