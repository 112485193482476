import axios from 'axios'
import env from '../env/env'
import { getAccessToken } from '../utils/AuthUtil'

const client = axios.create({
  baseURL: env.API_URL,
})

const getConfig = async (headers, params) => {
  let config = {}
  if (!headers) {
    headers = {}
  }
  const jwtToken = await getAccessToken()
  if (jwtToken) {
    headers.Authorization = 'Bearer ' + jwtToken
  }
  config = {
    headers,
  }

  if (params) {
    config.params = params
  }
  return config
}

const post = async (url, data, headers, params) => {
  try {
    const config = await getConfig(headers, params)
    const response = await client.post(url, data, config)
    return response.data
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e.response.status,
      response: e.response.data,
    }
  }
}

const postRequest = async (url, data, headers, params) => {
  try {
    const config = await getConfig(headers, params)
    const response = await client.post(url, data, config)
    return response
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e,
      response: e.response.data,
    }
  }
}

const patch = async (url, data, headers, params) => {
  try {
    const config = await getConfig(headers, params)
    const response = await client.patch(url, data, config)
    return response.data
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e.response.status,
      response: e.response.data,
    }
  }
}

const get = async (url, headers, params) => {
  try {
    const config = await getConfig(headers, params)
    const response = await client.get(url, config)
    return response.data
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e.response.status,
      response: e.response.data,
    }
  }
}

const getMFData = async (headers, params) => {
  const url = '/mutualfundservice/api/mfData/allFunds'
  try {
    const config = await getConfig(headers, params)
    const response = await client.get(url, config)
    return response.data
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e.response.status,
      response: e.response.data,
    }
  }
}

const deleteReq = async (url, headers, params) => {
  try {
    const config = await getConfig(headers, params)
    const response = await client.delete(url, config)
    return response
  } catch (e) {
    if (
      (e.response.status === 401 || e.response.status === 403) &&
      !env.DO_NOT_VALIDATE_401.includes(url)
    ) {
      window.goToLogin()
    }
    return {
      isError: true,
      status: e.response.status,
      response: e.response.data,
    }
  }
}

export { post, get, patch, postRequest, deleteReq, getConfig, getMFData }
