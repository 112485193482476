/* eslint-disable */
import { Alert, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { changePassword, forgotPassword, forgotPasswordOTP, resendOtp } from '../utils/AuthUtil'
import PasswordStrength from './PasswordStrength'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'
import clevertap from 'clevertap-web-sdk'

const ResetPasswordOTP = ({ phoneNumber, userId, requestCode, goBack, nextPage }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(45)
  // const theme = useTheme()

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const resendOTP = async () => {
    // console.log('calling resend otp')
    try {
      const payload = {
        uid: userId,
        requestCode: '116',
      }
      // console.log(payload)
      const resendOtpResponse = await resendOtp(payload)
      setLoading(false)
      // console.log(resendOtpResponse)
      if (resendOtpResponse.isError) {
        setErrorMessage(resendOtpResponse.message.message)
        return
      }
      setCounter(45)
    } catch (e) {
      setLoading(false)
      return
    }
  }

  const validationSchema = yup.object({
    otp: yup.string('OTP').required('OTP is required'),
  })

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  })

  const validateOTP = async (event) => {
    event.preventDefault()
    try {
      setErrorMessage(null)
      const payload = {
        uid: userId,
        otp: formik.values.otp,
        requestCode: requestCode + '',
        phoneNumber,
      }
      const response = await forgotPasswordOTP(payload)
      setLoading(false)
      if (response.isError) {
        setErrorMessage(response.message.message)
        return
      }
      nextPage()
    } catch (e) {
      setLoading(false)
      return
    }
  }

  return (
    <Grid container sx={{}} className='password-container !mt-0'>
      <Grid sx={{ width: '100%' }}>
        <div className='flex flex-col justify-center items-center mb-6'>
          <h2 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold mb-4'>
            Verify OTP
          </h2>
          {/* <Alert
            severity='success'
            className='!flex !items-center !font-DMSans !bg-secondary-50 !py-1 !rounded-[12px] !text-secondary-600 !font-medium !gap-x-0 !mb-6 !mt-6 !w-min !whitespace-nowrap'
          >
            {' '}
            OTP sent to <span className='font-semibold'>{phoneNumber}</span>
          </Alert> */}
          <p className='flex flex-row whitespace-nowrap font-DMSans text-[14px] kerning-[20px] -tracking-[0.56px] font-medium text-black-400 align-center'>
            Enter the OTP sent to&nbsp;
            <span className='font-semibold text-black-500'>{phoneNumber}</span>
          </p>
        </div>
        <div className='flex flex-col justify-center items-center w-full'>
          {' '}
          {errorMessage && (
            <Alert
              severity='error'
              className='!flex !items-center !font-DMSans !bg-error-50 !py-1 !rounded-[12px] !text-error-600 !font-medium !gap-x-0 !mb-6 !mt-0 !w-min !whitespace-nowrap'
            >
              {errorMessage}
            </Alert>
          )}
          <div className='mb-6'>
            {counter == 0 ? (
              <button
                onClick={() => {
                  setLoading(true)
                  resendOTP()
                }}
                className='flex flex-row items-center justify-center cursor-pointer whitespace-nowrap font-DMSans text-[14px] kerning-[20px] font-semibold text-primary-500 gap-x-1 border-b-[2px] border-dashed border-primary-500'
              >
                <span className='material-symbols-rounded icon-filled-400 text-[20px] text-primary-500'>
                  cached
                </span>
                Resend OTP
              </button>
            ) : (
              <div className='flex flex-row justify-center items-center gap-x-1 border-b-[2px] border-transparent'>
                <span className='material-symbols-rounded icon-filled-400 text-[20px] text-black-400'>
                  cached
                </span>
                <p className='flex flex-row whitespace-nowrap font-DMSans text-[14px] kerning-[20px] font-medium text-black-400'>
                  {' '}
                  Resend OTP in <p className='font-semibold text-black-500'>&nbsp;{counter} sec</p>
                </p>
              </div>
            )}
          </div>
        </div>
        <form onSubmit={validateOTP}>
          <Grid className='w-full'>
            {/* <TextField
              id='otp'
              label='OTP'
              type='text'
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
              sx={{
                my: 1,
                py: '2px',
                textTransform: 'none',
              }}
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
              }}
            /> */}
            <label
              htmlFor='otp'
              className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500'
            >
              Enter OTP
            </label>
            <input
              type='text'
              id='otp'
              placeholder='Enter OTP'
              required
              value={formik.values.otp}
              onChange={formik.handleChange}
              onWheel={() => document.activeElement.blur()}
              onBlur={formik.handleBlur}
              className='flex mt-1 w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
            />
          </Grid>
          <Grid>
            <LoadingButton
              variant='contained'
              loading={loading}
              // loadingPosition='end'
              sx={{
                mt: '24px',
                py: '12px',
                px: '24px',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '500',
                fontFamily: 'DM Sans',
                textTransform: 'none',
                borderRadius: { xs: '12px', md: '8px' },
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
              }}
              fullWidth
              disabled={!(formik.dirty && formik.isValid)}
              onClick={(event) => {
                setLoading(true)
                validateOTP(event)
              }}
            >
              Continue
            </LoadingButton>
          </Grid>
        </form>
        <div className='flex lg:flex-col gap-x-2 justify-center items-center mt-6'>
          <button
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Go Back',
                Page_name: 'Reset Password',
              })
              goBack()
            }}
          >
            <span className='font-DMSans border-b-2 border-primary-500 border-dashed text-primary-500 text-[14px] kerning-[20px] font-semibold'>
              Edit Details
            </span>
          </button>
        </div>
        {/* <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {counter == 0 ? (
            <Button
              onClick={() => {
                clevertap.event.push('Clicked Button', {
                  Action: 'Resend OTP',
                  Page_name: 'Reset Password',
                })
                setLoading(true)
                resendOTP()
              }}
            >
              Resend OTP
            </Button>
          ) : (
            <Typography fontWeight={500} align='center'>
              {' '}
              Resend OTP in <span style={{ color: 'green', fontWeight: 'bold' }}>
                {' '}
                {counter}
              </span>{' '}
              Seconds
            </Typography>
          )}
        </Grid> */}
      </Grid>
    </Grid>
  )
}

const PasswordPage = ({ userId, phoneNumber, goBack }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const navigator = useNavigate()

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,20}$/,
        'Password must contain at least one uppercase, one special character, and one number with a minimum of eight characters',
      ),

    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  })

  const resetPassword = async () => {
    const payload = {
      newPwd: formik.values.password,
      uid: userId,
      phoneNumber: phoneNumber,
    }
    const response = await changePassword(payload)
    setLoading(false)
    if (response.code === 'Success') {
      navigator('/password', {
        state: {
          phoneNumber: phoneNumber,
          navigationMessage: 'Password Updated Successfully. Please login again',
        },
      })
    } else {
      setErrorMessage(response.message.message)
    }
  }

  return (
    <Grid container sx={{}} className='password-container'>
      <Grid sx={{ width: '100%' }}>
        <Grid item xs={12} sx={{ justifyContent: 'center', mt: 1 }}>
          <Typography
            variant='h5'
            sx={{
              mb: 1,
              fontWeight: '500',
              color: theme.palette.primary.main,
              textAlign: 'center',
            }}
          >
            Enter New Password
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
        </Grid>
        <form onSubmit={resetPassword}>
          <Grid>
            <label
              className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
              htmlFor='phoneNumber'
            >
              Password
            </label>
            <input
              className='flex w-full py-2 pl-3 pr-4 mb-2 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
              fullWidth
              size='small'
              id='password'
              label='Password'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              // helperText={formik.touched.password && formik.errors.password}
              sx={{
                my: 1,
                py: '2px',
                textTransform: 'none',
                borderRadius: '8px',
              }}
            />
            <Grid item xs={12} sx={{}}>
              <PasswordStrength password={formik.values.password} />
            </Grid>
            <label
              className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
              htmlFor='phoneNumber'
            >
              Confirm Password
            </label>
            <input
              className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
              fullWidth
              size='small'
              id='confirmPassword'
              label='Confirm Password'
              type='password'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              sx={{
                py: '2px',
                textTransform: 'none',
                borderRadius: '8px',
              }}
            />
          </Grid>
          <Grid>
            <LoadingButton
              variant='contained'
              loading={loading}
              // loadingPosition='end'
              fullWidth
              sx={{
                my: 1,
                py: '10px',
                textTransform: 'none',
                borderRadius: '8px',
              }}
              disabled={
                !(formik.values.password && formik.values.confirmPassword && formik.isValid)
              }
              onClick={(event) => {
                setLoading(true)
                resetPassword(event)
              }}
            >
              Change Password
            </LoadingButton>
          </Grid>
        </form>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            sx={{
              textTransform: 'none',
            }}
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Go Back',
                Page_name: 'Reset Password',
              })
              goBack()
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ResetPassword = () => {
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('FP')
  const [requestCode, setRequestCode] = useState()
  const location = useLocation()
  const [severity, setSeverity] = useState('error')

  useEffect(() => {
    if (location) {
      setErrorMessage(location.state?.message)
      setSeverity(location.state?.severity || severity)
    }
  }, [])

  const validationSchema = yup.object({
    emailId: yup.string('Email').email('Enter a valid email').required('Email is required'),
    phoneNumber: yup
      .string('Phone')
      .min(10, 'Enter Valid Phone number')
      .max(10, 'Enter Valid Phone number')
      .required('Phone number is required'),
    pan: yup
      .string('Phone')
      .min(10, 'Enter Valid PAN')
      .max(10, 'Enter Valid PAN')
      .required('PAN is required'),
  })

  const formik = useFormik({
    initialValues: {
      //uid: '',
      phoneNumber: '',
      emailId: '',
      pan: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  })

  const goBack = async () => {
    setPage('FP')
  }

  const nextPage = async () => {
    setPage('PP')
  }

  const resetPassword = async () => {
    const response = await forgotPassword(formik.values)
    if (response.code === 'Success') {
      // console.log(response)
      setRequestCode(response.message.requestCode)
      setPage('OP')
    } else {
      // console.log(response)
      setErrorMessage(response.message.message)
    }
    setLoading(false)
  }

  return (
    <>
      {page === 'FP' && (
        <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
          <h2 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
            Reset Password
          </h2>

          <Grid sx={{ width: '100%' }}>
            <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
              {errorMessage && <Alert severity={severity}>{errorMessage}</Alert>}
            </Grid>
            <form onSubmit={resetPassword} className='flex flex-col gap-y-6'>
              <div className='flex flex-col'>
                <label
                  className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
                  htmlFor='phoneNumber'
                >
                  Mobile number
                </label>
                {/* <TextField
                  id='phoneNumber'
                  size='small'
                  inputProps={{ maxLength: 10 }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  sx={{
                    textTransform: 'none',
                  }}
                /> */}
                <input
                  type='text'
                  id='phoneNumber'
                  placeholder='+91'
                  required
                  maxLength='10'
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
                />
              </div>
              <div className='flex flex-col'>
                <label
                  className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
                  htmlFor='emailId'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='emailId'
                  placeholder='eg. john.doe@mail.com'
                  required
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
                />
                {/* <TextField
                  id='emailId'
                  size='small'
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                  helperText={formik.touched.emailId && formik.errors.emailId}
                  sx={{
                    textTransform: 'none',
                  }}
                /> */}
              </div>
              <div className='flex flex-col'>
                <label
                  className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
                  htmlFor='pan'
                >
                  PAN
                </label>
                <input
                  type='text'
                  id='pan'
                  placeholder='eg. ABCDE1234X'
                  required
                  maxLength='10'
                  value={formik.values.pan}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
                />
                {/* <TextField
                  id='pan'
                  size='small'
                  value={formik.values.pan}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pan && Boolean(formik.errors.pan)}
                  helperText={formik.touched.pan && formik.errors.pan}
                  sx={{}}
                  inputProps={{ maxLength: 10, style: { textTransform: 'uppercase' } }}
                /> */}
              </div>
              <LoadingButton
                variant='contained'
                loading={loading}
                // loadingPosition='end'
                sx={{
                  py: '12px',
                  px: '24px',
                  width: '100%',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '500',
                  fontFamily: 'DM Sans',
                  marginTop: '8px',
                  textTransform: 'none',
                  borderRadius: { xs: '12px', md: '8px' },
                  boxShadow: 'none',
                  '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                  '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                }}
                disabled={!(formik.dirty && formik.isValid)}
                onClick={(event) => {
                  setLoading(true)
                  resetPassword(event)
                }}
              >
                Continue
              </LoadingButton>
            </form>
            <div className='flex lg:flex-col gap-x-2 justify-center items-center mt-6'>
              <button
                onClick={() => {
                  clevertap.event.push('Clicked Button', {
                    Action: 'Back To Login',
                    Page_name: 'Reset Password',
                  })
                  navigate('/login')
                }}
              >
                <span className='font-DMSans border-b-2 border-primary-500 border-dashed text-primary-500 text-[14px] kerning-[20px] font-semibold'>
                  Back to Login
                </span>
              </button>
            </div>
          </Grid>

          <img
            className='hidden lg:block absolute top-[5%] left-[-30%] -z-[1]'
            src={vector}
            alt='Vector 1'
          />

          <img
            className='hidden lg:block absolute bottom-[10%] right-[-20%] -z-[1]'
            src={vector1}
            alt='Vector 2'
          />
        </div>
      )}
      {page === 'OP' && (
        <ResetPasswordOTP
          phoneNumber={formik.values.phoneNumber}
          userId={formik.values.uid}
          requestCode={requestCode}
          goBack={goBack}
          nextPage={nextPage}
        />
      )}
      {page === 'PP' && (
        <PasswordPage
          phoneNumber={formik.values.phoneNumber}
          userId={formik.values.uid}
          goBack={goBack}
        />
      )}
    </>
  )
}

export default ResetPassword
