/* eslint-disable */
import useLocalStorage from '../../hooks/useLocalStorage'
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'

import useMediaQuery from '../../hooks/useMediaQuery'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '../ui/sheet'
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from '../ui/table'
import rocket from '../../assets/SVGS/rocket.svg'
import { ChevronRight } from 'lucide-react'

const funds = [
  {
    icon: rocket,
    name: 'Parag Parikh Flexi Cap Fund Direct Growth',
    returns: '47.28%',
    minAmount: '₹ 5,000',
    type: 'Debt',
  },
  {
    icon: rocket,
    name: 'SBI PSU Direct Plan Growth',
    returns: '41.16%',
    minAmount: '₹ 1,000',
    type: 'Equity',
  },
  {
    icon: rocket,
    name: 'Motilal Oswal Midcap Fund Direct Growth',
    returns: '38.73%',
    minAmount: '₹ 2,000',
    type: 'Equity',
  },
]

const MFBreakdown = () => {
  const { isOpen, onClose, type, data } = useModal()

  const [userInfo] = useLocalStorage('userData')
  const isModalOpen = isOpen && type === 'openMFBreakdown'
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[736px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-xl text-[#2D2D2D]'>
                Detailed Investment Breakdown
              </DialogTitle>
              <DialogDescription className='text-start text-black-400 text-sm font-DMSans font-medium'>
                This is how your investment of ₹ 10,000 gets divided
              </DialogDescription>
            </DialogHeader>
            <Table>
              <TableHeader className='bg-[#F4F7FE]'>
                <TableRow className='border-none'>
                  <TableHead className='text-primary-400 w-[50%] pl-12 rounded-l-xl'>
                    Fund Name
                  </TableHead>
                  <TableHead className='text-primary-400 text-right rounded-r-xl'>
                    Allocation
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {funds.map((fund, index) => (
                  <TableRow key={index}>
                    <TableCell className='font-medium flex gap-x-2 max-w-[432px]'>
                      <img
                        src={fund.icon}
                        alt={`${fund.name} icon`}
                        width={24}
                        height={24}
                        className='rounded-full'
                      />
                      <div>
                        <p className='text-clip font-semibold text-base text-nowrap truncate'>
                          {fund.name}
                        </p>
                        <span className='font-medium text-xs text-black-400'>{fund.type}</span>
                      </div>
                    </TableCell>

                    <TableCell className='font-semibold text-right'>₹ 1,000</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <DialogFooter>
              <Button
                className='border rounded-lg bg-white hover:bg-white text-primary-500'
                onClick={onClose}
              >
                Okey!
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans p-0 py-5 rounded-t-[24px] 
              ${data?.displayNavbar ? 'bottom-[80px]' : 'bottom-0'}
              `}
            showClose={true}
            side='bottom'
          >
            <SheetHeader className='px-4'>
              <SheetTitle className='text-start font-semibold text-[#2D2D2D]'>
                Detailed Investment Breakdown
              </SheetTitle>
              <SheetDescription className='text-start text-black-400 font-medium text-sm'>
                This is how your investment of <b className='text-black-500'>₹ 10,000</b> gets
                divided
              </SheetDescription>
            </SheetHeader>

            <Table className='mt-6'>
              <TableBody>
                {funds.map((fund, index) => (
                  <TableRow
                    key={index}
                    className={`${index + 1 === funds.length && 'border-none'}`}
                  >
                    <TableCell className='font-medium flex gap-x-2 px-4'>
                      <img
                        src={fund.icon}
                        alt={`${fund.name} icon`}
                        width={24}
                        height={24}
                        className='rounded-full'
                      />
                      <div className='w-full'>
                        <p className='font-semibold text-sm text-nowrap truncate max-w-[284px]'>
                          {fund.name}
                        </p>

                        <div className='flex justify-between'>
                          <span className='font-medium text-xs text-black-400'>{fund.type}</span>
                          <span className='font-semibold text-right text-sm'>₹ 1,000</span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default MFBreakdown
