import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeCtxProvider } from './Provider/ThemeContextProvider'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './stylesheet.scss'
import './styles.css'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <BrowserRouter>
    <ThemeCtxProvider>
      <App />
    </ThemeCtxProvider>
  </BrowserRouter>,
)
