import * as React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { Button } from '../ui/button'
import { ChevronLeft, ChevronRight } from 'lucide-react'

// import rocket from '../../assets/SVGS/rocket.svg'

export default function CuretedBasketsCarousel({ children }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 1,
    containScroll: 'trimSnaps',
  })

  const [canScrollPrev, setCanScrollPrev] = React.useState(false)
  const [canScrollNext, setCanScrollNext] = React.useState(true)

  const scrollPrev = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = React.useCallback((emblaApi) => {
    setCanScrollPrev(emblaApi.canScrollPrev())
    setCanScrollNext(emblaApi.canScrollNext())
  }, [])

  React.useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)

    return () => {
      emblaApi.off('select', onSelect)
      emblaApi.off('reInit', onSelect)
    }
  }, [emblaApi, onSelect])

  return (
    <div className='relative max-w-5xl'>
      <div className='overflow-hidden' ref={emblaRef}>
        <div className='flex gap-x-4'>{children}</div>
      </div>
      {canScrollPrev && (
        <Button
          variant='outline'
          size='icon'
          className='absolute left-6 top-1/2 transform -translate-y-1/2 rounded-full bg-white/80 hover:bg-white hidden lg:flex'
          onClick={scrollPrev}
        >
          <ChevronLeft className='h-4 w-4' />
          <span className='sr-only'>Previous slide</span>
        </Button>
      )}
      {canScrollNext && (
        <Button
          variant='outline'
          size='icon'
          className='absolute right-6 top-1/2 transform -translate-y-1/2 rounded-full bg-white/80 hover:bg-white hidden lg:flex'
          onClick={scrollNext}
        >
          <ChevronRight className='h-4 w-4' />
          <span className='sr-only'>Next slide</span>
        </Button>
      )}
    </div>
  )
}
